import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import errorHandler from "sagas/error-handler";
import OngoingRidesActions, {
  OngoingRideTypes,
} from "reducers/transporter/transporter-ongoing-rides";
// import SamuRideHistoryActions from "reducers/transporter/transporter-rides-history";
// import { queryRideHistory } from "./transporter-rides-history";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* onGoingRidesAsyncRequest({ page, limit, filter = false }) {
  let filterQuery = null;
  if (filter) {
    filterQuery = `
      {
        dates: [${filter.dates}],
        text: "${filter.text}"
      }
    `;
  }
  const query = `
    query {
      SamuOnGoingRides(page:${page},limit:${limit},filter:${filterQuery}) {
        page 
        limit
        totalPages
        rides {
          _id
          bilan
          patient {
            firstname
            lastname
            phone
            email
            nss
            status
            age
            gender
          }
          pickup{address longitude latitude}
          dropoff{address longitude latitude}        
          status
          rideNumber
          transporter {
            company{name}
            vehicle {
              plate
              category
              make
              color
              model
              phone
            }
            driver
          }
          datetime
          vehicleOptions
          vehicleCategory

          rideType
          samu { name }
          subject
          observation
          intervention { numeroRue rue codePostal commune complement batiment digicode etage poi }
          waitingTime { duration_in_traffic }
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const rides = data.SamuOnGoingRides;

    yield put(OngoingRidesActions.onGoingRidesRequestSuccess(rides));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, OngoingRidesActions.onGoingRidesRequestFailure);
  }
}

// CANCEL RIDE

function* onCancelRideRequest({ rideId, reason, callback }) {
  const query = `
    mutation {
      SamuCancelRide(rideId:"${rideId}", reason:"${reason}") {
        _id
        status
      }
    }
  `;
  try {
    const { SamuCancelRide } = yield graphQLClient().request(query);
    yield put(OngoingRidesActions.onCancelRideSuccess(SamuCancelRide));
    // yield put(SamuRideHistoryActions.rideHistoryAppendNewRide(SamuCancelRide));
    if (_.isFunction(callback)) callback(true);
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    yield errorHandler("error", OngoingRidesActions.onCancelRideFailure);
  }
}

function* updateDropoffAsyncRequest({ rideId, dropoff, callback }) {
  const query = `
    mutation {
      SamuUpdateDropoff(rideId:"${rideId}", dropoff: {
        address: "${dropoff.address}",
        longitude: ${dropoff.longitude},
        latitude: ${dropoff.latitude}
      }) {
        _id
        status
        dropoff {
          address
          longitude
          latitude
        }
      }
    }
  `;
  try {
    const { SamuUpdateDropoff } = yield graphQLClient().request(query);
    yield put(OngoingRidesActions.updateDropoffSuccess(SamuUpdateDropoff));
    // yield put(SamuRideHistoryActions.rideHistoryAppendNewRide(SamuUpdateDropoff));
    if (_.isFunction(callback)) callback(true);
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    yield errorHandler("error", OngoingRidesActions.updateDropoffFailure);
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(OngoingRideTypes.ON_GOING_RIDES_REQUEST, onGoingRidesAsyncRequest),
  takeLatest(OngoingRideTypes.ON_CANCEL_RIDE_REQUEST, onCancelRideRequest),
  takeLatest(
    OngoingRideTypes.UPDATE_DROPOFF_REQUEST,
    updateDropoffAsyncRequest
  ),
];
