import { Component } from "react";
import { Alert, Center, HStack, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "global/local-dates";
import "assets/style.css";
import noDataIcon from "assets/img/no_data.svg";
import Loader from "react-loader-spinner";
import historyRidesActions from "reducers/transporter/transporter-rides-history";
import MinioActions from "reducers/minio";
import {
  Title,
  Text,
  FloatingInput,
  NotificationMenu,
  HistoryRidesCard,
} from "components";
import { downloadFilesAsBlob } from "functions";

class HistoryRides extends Component {
  constructor(props) {
    super(props);

    this.onSearchHistoryRidesFilter =
      this.onSearchHistoryRidesFilter.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);

    this.state = {
      searchInputValue: "",
      filterDates: [],
    };
  }

  componentDidMount() {
    const { rideHistoryRequest } = this.props;
    rideHistoryRequest(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterDates, searchInputValue } = this.state;
    if (
      prevState.filterDates !== filterDates ||
      prevState.searchInputValue !== searchInputValue
    ) {
      this.props.rideHistoryRequest(1, 10, {
        text: searchInputValue,
        dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
      });
    }
  }

  onSearchHistoryRidesFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
  }

  fetchData() {
    const { rideHistoryRequest, rides } = this.props;
    const { page, limit, totalPages } = rides;
    if (page < totalPages)
      rideHistoryRequest(page + 1, limit, {
        text: this.state.searchInputValue,
        dates: this.state.filterDates,
      });
  }

  downloadFiles(paths, patient) {
    this.props.getPresignedUrl(
      paths.map((e) => e.path),
      async (urls) => {
        downloadFilesAsBlob(urls, paths, patient);
      }
    );
  }

  render() {
    const { t, i18n, action, rides } = this.props;
    const { error, loading } = action;
    const { searchInputValue } = this.state;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space={5} h="48" mt="5" zIndex={1}>
            {error !== "" && (
              <Alert bsStyle="danger">
                <span>{error}</span>
              </Alert>
            )}

            <Title>
              {t("RidesHistoryPage.ride_history_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification */}
            <Stack alignItems="end" w="986px">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            {/* Search */}

            <HStack justifyContent="space-between" w="986px">
              <FloatingInput
                inputType="search"
                label={t("searchBar", { lng: i18n.language })}
                onChange={this.onSearchHistoryRidesFilter}
                value={searchInputValue}
              />

              <Center position="relative">
                <Calendar
                  multiple={true}
                  value={this.state.filterDates}
                  weekStartDayIndex={1}
                  disableDayPicker={false}
                  showOtherDays={false}
                  calendarPosition="Right Top"
                  className="container-input-calendar"
                  render={<InputIcon />}
                  locale={datepickerLocal}
                  shadow={false}
                  onChange={(selectedDates) => {
                    this.setState({ filterDates: selectedDates });
                  }}
                />
              </Center>
            </HStack>
          </VStack>

          {/* No Data */}
          {rides.rides.length === 0 && (
            <Center flex={1}>
              <img src={noDataIcon} alt="No Data" />
              <Text fontSize="18">
                {t("RidesHistoryPage.message_when_no_data")}
              </Text>
            </Center>
          )}

          <div
            id="scrollableDivHistory"
            style={{
              overflow: "auto",
              marginTop: 30,
            }}
          >
            <InfiniteScroll
              dataLength={rides.rides.length} //This is important field to render the next data
              next={this.fetchData}
              hasMore={true}
              scrollableTarget="scrollableDivHistory"
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={loading}
                />
              }
            >
              {rides.rides.map((ride, key) => (
                <HistoryRidesCard
                  {...{ t, i18n }}
                  ride={ride}
                  key={key}
                  mb="10px"
                  downloadFiles={this.downloadFiles}
                  token={this.props?.account?.token}
                />
              ))}
            </InfiniteScroll>
          </div>
        </VStack>

        {/* MODAL FOR REPORT ACCIDENT OR ANYTHING */}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.historyRides.rides.asMutable({ deep: true }),
    action: state.historyRides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rideHistoryRequest: (...args) =>
      dispatch(historyRidesActions.rideHistoryRequest(...args)),
    getPresignedUrl: (...args) =>
      dispatch(MinioActions.getPresignedUrl(...args)),
  };
};

const historyRedux = connect(mapStateToProps, mapDispatchToProps)(HistoryRides);
export default withTranslation(["Private", "Common"], { wait: true })(
  historyRedux
);
