import React from "react";
import { HStack, Spinner, VStack, Box } from "native-base";
import { Label, Pie, PieChart } from "recharts";
import { Text } from "components/Text";
import LegendChart from "./LegendChart";

const DividerVerticalDashed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2}
    height={66}
    fill="none"
    {...props}
  >
    <path
      stroke="#DBDBDB"
      strokeDasharray="7 7"
      strokeLinecap="round"
      strokeWidth={2}
      d="M1 1.5v63"
    />
  </svg>
);

const TauxRefusPieChart = (props) => {
  const taux = Math.round(
    (props.data[0].value / (props.data[0].value + props.data[1].value)) * 100
  );
  return (
    <HStack space={1} rounded="12px">
      <PieChart width={120} height={120}>
        <Pie
          startAngle={-270}
          // activeIndex={this.state.activeIndex}
          data={props.data}
          cx="50%"
          cy="50%"
          innerRadius={22}
          outerRadius={44}
          dataKey="value"
          // onMouseEnter={this.onPieEnter}
        >
          <Label
            value={taux + "%"}
            position="center"
            fill="#EE5331"
            fontSize="12px"
            fontWeight={900}
            fontFamily="Lato"
          />
        </Pie>
      </PieChart>
      <VStack justifyContent="center" space={4}>
        <Text
          fontSize="16px"
          fontWeight="700"
          color="#273B4A"
          lineHeight="20px"
        >
          Taux de refus
          <br />
          ambulanciers
        </Text>
        <VStack space={1.5}>
          <LegendChart fill="#EE5331" label="Refus ambulanciers" />
          <LegendChart fill="#FACDC4" label="Nb total d'interventions" />
        </VStack>
      </VStack>
    </HStack>
  );
};

export default function RealTime({ action, dataset }) {
  return (
    <HStack space="28px" my="14px">
      <HStack
        space={"60px"}
        bg="dashboardCard"
        rounded="12px"
        // py="15px"
        px={"57px"}
        alignItems={"center"}
      >
        <VStack justifyContent="center">
          <Text fontSize="16px" fontWeight="700" lineHeight="normal">
            Données traffic
            <br />
            en temps réel :
          </Text>
          <Text fontSize="14px" fontWeight="400" color="gray">
            Mis à jour il y a 2 min
          </Text>
        </VStack>

        <VStack justifyContent="center">
          <Text
            fontSize="16px"
            fontWeight="600"
            color="#1C1C1C"
            lineHeight="normal"
          >
            Véhicules
            <br />
            disponibles
          </Text>
          {action.loading || !dataset?.driver ? (
            <Spinner accessibilityLabel="Loading posts" color="#F5BD4F" />
          ) : (
            <Text fontSize="24px" fontWeight="900" color="#F5BD4F">
              {dataset?.driver?.free}
              <Text fontSize="15px" fontWeight="600" color="#1C1C1C">
                /10
              </Text>
            </Text>
          )}
        </VStack>

        <DividerVerticalDashed />

        <VStack justifyContent="center">
          <Text
            fontSize="16px"
            fontWeight="600"
            color="#1C1C1C"
            lineHeight="normal"
          >
            Véhicules
            <br />
            engagés
          </Text>
          {action.loading || !dataset?.driver ? (
            <Spinner accessibilityLabel="Loading posts" color="#8A8FF8" />
          ) : (
            <Text fontSize="24px" fontWeight="900" color="#8A8FF8">
              {dataset?.driver?.busy}
              <Text fontSize="15px" fontWeight="600" color="#1C1C1C">
                /10
              </Text>
            </Text>
          )}
        </VStack>

        <DividerVerticalDashed />

        <VStack justifyContent="center">
          <Text
            fontSize="16px"
            fontWeight="700"
            color="#EE8131"
            lineHeight="normal"
          >
            Interventions
            <br />
            en attente
          </Text>
          {action.loading || !dataset?.ride ? (
            <Spinner accessibilityLabel="Loading posts" color="#EE8131" />
          ) : (
            <Text fontSize="24px" fontWeight="900" color="#EE8131">
              {dataset?.ride?.incoming}
            </Text>
          )}
        </VStack>

        <DividerVerticalDashed />

        <VStack justifyContent="center">
          <Text
            fontSize="16px"
            fontWeight="600"
            color="#1C1C1C"
            lineHeight="normal"
          >
            Interventions
            <br />
            effectuées
          </Text>
          {action.loading || !dataset?.ride ? (
            <Spinner accessibilityLabel="Loading posts" color="#0E2BE2" />
          ) : (
            <Text fontSize="24px" fontWeight="900" color="#0E2BE2">
              {dataset?.ride?.completed}
            </Text>
          )}
        </VStack>
      </HStack>

      <TauxRefusPieChart
        data={[
          { name: "Refus", value: 16, fill: "#EE5331" },
          { name: "Acceptation", value: 80, fill: "#FACDC4" },
        ]}
      />
    </HStack>
  );
}
