import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  samuCreateNewRideRequest: ["ride", "callback"],
  samuCreateNewRideRequestSuccess: null,
  samuCreateNewRideRequestFailure: ["error"],
});

export const NewRideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const samuCreateNewRideRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const samuCreateNewRideRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const samuCreateNewRideRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAMU_CREATE_NEW_RIDE_REQUEST]: samuCreateNewRideRequest,
  [Types.SAMU_CREATE_NEW_RIDE_REQUEST_SUCCESS]: samuCreateNewRideRequestSuccess,
  [Types.SAMU_CREATE_NEW_RIDE_REQUEST_FAILURE]: samuCreateNewRideRequestFailure,
});
