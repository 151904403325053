import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, HStack, Stack, View, VStack, Toast } from "native-base";
import { connect } from "react-redux";
import _ from "lodash";
import { FileUploader } from "react-drag-drop-files";
import {
  Title,
  NotificationMenu,
  MenuLink,
  Text,
  Button,
  NotificationCustom,
  Planning as CPlanning,
} from "components";
import PlanningAction from "reducers/planning";
import MinioAction from "reducers/minio";
import addPartnerIcon from "assets/img/add_vehicle.svg";
import { uploadFile } from "functions";

class Planning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: {},
      showAddPlanningPopUp: false,
      presignedUrl: "",
      file: null,
      path: "",
    };

    this.onCancelFormPopUp = this.onCancelFormPopUp.bind(this);
    this.onPressUploadPlanningButton =
      this.onPressUploadPlanningButton.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onPressUpload = this.onPressUpload.bind(this);
  }

  componentDidMount() {
    this.props.planningsRequest(1, 5);
  }

  onPressUploadPlanningButton() {
    // get id unique between 5 characters
    const id = Math.random().toString(36).substr(2, 5);
    // concat with datetime milliseconds
    const filename = `${id}-${Date.now()}`;
    const path = "samu-garde/" + filename + ".xlsx";

    this.props.putPresignedUrl([path], (presignedUrls) => {
      this.setState(
        Object.assign({}, this.state, {
          showAddPlanningPopUp: true,
          presignedUrl: presignedUrls.length > 0 ? presignedUrls[0] : "",
          path,
        })
      );
    });
  }

  onChangeFile(file) {
    this.setState({ file });
  }

  onPressUpload() {
    const { t, i18n } = this.props;
    const { file, presignedUrl, path } = this.state;
    if (!file || !presignedUrl) return;

    uploadFile({
      url: presignedUrl,
      file,
      callback: (result) => {
        // Toast.show(
        //   NotificationCustom({
        //     message: result
        //       ? t("partnersPage.toast_planning_uploaded", {
        //           lng: i18n.language,
        //         })
        //       : t("partnersPage.toast_planning_uploaded_error", {
        //           lng: i18n.language,
        //         }),
        //   })
        // );

        this.setState({
          showAddPlanningPopUp: false,
          file: null,
          presignedUrl: "",
          path: "",
        });
        if (!result) return;

        // send to backend the file path to save in database
        this.props.createPlanningRequest(path, (err) => {
          let messageKey = "toast_planning_uploaded";
          if (err)
            messageKey =
              err === "planning already imported"
                ? "toast_planning_already_imported"
                : "toast_planning_uploaded_error";
          Toast.show(
            NotificationCustom({
              message: t(`partnersPage.${messageKey}`, { lng: i18n.language }),
            })
          );
        });
      },
    });
  }

  onCancelFormPopUp() {
    this.setState(
      Object.assign({}, this.state, {
        showAddPlanningPopUp: false,
      })
    );
  }

  render() {
    const { t, i18n, planning /* , actionError, partners */ } = this.props;

    // const { months } = this.state;

    // const companies = [
    //   {
    //     id: 1,
    //     name: "Société 1",
    //   },
    //   {
    //     id: 2,
    //     name: "Société 2",
    //   },
    //   {
    //     id: 3,
    //     name: "Société 3",
    //   },
    //   {
    //     id: 4,
    //     name: "Société 4",
    //   },
    //   {
    //     id: 5,
    //     name: "Société 5",
    //   },
    //   {
    //     id: 6,
    //     name: "Société 6",
    //   },
    //   {
    //     id: 7,
    //     name: "Société 7",
    //   },
    //   {
    //     id: 8,
    //     name: "Société 8",
    //   },
    //   {
    //     id: 9,
    //     name: "Société 9",
    //   },
    //   {
    //     id: 10,
    //     name: "Société 10",
    //   },
    // ];

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          {/* Menu and title */}
          <VStack space="5" mt="5">
            <Title>
              {t("partnersPage.title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification menu */}
            <Stack alignItems="end" w="986px">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            <HStack justifyContent="space-between" w="986px">
              {/* Menu */}
              <HStack space="5">
                <MenuLink to="/planning" label="Planification" />
                <MenuLink to="/partner" label="Liste des structures" />
              </HStack>
              {/* Search */}

              {/* Add planning for */}
              <Button
                rightIcon={
                  <img src={addPartnerIcon} alt="ajouter un planning" />
                }
                onPress={this.onPressUploadPlanningButton}
              >
                {t("partnersPage.button_add_planning_for", {
                  lng: i18n.language,
                })}
              </Button>
            </HStack>
          </VStack>

          <HStack space={4}>
            {/* Choose month or create next month */}
            <CPlanning.SelectMonth
              months={planning.plannings}
              selectedMonth={this.state.selectedMonth}
              onSelectMonth={(month) => this.setState({ selectedMonth: month })}
              onPressAddMonth={() => console.log("add month")}
              // this.setState({
              //   // months: ["2023-04-01", ...months],
              //   // selectedMonth: "2023-04-01",
              // })
            />

            {/* Display month planning */}
            {!_.isEmpty(this.state.selectedMonth) && (
              <VStack py={2} space={2} px={4} rounded={4} bg="muted.100">
                <Text fontSize="lg" fontWeight="bold" px={4} pt={4}>
                  Planning importé
                </Text>
                <HStack space={2}>
                  <Text fontSize="sm">Jour</Text>
                  <Text fontSize="sm" bold={this.state.selectedMonth?.haveDay}>
                    {this.state.selectedMonth?.haveDay ? "Oui" : "Non"}
                  </Text>
                </HStack>
                <HStack space={2}>
                  <Text fontSize="sm">Nuit</Text>
                  <Text fontSize="sm" bold={this.state.selectedMonth?.haveDay}>
                    {this.state.selectedMonth?.haveNight ? "Oui" : "Non"}
                  </Text>
                </HStack>
              </VStack>
            )}
          </HStack>
        </VStack>

        {/* Add planning for */}
        <Modal
          isOpen={this.state.showAddPlanningPopUp}
          onClose={this.onCancelFormPopUp}
        >
          <Modal.Content>
            <Modal.Header>
              {t("partnersPage.modal_add_planning_for", { lng: i18n.language })}
            </Modal.Header>
            <Modal.CloseButton onPress={this.onCancelFormPopUp} />
            <Modal.Body>
              <VStack space={3}>
                <FileUploader
                  handleChange={this.onChangeFile}
                  name="file"
                  types={["xlsx"]}
                  multiple={false}
                  label={t("partnersPage.modal_add_planning_for_file", {
                    lng: i18n.language,
                  })}
                />
                <Text textAlign="center">
                  {this.state.file
                    ? `${t(
                        "partnersPage.modal_add_planning_for_name_of_file"
                      )} : ${this.state.file.name}`
                    : t("partnersPage.modal_add_planning_for_no_selected_file")}
                </Text>
              </VStack>
            </Modal.Body>
            <Modal.Footer>
              <HStack space={4}>
                <Button variant="ghost" onPress={this.onCancelFormPopUp}>
                  {t("partnersPage.modal_add_planning_for_cancel", {
                    lng: i18n.language,
                  })}
                </Button>
                <Button
                  onPress={this.onPressUpload}
                  isDisabled={!this.state.file}
                >
                  {t("partnersPage.modal_add_planning_for_confirm", {
                    lng: i18n.language,
                  })}
                </Button>
              </HStack>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    );
  }
}

// <HStack space={4} flex={1} pb={2}>

//   {/* Choose month or create next month */}
//   <CPlanning.SelectMonth
//     months={months}
//     selectedMonth={this.state.selectedMonth}
//     onSelectMonth={(month) => this.setState({ selectedMonth: month })}
//     onPressAddMonth={() =>
//       this.setState({
//         months: ["2023-04-01", ...months],
//         selectedMonth: "2023-04-01",
//       })
//     }
//   />

//   {/* Display month planning */}
//   <CPlanning.PlanMonth
//     selectedMonth={this.state.selectedMonth}
//     companies={companies}
//   />
//   <CPlanning.HistoryMonth selectedMonth={this.state.selectedMonth} />
// </HStack>
const mapStateToProps = (state) => {
  return {
    planning: state.planning.plannings.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    planningsRequest: (...args) =>
      dispatch(PlanningAction.planningsRequest(...args)),
    putPresignedUrl: (...args) =>
      dispatch(MinioAction.putPresignedUrl(...args)),
    createPlanningRequest: (...args) =>
      dispatch(PlanningAction.createPlanningRequest(...args)),
  };
};

const planningRedux = connect(mapStateToProps, mapDispatchToProps)(Planning);
export default withTranslation(["Private", "Common"], { wait: true })(
  planningRedux
);
