import React, { Component } from "react";
import {
  HStack,
  Modal,
  Stack,
  TextArea,
  useClipboard,
  View,
  VStack,
} from "native-base";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SettingsActions from "reducers/setting";
import { Title, NotificationMenu, Button, Text } from "components";
import { useState } from "react";

function ModalTokenExtension({ isOpen, onClose, t }) {
  const { onCopy } = useClipboard();
  const [copied, setCopied] = useState(false);

  function onCopied() {
    onCopy(isOpen);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false}>
      <Modal.Content minWidth="600px" px="" py="41px">
        <Modal.Body alignItems="center">
          <VStack space="30px">
            <VStack space="2">
              <Title fontSize="36px" fontWeight="400">
                {t(`SettingPage.token_authentication.modal.title`)}
              </Title>

              <Text fontSize="14px" color="default">
                {t(`SettingPage.token_authentication.modal.description`)}
              </Text>
              {/* <Text fontSize="12px" color="danger">
                Attention ce token ne sera plus affiché après la fermeture de la modal.
              </Text> */}
            </VStack>

            <HStack space={3}>
              <TextArea value={isOpen} flex={1} /*  h="120px" */ />
              {/* <Input placeholder="token auth" value={isOpen} flex={1} /> */}
              <HStack space={2} alignItems="end">
                <Button onPress={onCopied} third={true}>
                  {t(`button.copy`)}
                </Button>
                <Text px="2" py="1" color={copied ? "black" : "white"}>
                  {t(`SettingPage.token_authentication.modal.copied`)}
                </Text>
              </HStack>
            </HStack>

            <HStack justifyContent="end" space="11px" alignItems="center">
              <Button
                minWidth="140px"
                borderWidth="0px"
                px="36px"
                py="8px"
                onPress={onClose}
              >
                {t(`button.close`).toUpperCase()}
              </Button>
            </HStack>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenExtension: null,
    };

    this.openModalTokenExtension = this.openModalTokenExtension.bind(this);
    this.onPressGenerateTokenExtension =
      this.onPressGenerateTokenExtension.bind(this);
  }

  openModalTokenExtension(token) {
    this.setState({ tokenExtension: token });
  }

  onPressGenerateTokenExtension() {
    this.props.generateTokenExtensionRequest(this.openModalTokenExtension);
  }

  render() {
    const { t } = this.props;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space={5} h="48" mt="5" zIndex={1}>
            <Title>{t(`SettingPage.title`)}</Title>

            {/* Notification */}
            <Stack alignItems="end" w="986px">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
          </VStack>

          <VStack space={2}>
            <Text fontSize="18px" fontWeight={700}>
              {t(`SettingPage.token_authentication.subtitle`)}
            </Text>
            <HStack space={4}>
              <Text>{t(`SettingPage.token_authentication.description`)}</Text>
              <Button onPress={this.onPressGenerateTokenExtension}>
                {t(`SettingPage.token_authentication.button_generate`)}
              </Button>
            </HStack>
          </VStack>
        </VStack>

        {/* Modal to view token extension after close is never showed */}
        <ModalTokenExtension
          isOpen={this.state.tokenExtension}
          onClose={() => this.setState({ tokenExtension: null })}
          t={this.props.t}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // rides: state.historyRides.rides.asMutable({ deep: true }),
    // action: state.historyRides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateTokenExtensionRequest: (...args) =>
      dispatch(SettingsActions.generateTokenExtensionRequest(...args)),
  };
};

const settingsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage);
export default withTranslation(["Private", "Common"], { wait: true })(
  settingsRedux
);
