import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  planningsResetState: null,

  planningsRequest: ["page", "limit", "filter"],
  planningsRequestSuccess: ["plannings"],
  planningsRequestFailure: ["error"],

  createPlanningRequest: ["planning", "callback"],
  createPlanningRequestSuccess: null,
  createPlanningRequestFailure: ["error"],

  deletePlanningRequest: ["planningId"],
  deletePlanningRequestSuccess: null,
  deletePlanningRequestFailure: ["error"],
});

export const PlanningTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  plannings: {
    page: 1,
    limit: 5,
    plannings: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const planningsResetState = (state, action) => {
  return INITIAL_STATE;
};

export const planningsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const planningsRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["plannings"], action.plannings);
};

export const planningsRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------add planning -----------------------*/

export const createPlanningRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const createPlanningRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const createPlanningRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------ delete planning -----------------------*/

export const deletePlanningRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const deletePlanningRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const deletePlanningRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PLANNINGS_RESET_STATE]: planningsResetState,

  [Types.PLANNINGS_REQUEST]: planningsRequest,
  [Types.PLANNINGS_REQUEST_SUCCESS]: planningsRequestSuccess,
  [Types.PLANNINGS_REQUEST_FAILURE]: planningsRequestFailure,

  [Types.CREATE_PLANNING_REQUEST]: createPlanningRequest,
  [Types.CREATE_PLANNING_REQUEST_SUCCESS]: createPlanningRequestSuccess,
  [Types.CREATE_PLANNING_REQUEST_FAILURE]: createPlanningRequestFailure,

  [Types.DELETE_PLANNING_REQUEST]: deletePlanningRequest,
  [Types.DELETE_PLANNING_REQUEST_SUCCESS]: deletePlanningRequestSuccess,
  [Types.DELETE_PLANNING_REQUEST_FAILURE]: deletePlanningRequestFailure,
});
