import { put, takeLatest } from "redux-saga/effects";
import DriverLocationActions, {
  DriverLocationTypes,
} from "reducers/ride/driver-location";
import _ from "lodash";
// import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* samuGetRideDriverLocationAsyncRequest({ driverId }) {
  const query = `
  query{
    SamuGetDriversCurrentLocation(
    driversId:["${driverId}"]
    ) {
      driverId
      longitude 
      latitude
      plate
    }
  }
  `;
  try {
    const { SamuGetDriversCurrentLocation } = yield graphQLClient().request(
      query
    );
    yield put(
      DriverLocationActions.samuGetRideDriverLocationRequestSuccess(
        SamuGetDriversCurrentLocation[0]
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.samuGetRideDriverLocationRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

function* samuGetRidesWaitingTimeAsyncRequest({ ridesId }) {
  let _ridesId = _.reduce(ridesId, (acc, e) => [...acc, `"${e}"`], []);

  const query = `
  query{SamuGetRideWaitingTime(
      ridesId:[${_ridesId.join(",")}]
      ){
          rideId, 
          duration, 
          distance
        }
    }
  `;

  try {
    const { SamuGetRideWaitingTime } = yield graphQLClient().request(query);

    yield put(
      DriverLocationActions.samuGetRidesWaitingTimeRequestSuccess(
        SamuGetRideWaitingTime
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.samuGetRidesWaitingTimeRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

function* samuGetDriversLocationAsyncRequest({ driversId }) {
  let _driversId = _.reduce(driversId, (acc, e) => [...acc, `"${e}"`], []);
  const query = `
    query{
      SamuGetDriversCurrentLocation(
        driversId: [${_driversId.join(",")}]
      ) {
        driverId
        longitude 
        latitude
        plate
        status
      }
    }
  `;
  try {
    const { SamuGetDriversCurrentLocation } = yield graphQLClient().request(
      query
    );
    yield put(
      DriverLocationActions.samuGetDriversLocationSuccess(
        SamuGetDriversCurrentLocation
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.samuGetDriversLocationFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}
/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(
    DriverLocationTypes.SAMU_GET_RIDE_DRIVER_LOCATION_REQUEST,
    samuGetRideDriverLocationAsyncRequest
  ),
  takeLatest(
    DriverLocationTypes.SAMU_GET_RIDES_WAITING_TIME_REQUEST,
    samuGetRidesWaitingTimeAsyncRequest
  ),
  takeLatest(
    DriverLocationTypes.SAMU_GET_DRIVERS_LOCATION_REQUEST,
    samuGetDriversLocationAsyncRequest
  ),
];
