import { Component } from "react";
import { Box, HStack, Modal, Pressable, VStack } from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { Button, Text, Title, Card } from "components";
import OngoingRidesActions from "reducers/transporter/transporter-ongoing-rides";
import { connect } from "react-redux";

const CANCEL_RIDE_REASON = {
  REFUSAL_OF_SUPPORT_BY_PATIENT: "REFUSAL_OF_SUPPORT_BY_PATIENT",
  CANCELLED_BY_SAMU: "CANCELLED_BY_SAMU",
};

class TrashRideModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMotif: "",
    };

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentWillUnmount() {
    this.resetState();
  }

  onSubmitForm() {
    const { ride, onCancelRideRequest } = this.props;
    onCancelRideRequest(ride._id, this.state.selectedMotif, (isSuccess) => {
      if (isSuccess) this.onCancel();
    });
  }

  onCancel() {
    this.props.onClose();
    this.resetState();
  }

  resetState() {
    this.setState({
      selectedMotif: "",
    });
  }

  render() {
    const { t, i18n, ride, action } = this.props;

    if (!ride) return null;
    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="517px" px="" py="41px">
          <Modal.Header>
            {/* TITLE */}
            <VStack space="2">
              <Title
                fontSize="36px"
                fontWeight="400"
                textAlign="center"
                fontFamily="Lato"
              >
                {t("trashRidePopUp.title_headline", {
                  lng: i18n.language,
                })}
              </Title>

              <Text fontSize="14px" color="default" textAlign="center">
                {t("trashRidePopUp.subtitle_headline", {
                  lng: i18n.language,
                  rideNumber: ride?.rideNumber,
                })}
              </Text>
              {action?.error && (
                <Text color="danger">
                  {t("trashRidePopUp.fail_trash", { lng: i18n.language })}
                </Text>
              )}
            </VStack>
          </Modal.Header>

          <Modal.Body>
            <VStack space={1}>
              {Object.values(CANCEL_RIDE_REASON).map((motif) => (
                <Pressable
                  key={motif}
                  onPress={() => this.setState({ selectedMotif: motif })}
                >
                  <Card
                    borderWidth="1px"
                    rounded={4}
                    borderColor={
                      this.state.selectedMotif === motif ? "primary" : "gray"
                    }
                  >
                    <HStack
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text
                        fontSize="14px"
                        color={
                          this.state.selectedMotif === motif
                            ? "primary"
                            : "dark"
                        }
                      >
                        {t(`trashRidePopUp.motif.${motif}`, {
                          lng: i18n.language,
                        })}
                      </Text>
                      <Box
                        size={15}
                        rounded="full"
                        bg={
                          this.state.selectedMotif === motif
                            ? "primary"
                            : "white"
                        }
                      />
                    </HStack>
                  </Card>
                </Pressable>
              ))}
            </VStack>
          </Modal.Body>

          <Modal.Footer bg="light">
            {/* BUTTONS */}
            <HStack justifyContent="end" space="11px" alignItems="center">
              <Button
                minWidth="140px"
                third
                borderWidth="1px"
                px="36px"
                py="8px"
                onPress={this.onCancel}
              >
                {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
              </Button>

              <Button
                minWidth="140px"
                isDisabled={_.isEmpty(this.state.selectedMotif)}
                borderWidth="0px"
                px="36px"
                py="8px"
                onPress={this.onSubmitForm}
              >
                {t(`button.confirm`, { lng: i18n.language }).toUpperCase()}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  action: state.onGoingRides.action.asMutable({ deep: true }),
});

const mapDispatchToProps = (dispatch) => ({
  onCancelRideRequest: (...args) =>
    dispatch(OngoingRidesActions.onCancelRideRequest(...args)),
});

const trashRideModalRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrashRideModal);

export default withTranslation(["Private", "Common"], { wait: true })(
  trashRideModalRedux
);
