import { Component } from "react";
import { HStack, Modal } from "native-base";
import _ from "lodash";
import Button from "./Button";
import { Text, Title } from "./Text";
import logo from "assets/img/resaam-logo.svg";

class LogoutConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    this.onConfirmAction = this.onConfirmAction.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);
  }

  onCancelAction() {
    const { onClose } = this.props;
    if (_.isFunction(onClose)) onClose();
  }

  onConfirmAction() {
    const { onConfirm } = this.props;
    if (_.isFunction(onConfirm)) onConfirm();
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="500px" px="5%" py="8">
          <Modal.CloseButton />
          <Modal.Body alignItems="center">
            <img src={logo} alt="resaam-logo" width="60%" />
            <Title fontSize="24px">
              {t("Common:Dialog.LogoutConfirmation.header_label", {
                lng: i18n.language,
              })}
            </Title>
            <Text>
              {t("Common:Dialog.LogoutConfirmation.confirmation_message", {
                lng: i18n.language,
              })}
            </Text>
          </Modal.Body>
          <Modal.Footer bg="grayLigth">
            <HStack justifyContent="center" w="full" space="4">
              <Button w="40%" third onPress={() => this.onCancelAction()}>
                {t("Common:Dialog.LogoutConfirmation.cancel_button", {
                  lng: i18n.language,
                })}
              </Button>

              <Button w="40%" onPress={() => this.onConfirmAction()}>
                {t("Common:Dialog.LogoutConfirmation.confirm_button", {
                  lng: i18n.language,
                })}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}

export default LogoutConfirmationDialog;
