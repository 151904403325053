import { Text } from "./Text";

import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HStack, Pressable } from "native-base";

function DownloadShowButton({
  label = "Fiche bilan",
  onShow = () => null,
  onDownload = () => null,
  hideDownload = false,
  hideShow = false,
  hideLabel = false,
  isDanger = false,
}) {
  return (
    <HStack space={2} alignItems="center">
      {!hideLabel && (
        <Text fontSize="12px" w="16" fontWeight="500" color="gray">
          {label}
        </Text>
      )}

      {!hideShow && (
        <Pressable onPress={onShow}>
          <FontAwesomeIcon
            size={isDanger ? "lg" : "1x"}
            icon={faEye}
            style={{
              color: isDanger ? "red" : "rgb(95, 174, 232)",
            }}
          />
        </Pressable>
      )}

      {!hideDownload && (
        <Pressable onPress={onDownload}>
          <FontAwesomeIcon
            size="1x"
            icon={faDownload}
            style={{ color: "rgb(95, 174, 232)" }}
          />
        </Pressable>
      )}
    </HStack>
  );
}

export default DownloadShowButton;
