import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Icon, Input } from "native-base";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import searchIcon from "assets/img/search-input-icon.svg";

class FloatingInput extends Component {
  constructor(props) {
    super(props);

    this.togglePasswordHidden = this.togglePasswordHidden.bind(this);

    this.state = {
      passwordHidden: true,
    };
  }

  togglePasswordHidden() {
    this.setState(
      Object.assign({}, this.state, {
        passwordHidden: !this.state.passwordHidden,
      })
    );
  }

  render() {
    const { label = "Email", inputType = "text", error = false } = this.props;
    const { passwordHidden } = this.state;
    const inputProps = {
      w: "full",
      borderWidth: "1px",
      borderRadius: "4px",
      borderColor: error ? "danger" : "gray",
      color: "default",
      fontFamily: "Lato",
      fontSize: "14px",
      _focus: {
        borderColor: error ? "danger" : "gray",
      },
    };

    if (inputType === "search") {
      return (
        <Input
          {...inputProps}
          w="sm"
          type={inputType}
          placeholder={label}
          InputLeftElement={
            <img
              src={searchIcon}
              style={{ marginLeft: "10px" }}
              alt="recherche"
            />
          }
          {...this.props}
        />
      );
    }

    if (inputType === "password") {
      return (
        <Input
          {...inputProps}
          type={passwordHidden ? "password" : "text"}
          InputRightElement={
            <Button
              size="xs"
              rounded="none"
              w="1/6"
              h="full"
              onPress={this.togglePasswordHidden}
            >
              <Icon
                as={
                  <FontAwesomeIcon
                    icon={passwordHidden ? faEyeSlash : faEye}
                    size="lg"
                    style={{ color: "#999C9F" }}
                  />
                }
              />
            </Button>
          }
          placeholder={label}
          {...this.props}
        />
      );
    }
    return (
      <Input
        {...inputProps}
        placeholder={label}
        type={this.props.inputType}
        {...this.props}
      />
    );
  }
}

export default FloatingInput;
