import React, { Component, useEffect } from "react";
import { Modal, Stack } from "native-base";
import { withTranslation } from "react-i18next";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import resaamVehicle from "assets/img/AmbulanceMap.svg";
import LieuDarrivee from "assets/img/Lieu-darrivee.svg";
import LieuDePriseEnCharge from "assets/img/Lieu-de-prise-en-charge.svg";
import googleMapStyle from "assets/googleMapStyle";

// const STATUS_MAP_TEXT_COLOR_WHITE = [
//   "free",
//   "offline",
//   "busy",
//   "disconnect",

//   // "onway",
//   "onboard",
//   "waiting",
//   "arrived",
// ];

function MapView(props) {
  const zoom = 13;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [direction, setDirection] = React.useState(null);

  /*const onLoad = React.useCallback(function callback (map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])*/

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fitBounds = () => {
    let markers = [];
    if (props.from && props.coords.length === 1) markers.push(props.from);
    else if (props.coords && props.coords.length > 1)
      props.coords.map((coord) => {
        if (coord.latitude && coord.longitude) markers.push(coord);
      });
    if (props.to) markers.push(props.to);
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((position) => {
      bounds.extend(position);
      return position;
    });
    map.fitBounds(bounds);
    // map.setZoom(zoom);
  };
  const onLoadMap = (map) => {
    setMap(map);
  };

  const getDirection = async () => {
    const directionService = new window.google.maps.DirectionsService();
    const direction = await directionService.route({
      origin: new window.google.maps.LatLng(props.from),
      destination: new window.google.maps.LatLng(props.to),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setDirection(direction);
  };

  useEffect(() => {
    if (map) {
      map.setZoom(zoom);
      fitBounds();
      if (props.from && props.to) getDirection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100%" }}
      // center={props.center}
      options={googleMapStyle}
      onLoad={onLoadMap}
      onUnmount={onUnmount}
      zoom={zoom}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {props.coords &&
        props.coords.length > 1 &&
        props.coords.map((coord) => (
          <Marker
            key={coord.driverId}
            position={coord}
            label={{
              text: coord.plate.toUpperCase(),
              // STATUS_MAP_TEXT_COLOR_WHITE.includes(coord.status) ? "white" : "black",
              color: "onway" === coord.status ? "black" : "white",
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Lato",
              className: "vehicle-map vehicle-status-" + coord.status,
            }}
            icon={{
              url: coord.icon,
              labelOrigin: new window.google.maps.Point(40, 40),
            }}
          />
        ))}

      {props.from && props.coords.length === 1 && (
        <Marker
          position={props.from}
          label={props.from.plate}
          icon={{
            url: props.from.icon,
            labelOrigin: new window.google.maps.Point(40, 35),
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}
      {props.to && (
        <Marker
          position={props.to}
          icon={{
            url: props.to.icon,
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}

      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            markerOptions: { icon: " ", anchorPoint: { x: 0.2, y: 0.2 } },
            polylineOptions: {
              zIndex: 10,
              strokeColor: "#9273FF",
              strokeWeight: 5,
            },
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

class DriverLocationMapModal extends Component {
  // renderMarkers() {
  //   // const coordinates = { longitude: null, latitude: null, plate: "" };
  //   const { coordinates } = this.props;
  //   return coordinates.map(({ latitude, longitude, plate }, key) => (
  //     <Marker
  //       position={{ lat: latitude, lng: longitude }}
  //       label={plate}
  //       icon={{
  //         url: resaamVehicle,
  //         labelOrigin: new window.google.maps.Point(20, 40),
  //       }}
  //       key={key}
  //     />
  //   ));
  // }

  render() {
    // const { t, i18n } = this.props;
    const { coordinates = [], ride } = this.props;
    // if(!_.isObject(ride)) return null

    let latitude = 48.856614;
    let longitude = 2.3522219;

    if (coordinates.length > 0) {
      if (coordinates[0]?.latitude && coordinates[0]?.longitude) {
        latitude = coordinates[0].latitude;
        longitude = coordinates[0].longitude;
      }
    }

    let to = undefined;
    if (ride && ride.status === "onway") {
      to = {
        lat: ride.pickup.latitude,
        lng: ride.pickup.longitude,
        icon: LieuDePriseEnCharge,
      };
    } else if (
      ride &&
      ride.status === "onboard" &&
      ride.dropoff &&
      ride.dropoff.longitude &&
      ride.dropoff.latitude
    ) {
      to = {
        lat: ride.dropoff.latitude,
        lng: ride.dropoff.longitude,
        icon: LieuDarrivee,
      };
    }

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="588px" height="545px">
          {/* <Modal.CloseButton /> */}
          {/* <Modal.Header alignItems="center" borderBottomWidth="0px">
            <Text fontSize="24px" fontWeight="900" lineHeight="29px">
              Refuser une série
            </Text>
          </Modal.Header> */}
          <Modal.Body style={{ width: 588, height: 545 }}>
            <Stack top={0} bottom={0} right={0} left={0} position="absolute">
              <MapView
                coords={coordinates.map((coord) => ({
                  ...coord,
                  lng: coord.longitude,
                  lat: coord.latitude,
                  icon: resaamVehicle,
                }))}
                center={{ lat: latitude, lng: longitude }}
                from={{ lat: latitude, lng: longitude, icon: resaamVehicle }}
                to={to}
              />
            </Stack>
          </Modal.Body>
          {/* <Modal.Footer bg="light"></Modal.Footer> */}
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  DriverLocationMapModal
);
