import VehicleAmbulanceIcon from "assets/img/ride/vehicle_ambulance.svg";
import VehicleAmbulanceHoverIcon from "assets/img/ride/vehicle_ambulance_hover.svg";
import VehicleVslIcon from "assets/img/ride/vehicle_vsl.svg";
import VehicleVslHoverIcon from "assets/img/ride/vehicle_vsl_hover.svg";

const VEHICLE_CATEGORIES = ["AMBULANCE", "VSL"];

const VEHICLE_OPTIONS = {
  OXYGEN: "Oxigène",
  CHILD_SAFETY_SEAT: "Siège enfant",
  PERFUSION: "Perfusion",
  POUSSE_SERINGUE: "Pousse seringue",
  ISOLEMENT: "Isolement",
  WHEELCHAIR: "Wheelchair",
};

const DRIVER_STATUS = {
  disconnected: "Déconnecté",
  offline: "Hors ligne",
  free: "Libre",
  busy: "Occupé",
  invoked: "En attente",
};

const RIDE_STATUS = {
  incoming: "Attribution en cours",
  pending: "Course en attente",
  onway: "Vehicule en chemin",
  waiting: "Véhicule arrivé",
  onboard: "Patient à bord",
  arrived: "Patient arrivé",
  completed: "Course terminée",
  canceled: "Reservation annulée",
};

const PATIENT_RIDE_STATUS = {
  incoming: "Attribution en cours...",
  onway: "Vehicule en chemin",
  waiting: "Votre vehicule vous attend",
  onboard: "Vous êtes à bord",
  arrived: "Vous êtes arrivé",
  completed: "Votre transport est terminé",
  canceled: "Reservation annulée",
};

const VEHICLE_ICONS = {
  hover: {
    AMBULANCE: VehicleAmbulanceHoverIcon,
    VSL: VehicleVslHoverIcon,
  },
  default: {
    AMBULANCE: VehicleAmbulanceIcon,
    VSL: VehicleVslIcon,
  },
};

const MACRO_DASHBOARD_PERIODS = {
  HOUR: "HOUR",
  DAY: "DAY",
  DAY_MONTH: "DAY_MONTH",
  WEEK: "WEEK",
  MONTH: "MONTH",
  // YEAR: "YEAR",
};

const MACRO_DATE_CONVERTER = {
  HOUR: "HH", // heure de la journée 0 à 23
  DAY: "E", // jour de la semaine 0 à 6
  DAY_MONTH: "DD", // jour du mois 1 à 31
  MONTH: "MM", // mois de l'année 1 à 12
  WEEK: "W", // Semaine de l'année (0 à 52)
  YEAR: "YYYY", // année
};

const EXPORT_DOCUMENT_FORMAT = {
  PDF: "PDF",
  EXCEL: "EXCEL",
  CSV: "CSV",
};

export {
  RIDE_STATUS,
  PATIENT_RIDE_STATUS,
  DRIVER_STATUS,
  VEHICLE_CATEGORIES,
  VEHICLE_OPTIONS,
  VEHICLE_ICONS,
  MACRO_DASHBOARD_PERIODS,
  MACRO_DATE_CONVERTER,
  EXPORT_DOCUMENT_FORMAT,
};
