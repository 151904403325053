import { Component } from "react";
import { Box, Center, HStack, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import OngoingRidesActions from "reducers/transporter/transporter-ongoing-rides";
import DriverLocationActions from "reducers/ride/driver-location";
import VehicleActions from "reducers/transporter/transporter-vehicle";
import MinioActions from "reducers/minio";
import NewRidesActions from "reducers/ride/new-ride";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "global/local-dates";
import "assets/style.css";
import noDataIcon from "assets/img/no_data.svg";

import {
  Title,
  Button,
  Text,
  OnGoingRidesCard,
  FloatingInput,
  NotificationMenu,
  DriverLocationMapModal,
  TrashRideModal,
} from "components";
import { downloadFilesAsBlob } from "functions";

const FETCH_RIDES_TIME = 10_000; //10s
const APP_STAGE = process.env.REACT_APP_STAGE || "dev";
const FILL_FORM = APP_STAGE !== "prod";

class OnGoingRides extends Component {
  constructor(props) {
    super(props);

    this.onSearchOngoingRidesFilter =
      this.onSearchOngoingRidesFilter.bind(this);
    this.closePopupCreateRideView = this.closePopupCreateRideView.bind(this);
    this.createSamuRide = this.createSamuRide.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onShowMap = this.onShowMap.bind(this);

    this.onInputForm = this.onInputForm.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.onOpenTrashRideModal = this.onOpenTrashRideModal.bind(this);

    this.state = {
      searchInputValue: "",
      filterDates: [],
      selectedRide: null,
      showMapOpen: false,
      selectedRideToShowDriverLocation: 0,
      showCreateRidePopup: false,
      showSuccessCreateRidePopup: false,
      showTrashRideModal: false,
      drivers: [],
      initFormValue: {
        id: !FILL_FORM ? "" : "1234",
        fname: !FILL_FORM ? "" : "SIMONE",
        lname: !FILL_FORM ? "" : "PIVOT",
        age: !FILL_FORM ? "" : 80,
        phone: !FILL_FORM ? "" : "",
        motif: !FILL_FORM ? "" : "TRAUMA CRANIEN",
        nvoie: !FILL_FORM ? "" : "130",
        voie: !FILL_FORM ? "" : "AVENUE DE VERSAILLES",
        zcode: !FILL_FORM ? "" : "75015",
        commune: !FILL_FORM ? "" : "PARIS",
        batiment: !FILL_FORM ? "" : "PRINCIPAL",
        dgicode: !FILL_FORM ? "" : "12B12",
        etage: !FILL_FORM ? "" : "RDC",
        note: !FILL_FORM ? "" : "NOTE",
      },
    };
  }

  onInputForm(ref) {
    const { name, value } = ref.target;
    const { initFormValue } = this.state;
    initFormValue[name] = value;
    this.setState(Object.assign({}, this.state, { initFormValue }));
  }

  componentDidMount() {
    const { onGoingRidesRequest, vehiclesRequest } = this.props;
    onGoingRidesRequest(1, 10);

    // drivers to create ride and assign when is manual
    vehiclesRequest(1, 100);

    this.rideIntervalId = setInterval(() => {
      const { filterDates, searchInputValue } = this.state;

      onGoingRidesRequest(1, 100, {
        text: searchInputValue,
        dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
      });
    }, [FETCH_RIDES_TIME]);

    this.driverLocationIntervalId = setInterval(() => {
      const { showMapOpen, selectedRideToShowDriverLocation } = this.state;

      if (!showMapOpen) return;

      this.getCurrentDriverLocationForSelectedRide(
        selectedRideToShowDriverLocation
      );
    }, 30 * 1000); // 30s
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterDates, searchInputValue /* , showCreateRidePopup */ } =
      this.state;
    if (
      prevState.filterDates !== filterDates ||
      prevState.searchInputValue !== searchInputValue
    ) {
      this.props.onGoingRidesRequest(1, 10, {
        text: searchInputValue,
        dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
      });
    }
    // if (showCreateRidePopup !== prevState.showCreateRidePopup) {
    // if (showCreateRidePopup) {
    // this.props.searchFreeDriversForRideRequest(drivers => {
    //   this.setState({ drivers });
    // });
    // }
    // }
  }

  componentWillUnmount() {
    clearInterval(this.rideIntervalId);
    clearInterval(this.driverLocationIntervalId);
  }

  onOpenTrashRideModal(ride) {
    this.setState({
      selectedRide: ride,
      showTrashRideModal: true,
    });
  }

  downloadFiles(paths, patient) {
    this.props.getPresignedUrl(
      paths.map((e) => e.path),
      async (urls) => {
        downloadFilesAsBlob(urls, paths, patient);
      }
    );
  }

  onSearchOngoingRidesFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
  }

  fetchData() {
    const { onGoingRidesRequest, rides } = this.props;
    const { page, limit, totalPages } = rides;
    if (page < totalPages) {
      onGoingRidesRequest(page + 1, limit, {
        text: this.state.searchInputValue,
        dates: this.state.filterDates,
      });
    }
  }

  onShowMap(ride) {
    this.setState({
      showMapOpen: true,
      selectedRideToShowDriverLocation: ride,
    });
    this.getCurrentDriverLocationForSelectedRide(ride);
  }

  getCurrentDriverLocationForSelectedRide(ride) {
    const { samuGetRideDriverLocationRequest } = this.props;
    //in future check if ride is transporter or taxi
    samuGetRideDriverLocationRequest(ride.transporter.driver);
  }

  popupSuccessView() {
    if (!this.state.showSuccessCreateRidePopup) return null;
    return (
      <div
        id="resaam-success-popup"
        className="modal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <h1 className="title">Une nouvelle intervention a été crée</h1>
        </div>
      </div>
    );
  }

  closePopupCreateRideView() {
    this.setState(
      Object.assign({}, this.state, { showCreateRidePopup: false })
    );
  }

  createSamuRide() {
    const { samuCreateNewRideRequest } = this.props;

    const resaamFormIds = [
      "resaam-observation",
      "resaam-intervention-id",
      "resaam-intervention-phone",
      "resaam-zip-code",
      "resaam-commune",
      "resaam-street-number",
      "resaam-street",
      "resaam-poi",
      "resaam-batiment",
      "resaam-dgicode",
      "resaam-intervention-etage",
      "resaam-intervention-adresse",
      "resaam-victime-age",
      "resaam-victime-nom",
      "resaam-victime-prenom",
      "resaam-victime-genre",
      "resaam-motif-intervention",
      "resaam-mode",
      "resaam-urgence",
      "resaam-note",
    ];

    const data = resaamFormIds.reduce(function (acc, el) {
      return [
        ...acc,
        {
          field: el,
          value: document.getElementById(el).value,
        },
      ];
    }, []);

    const mapData = (key) => {
      switch (key) {
        case "resaam-observation":
          return "Observation";
        case "resaam-intervention-id":
          return "InterventionId";
        case "resaam-intervention-phone":
          return "PatientPhone";
        case "resaam-zip-code":
          return "InterventionZipCode";
        case "resaam-commune":
          return "InterventionCommune";
        case "resaam-street-number":
          return "InterventionStreetNumber";
        case "resaam-street":
          return "InterventionStreet";

        case "resaam-poi":
          return "InterventionPOI";
        case "resaam-batiment":
          return "InterventionBatiment";
        case "resaam-dgicode":
          return "InterventionDigitCode";
        case "resaam-intervention-etage":
          return "InterventionEtage";

        case "resaam-intervention-adresse":
          return "InterventionAddress";

        case "resaam-victime-age":
          return "PatientAge";

        case "resaam-victime-nom":
          return "PatientNom";

        case "resaam-victime-prenom":
          return "PatientPrenom";

        case "resaam-victime-genre":
          return "PatientGenre";
        case "resaam-motif-intervention":
          return "InterventionMotif";
        case "resaam-mode":
          return "InterventionMode";
        case "resaam-urgence":
          return "InterventionUrgence";
        case "resaam-note":
          return "InterventionNote";
        default:
          return null;
      }
    };

    let params = data.reduce(function (acc, el) {
      const { field, value } = el;
      return [...acc, `${mapData(field)}:"${value.replace(/\n/g, " ")}"`];
    }, []);

    samuCreateNewRideRequest(params, () => {
      this.setState(
        Object.assign({}, this.state, {
          showCreateRidePopup: false,
          showSuccessCreateRidePopup: true,
        })
      );

      setTimeout(() => {
        this.setState(
          Object.assign({}, this.state, {
            showSuccessCreateRidePopup: false,
          })
        );
      }, 2500);
    });
  }

  popupCreateRideView() {
    const { newRide, drivers } = this.props;
    const { loading, error } = newRide.action;

    if (!this.state.showCreateRidePopup) return null;
    // const defaultId = Math.floor(Math.random() * 99999) + 10000;
    const { initFormValue } = this.state;

    return (
      <div id="resaam-popup" className="modal" style={{ display: "block" }}>
        <div className="modal-dialog">
          <span
            onClick={this.closePopupCreateRideView}
            className="close"
            title="Close Modal"
          >
            ×
          </span>

          <h1 className="title">Demande d'intervention</h1>
          {loading ? <div id="resaam-loading" /> : null}

          <p id="resaam-error-msg">{error}</p>
          <div className="modal-content animate">
            <div className="column">
              <h2>Informations victime</h2>
              <div className="form-group">
                <label>Identifiant</label>
                <input
                  type="text"
                  id="resaam-intervention-id"
                  name="id"
                  value={initFormValue.id}
                  onInput={this.onInputForm}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-group">
                  <label>Prénom</label>
                  <input
                    type="text"
                    id="resaam-victime-prenom"
                    onInput={this.onInputForm}
                    name="fname"
                    value={initFormValue.fname}
                  />
                </div>
                <div className="form-group">
                  <label>Nom</label>
                  <input
                    type="text"
                    id="resaam-victime-nom"
                    onInput={this.onInputForm}
                    name="lname"
                    value={initFormValue.lname}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-group">
                  <label>Age</label>
                  <input
                    name="age"
                    type="text"
                    id="resaam-victime-age"
                    onInput={this.onInputForm}
                    value={initFormValue.age}
                  />
                </div>
                <div
                  className="form-group"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <label>Sexe</label>
                  <select
                    name="resaam-victime-genre"
                    id="resaam-victime-genre"
                    defaultValue={FILL_FORM ? "female" : null}
                  >
                    <option value="unknown"></option>
                    <option value="female">Féminin</option>
                    <option value="male">Masculin</option>
                    <option value="other">Indéterminé</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-group">
                  <label>Téléphone</label>
                  <input
                    type="text"
                    id="resaam-intervention-phone"
                    onInput={this.onInputForm}
                    name="phone"
                    value={initFormValue.phone}
                  />
                </div>
                <div className="form-group">
                  <label>Motif</label>
                  <input
                    type="text"
                    id="resaam-motif-intervention"
                    onInput={this.onInputForm}
                    name="motif"
                    value={initFormValue.motif}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Observation</label>
                <textarea
                  id="resaam-observation"
                  name="resaam-observation"
                  rows="4"
                  cols="50"
                  defaultValue={!FILL_FORM ? "" : "EHPAD IDE SUR PLACE"}
                />
              </div>
            </div>

            <div className="column">
              <h2>Informations intervention</h2>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-group">
                  <label>Numéro voie</label>
                  <input
                    type="text"
                    id="resaam-street-number"
                    onInput={this.onInputForm}
                    name="nvoie"
                    value={initFormValue.nvoie}
                  />
                </div>
                <div className="form-group">
                  <label>Voie</label>
                  <input
                    type="text"
                    id="resaam-street"
                    onInput={this.onInputForm}
                    name="voie"
                    value={initFormValue.voie}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-group">
                  <label>Code postal</label>
                  <input
                    type="text"
                    id="resaam-zip-code"
                    onInput={this.onInputForm}
                    name="zcode"
                    value={initFormValue.zcode}
                  />
                </div>
                <div className="form-group">
                  <label>Commune</label>
                  <input
                    type="text"
                    id="resaam-commune"
                    onInput={this.onInputForm}
                    name="commune"
                    value={initFormValue.commune}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Complement adresse</label>
                <textarea
                  id="resaam-intervention-adresse"
                  name="resaam-intervention-adresse"
                  rows="4"
                  cols="50"
                  defaultValue={!FILL_FORM ? "" : "EHPAD DES ROSIERS RDC CH 18"}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-group">
                  <label>Batiment</label>
                  <input
                    type="text"
                    id="resaam-batiment"
                    onInput={this.onInputForm}
                    name="batiment"
                    value={initFormValue.batiment}
                  />
                </div>
                <div className="form-group">
                  <label>Digit Code</label>
                  <input
                    type="text"
                    id="resaam-dgicode"
                    onInput={this.onInputForm}
                    name="dgicode"
                    value={initFormValue.dgicode}
                  />
                </div>
                <div className="form-group">
                  <label>Etage</label>
                  <input
                    type="text"
                    id="resaam-intervention-etage"
                    onInput={this.onInputForm}
                    name="etage"
                    value={initFormValue.etage}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>POI</label>
                <input type="text" id="resaam-poi" />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "20px",
              padding: "0 15px 15px 15px",
            }}
          >
            <div
              className="choose-mode"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <label>Choix du véhicule</label>
              <div className="form-group">
                <select name="resaam-mode" id="resaam-mode" defaultValue="auto">
                  <option value="auto">Automatique</option>

                  {drivers.vehicles.map((driver, key) => (
                    <option value={driver._id} key={key}>
                      {driver?.company?.name} - {driver?.vehicle?.plate}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div
              className="choose-urgence"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <label>Urgence</label>
              <div className="form-group">
                <select name="resaam-urgence" id="resaam-urgence">
                  <option value="15">15 mins</option>
                  <option value="30">30 mins</option>
                  <option value="60">+60 mins</option>
                </select>
              </div>
            </div>

            <div
              class="resaam-container-note"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <label>Note</label>
              <textarea
                id="resaam-note"
                name="note"
                onInput={this.onInputForm}
                value={initFormValue.note}
                rows="4"
                cols="50"
              />
              <input type="hidden" id="resaam-note" />
            </div>
          </div>

          <div className="footer">
            <button
              className="cancel-btn"
              id="resaam-cancel-button"
              onClick={this.closePopupCreateRideView}
            >
              Annuler
            </button>
            <button
              disabled={loading}
              className="booking-btn"
              id="resaam-booking-button"
              onClick={this.createSamuRide}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t, i18n /* , action */, rides, driverCoords } = this.props;

    const { searchInputValue, selectedRideToShowDriverLocation } = this.state;
    let coords = {};

    if (
      selectedRideToShowDriverLocation !== 0 &&
      selectedRideToShowDriverLocation.transporter.driver ===
        driverCoords.driverId
    ) {
      coords = driverCoords;
    }

    return (
      <View w="full">
        {this.popupSuccessView()}
        {this.popupCreateRideView()}
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space={5} h="48" my="5" zIndex={1}>
            {/* {error !== '' && (
              <Alert bsStyle='danger'>
                <span>{error}</span>
              </Alert>
            )} */}

            <Title>
              {t("TransporterReservationsPage.reservation_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification */}
            <Stack alignItems="end" w="986px">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            {/* Search */}

            <VStack w="986px">
              <HStack justifyContent="space-between" alignItems="center">
                <FloatingInput
                  inputType="search"
                  label={t("searchBar", { lng: i18n.language })}
                  onChange={this.onSearchOngoingRidesFilter}
                  value={searchInputValue}
                />

                <Box justifyContent="center" position="relative">
                  <Calendar
                    multiple={true}
                    value={this.state.filterDates}
                    weekStartDayIndex={1}
                    disableDayPicker={false}
                    showOtherDays={false}
                    calendarPosition="Right Top"
                    className="container-input-calendar"
                    render={<InputIcon />}
                    locale={datepickerLocal}
                    shadow={false}
                    onChange={(selectedDates) => {
                      this.setState({ filterDates: selectedDates });
                    }}
                  />
                </Box>
              </HStack>

              <HStack justifyContent="flex-end">
                <Button
                  mt={2}
                  _text={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "white",
                  }}
                  onPress={() => {
                    this.setState(
                      Object.assign({}, this.state, {
                        showCreateRidePopup: true,
                      })
                    );
                  }}
                >
                  AJOUTER UNE INTERVENTION
                </Button>
              </HStack>
            </VStack>
          </VStack>

          {/* No Data */}
          {rides.rides.length === 0 && (
            <Center flex={1}>
              <img src={noDataIcon} alt="no data" />
              <Text fontSize="18">
                {t("TransporterReservationsPage.message_when_no_data")}
              </Text>
            </Center>
          )}

          <div
            id="scrollableDiv"
            style={{
              overflow: "auto",
              marginTop: 10,
            }}
          >
            <InfiniteScroll
              dataLength={rides.rides.length} //This is important field to render the next data
              next={this.fetchData}
              hasMore={true}
              scrollableTarget="scrollableDiv"
              loader={
                <div></div>
                // <Loader
                //   type='ThreeDots'
                //   color='#00BFFF'
                //   height={100}
                //   width={100}
                //   visible={loading}
                // />
              }
            >
              {rides.rides.map((ride, key) => {
                return (
                  <OnGoingRidesCard
                    {...{ t, i18n }}
                    ride={ride}
                    key={key}
                    mb="10px"
                    onShowMap={this.onShowMap}
                    downloadFiles={this.downloadFiles}
                    onOpenTrashRideModal={this.onOpenTrashRideModal}
                    token={this.props?.account?.token}
                    updateDropoffRequest={this.props.updateDropoffRequest}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </VStack>

        <DriverLocationMapModal
          isOpen={this.state.showMapOpen}
          center={coords}
          coordinates={[coords]}
          ride={selectedRideToShowDriverLocation}
          onClose={() => {
            this.setState(
              Object.assign({}, this.state, { showMapOpen: false })
            );
          }}
        />

        <TrashRideModal
          isOpen={this.state.showTrashRideModal}
          onClose={() => this.setState({ showTrashRideModal: false })}
          ride={this.state.selectedRide}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.onGoingRides.rides.asMutable({ deep: true }),
    action: state.onGoingRides.action.asMutable({ deep: true }),
    driverCoords: state.driverLocation.coords.asMutable({ deep: true }),
    newRide: state.newRide.asMutable({ deep: true }),
    drivers: state.transporterVehicles.vehicles.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    samuCreateNewRideRequest: (...args) =>
      dispatch(NewRidesActions.samuCreateNewRideRequest(...args)),
    onGoingRidesRequest: (...args) =>
      dispatch(OngoingRidesActions.onGoingRidesRequest(...args)),
    samuGetRideDriverLocationRequest: (...args) =>
      dispatch(DriverLocationActions.samuGetRideDriverLocationRequest(...args)),

    getPresignedUrl: (...args) =>
      dispatch(MinioActions.getPresignedUrl(...args)),
    vehiclesRequest: (...args) =>
      dispatch(VehicleActions.vehiclesRequest(...args)),

    updateDropoffRequest: (...args) =>
      dispatch(OngoingRidesActions.updateDropoffRequest(...args)),
  };
};

const reservationsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnGoingRides);
export default withTranslation(["Private", "Common"], { wait: true })(
  reservationsRedux
);
