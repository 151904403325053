import { LoginPage, ForgotPassword } from "views/public";
import {
  NotFoundPage,
  Partners,
  Planning,
  OnGoingRides,
  HistoryRides,
  TransporterVehicles,
  // OnGoingBilans,
  SettingsPage,
  Dashboard,
} from "views/private";
import { ReactComponent as partnerIcon } from "assets/img/menu/partner.svg";
import { ReactComponent as ongoingIcon } from "assets/img/menu/ongoing.svg";
import { ReactComponent as historyIcon } from "assets/img/menu/history.svg";
import { ReactComponent as vehicleIcon } from "assets/img/menu/vehicle.svg";
import { ReactComponent as dashboardIcon } from "assets/img/menu/dashboard.svg";

const routes = [
  // PRIVATE
  // {
  //   path: "/offer",
  //   layout: "/private",
  //   name: "Demandes",
  //   icon: incomingIcon,
  //   component: InComingRides,
  // },
  {
    path: "/dashboard",
    layout: "/private",
    name: "Dashboard",
    icon: dashboardIcon,
    component: Dashboard,
    hidden: process.env.REACT_APP_STAGE === "prod",
  },
  {
    hidden: true,
    path: "/",
    layout: "/private",
    name: "Réservation",
    component: OnGoingRides,
  },
  {
    path: "/reservation",
    layout: "/private",
    name: "Réservation",
    icon: ongoingIcon,
    component: OnGoingRides,
  },
  // {
  //   path: "/intervention",
  //   layout: "/private",
  //   name: "Interventions",
  //   icon: ongoingIcon,
  //   component: OnGoingBilans,
  // },
  {
    path: "/history",
    layout: "/private",
    name: "Historique",
    icon: historyIcon,
    component: HistoryRides,
  },
  {
    path: "/vehicle",
    layout: "/private",
    name: "Véhicule",
    icon: vehicleIcon,
    component: TransporterVehicles,
  },
  {
    path: "/planning",
    layout: "/private",
    name: "Planning",
    // icon: partnerIcon,
    component: Planning,
    hidden: true,
  },
  {
    path: "/partner",
    layout: "/private",
    name: "Partenaires",
    component: Partners,
    icon: partnerIcon,
    // hidden: true,
  },
  {
    path: "/settings",
    layout: "/private",
    name: "Settings list",
    component: SettingsPage,
    hidden: true,
  },
  {
    hidden: true,
    path: "*",
    layout: "/private",
    name: "not found 404",
    component: NotFoundPage,
  },
  // PUBLIC
  {
    path: "/login",
    layout: "/public",
    name: "Page login",
    component: LoginPage,
  },
  {
    path: "/forgot",
    layout: "/public",
    name: "Forgot password",
    component: ForgotPassword,
  },
  {
    layout: "/public",
    path: `*`,
    component: LoginPage,
  },
];

// Add dashboard route only in dev/demo mode
// if (process.env.REACT_APP_STAGE !== "prod") {
//   routes.unshift();
// }

export default routes;
