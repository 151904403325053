import { Component } from "react";
import { Box, Center, FlatList, Image, VStack } from "native-base";
import routes from "routes";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import LogoutConfirmationDialog from "./LogoutConfirmation";
import logo from "assets/img/resaam-logo.svg";
import avatar from "assets/img/avatar.svg";
import "assets/style.css";
// import { version } from "../../../package.json";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogoutConfirmationDialog: false,
      isHover: false,
    };

    this.logout = this.logout.bind(this);
    this.onConfirmLogout = this.onConfirmLogout.bind(this);
    this.onCancelLogout = this.onCancelLogout.bind(this);

    this.setIsHover = this.setIsHover.bind(this);
    this.getNumberOfNotificationForPath =
      this.getNumberOfNotificationForPath.bind(this);
  }

  logout() {
    this.setState(
      Object.assign({}, this.state, { showLogoutConfirmationDialog: true })
    );
  }

  onConfirmLogout() {
    const { signoutRequest } = this.props;

    signoutRequest(() => {
      this.setState(
        Object.assign({}, this.state, { showLogoutConfirmationDialog: false })
      );
    });
  }

  onCancelLogout() {
    this.setState(
      Object.assign({}, this.state, { showLogoutConfirmationDialog: false })
    );
  }

  setIsHover(isHoverProp = false) {
    // this.setState(
    //   // Object.assign({}, this.state, { isHover: isHoverProp })
    // );
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return "";
    // return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  getNumberOfNotificationForPath(path) {
    const notificationsLength = this.props.notifications.filter(
      (notif) => notif.page === path && !notif.readed
    ).length;
    if (notificationsLength === 0) return null;
    return (
      <Center
        size="18px"
        position="absolute"
        bg="danger"
        top="0px"
        right="15px"
        rounded="full"
        _text={{
          color: "ligth",
          fontSize: "14px",
          fontFamily: "Lato",
          fontWeight: "700",
        }}
      >
        {notificationsLength}
      </Center>
    );
  }

  createLinks() {
    return (
      <FlatList
        data={routes}
        renderItem={({ item }) => {
          const Icon = item.icon || null;
          if (item.layout !== "/private" || item.hidden) return null;

          return (
            <Center
              w="full"
              className={`${this.activeRoute(item.path)}`}
              mb="10"
              py="2"
              position="relative"
            >
              <NavLink
                to={item.path}
                bg="#265980"
                display="flex"
                // justifyContent="center"
                onClick={() => this.props.readedNotificationInPage(item.path)}
                className={({ isActive }) =>
                  isActive ? "menu-item-active" : ""
                }
                // activeClassName="active-link"
                // className={isActive => !isActive ? "nav-link-selected" : ""}
              >
                {item.icon ? <Icon /> : null}
              </NavLink>
              {this.getNumberOfNotificationForPath(item.path)}
            </Center>
          );
        }}
        keyExtractor={(item, key) => key}
      />
    );
  }

  render() {
    const { t, i18n, width } = this.props;
    const { isHover } = this.state;

    return (
      <Box
        w={width}
        borderColor="lightgray"
        borderWidth="2"
        borderRightRadius="20px"
        justifyContent="space-between"
        px="2"
        h="100vh"
        onMouseEnter={() => this.setIsHover(true)}
        onMouseLeave={() => this.setIsHover(false)}
      >
        <VStack alignItems="center">
          <Center mt="2">
            <img
              src={logo}
              width={isHover ? "182px" : "99px"}
              alt="pictogram"
            />
          </Center>
          <Box
            display="flex"
            flexDirection={isHover ? "row" : "column"}
            justifyContent="center"
            mb="12"
          >
            <Image source={avatar} alt="avatar user" size={"md"} mt="4" />
          </Box>

          <VStack space="4" w="full">
            {this.createLinks()}
          </VStack>
        </VStack>

        <Box>
          {/* <p>{version}</p> */}
          <p>1.0.0</p>
        </Box>

        <LogoutConfirmationDialog
          className="side-bar-dialog"
          {...{ t, i18n }}
          isOpen={this.state.showLogoutConfirmationDialog}
          onConfirm={this.onConfirmLogout}
          onClose={this.onCancelLogout}
        />
      </Box>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(SideBar);
