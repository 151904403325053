import React, { useEffect, useState } from "react";
import {
  HStack,
  Pressable,
  Stack,
  VStack,
  Divider,
  ScrollView,
} from "native-base";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import ExportButton from "./ExportButton";
import { Text, Button } from "components";

export default function PlanMonth({ companies, selectedMonth }) {
  const { t } = useTranslation("Private");
  const [planifiedCompanies /* , setPlanifiedCompanies */] = useState([]);
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    return () => getInitialCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  // get previous plannification and add companies not in it
  // or create new planification with all companies
  function getInitialCompanies() {
    const other = companies
      .filter(
        (company) =>
          !planifiedCompanies.map((item) => item.id).includes(company.id)
      )
      .map((item) => ({
        ...item,
        day: false,
        night: false,
      }));
    setPlan([...planifiedCompanies, ...other]);
  }

  // toggle day/night/not
  const handleSelect = (company, type) => {
    const newPlan = plan.map((item) => {
      if (item.id === company.id) {
        return {
          ...item,
          day: type === "day" ? (item.day ? false : true) : false,
          night: type === "night" ? (item.night ? false : true) : false,
        };
      }
      return item;
    });
    setPlan(newPlan);
  };

  // update planning with only night or day selected
  const onSubmit = () => {
    const nightAndDay = plan.filter((item) => item.day || item.night);
    console.log("nightAndDay", nightAndDay);
  };

  const onExportDocument = (format) => {
    console.log("export planning with format", format);
  };

  return (
    <VStack flex={1} py={2} space={2} rounded={4} bg="muted.100">
      <HStack
        HStack
        alignItems="center"
        justifyContent="space-between"
        pr={4}
        pt={1}
      >
        <Stack w="18px" h="16px" />

        <Text fontSize="lg" fontWeight="bold">
          {t("Planning.subtitlePlannification")}
          {/*  {selectedMonth ? moment(selectedMonth).format("MMMM YYYY") : "nouveau mois"} */}
        </Text>

        <ExportButton
          disabled={selectedMonth === null}
          onExport={onExportDocument}
        />
      </HStack>

      <ScrollView h="full" py={4} flex={1}>
        <VStack space={2} divider={<Divider />}>
          {plan.map((company, key) => (
            <HStack key={key} px={4} py={1} justifyContent="space-between">
              <Text>{_.capitalize(company.name)}</Text>
              <HStack space={5}>
                <Pressable
                  alignItems="center"
                  justifyContent="center"
                  onPress={() => handleSelect(company, "night")}
                >
                  <FontAwesomeIcon
                    icon={faMoon}
                    color={company.night ? "#5FAEE8" : "#D9D9D9"}
                  />
                </Pressable>

                <Pressable
                  alignItems="center"
                  justifyContent="center"
                  onPress={() => handleSelect(company, "day")}
                >
                  <FontAwesomeIcon
                    icon={faSun}
                    color={company.day ? "#5FAEE8" : "#D9D9D9"}
                  />
                </Pressable>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </ScrollView>
      <Stack alignItems="end" justifyContent="end" px={4}>
        <Button isDisabled={selectedMonth === null} onPress={onSubmit}>
          {t("Planning.buttonSavePlanning")}
        </Button>
      </Stack>
    </VStack>
  );
}
