import { Center, HStack, VStack, Box } from "native-base";
import { Component } from "react";
import { Text } from "./Text";

class PatientStatus extends Component {
  render() {
    const { t, i18n, status } = this.props;

    const admission = status === "ADMISSION";
    const ready = status !== "WAITING" && !admission;

    // const textPlacement =
    //   status === "WAITING" ? "start" : admission ? "center" : "end";
    const statusText = {
      pending: t("TransporterReservationsPage.status_patient_pending", {
        lng: i18n.language,
      }),
      onway: t("TransporterReservationsPage.status_patient_onway", {
        lng: i18n.language,
      }),
      ready: t("TransporterReservationsPage.status_patient_ready", {
        lng: i18n.language,
      }),
    };

    return (
      <VStack space="1" w="140px">
        <HStack alignItems="center">
          <Box bg="primary" w="10px" h="5px" roundedLeft="full" />

          <Center bg="primary" rounded="full" size="20px">
            <Center bg="ligth" rounded="full" size="8px" />
          </Center>

          <Box bg="primary" w="34px" h="5px" />

          <Center
            bg={admission ? "primary" : ready ? "primary" : "ligthGray"}
            rounded="full"
            size="20px"
          >
            <Center bg="ligth" rounded="full" size="8px" />
          </Center>

          <Box
            bg={admission ? "primary" : ready ? "primary" : "ligthGray"}
            w="34px"
            h="5px"
          />

          <Center
            bg={ready ? "primary" : "ligthGray"}
            rounded="full"
            size="20px"
          >
            <Center bg="ligth" rounded="full" size="8px" />
          </Center>

          <Box
            bg={ready ? "primary" : "ligthGray"}
            w="10px"
            h="5px"
            roundedRight="full"
          />
        </HStack>

        <HStack alignItems="center" w="full" justifyContent="space-between">
          <Text
            textAlign="center"
            color="primary"
            fontStyle="italic"
            fontSize="12px"
          >
            {status === "WAITING" && statusText.pending}
          </Text>
          <Text
            textAlign="center"
            color="primary"
            fontStyle="italic"
            fontSize="12px"
          >
            {admission && statusText.onway}
          </Text>
          <Text
            textAlign="center"
            color="primary"
            fontStyle="italic"
            fontSize="12px"
          >
            {ready && statusText.ready}
          </Text>
        </HStack>
      </VStack>
    );
  }
}

export default PatientStatus;
