import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  samuGetRideDriverLocationRequest: ["driverId"],
  samuGetRideDriverLocationRequestSuccess: ["coords"],
  samuGetRideDriverLocationRequestFailure: ["error"],

  samuGetRidesWaitingTimeRequest: ["ridesId"],
  samuGetRidesWaitingTimeRequestSuccess: ["waitingTime"],
  samuGetRidesWaitingTimeRequestFailure: ["error"],

  samuGetDriversLocationRequest: ["driversId"],
  samuGetDriversLocationSuccess: ["coords"],
  samuGetDriversLocationFailure: ["error"],
});

export const DriverLocationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  waitingTime: [],
  coords: {
    driverId: "",
    longitude: 0,
    latitude: 0,
  },
  driversCoords: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const samuGetRidesWaitingTimeRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const samuGetRidesWaitingTimeRequestSuccess = (state, action) => {
  const { waitingTime } = state.asMutable({ deep: true });
  const s = _.reduce(
    waitingTime,
    (acc, el) => {
      const oldExist = _.filter(
        action.waitingTime,
        (e) => el.rideId === e.rideId
      );
      return _.isEmpty(oldExist) ? [...acc, el] : acc;
    },
    []
  );

  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["waitingTime"],
    [...s, ...action.waitingTime]
  );
};

export const samuGetRidesWaitingTimeRequestFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const samuGetRideDriverLocationRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (_.isNull(action.coords.longitude) || _.isNull(action.coords.latitude))
    return nextSate;

  return Immutable.setIn(nextSate, ["coords"], action.coords);
};

export const samuGetRideDriverLocationRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const samuGetRideDriverLocationRequestFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

// Drivers Coords
export const samuGetDriversLocationSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (_.isEmpty(action.coords)) return nextSate;
  return Immutable.setIn(nextSate, ["driversCoords"], action.coords);
};

export const samuGetDriversLocationRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const samuGetDriversLocationFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAMU_GET_RIDE_DRIVER_LOCATION_REQUEST]:
    samuGetRideDriverLocationRequest,
  [Types.SAMU_GET_RIDE_DRIVER_LOCATION_REQUEST_SUCCESS]:
    samuGetRideDriverLocationRequestSuccess,
  [Types.SAMU_GET_RIDE_DRIVER_LOCATION_REQUEST_FAILURE]:
    samuGetRideDriverLocationRequestFailure,

  [Types.SAMU_GET_RIDES_WAITING_TIME_REQUEST]: samuGetRidesWaitingTimeRequest,
  [Types.SAMU_GET_RIDES_WAITING_TIME_REQUEST_SUCCESS]:
    samuGetRidesWaitingTimeRequestSuccess,
  [Types.SAMU_GET_RIDES_WAITING_TIME_REQUEST_FAILURE]:
    samuGetRidesWaitingTimeRequestFailure,

  [Types.SAMU_GET_DRIVERS_LOCATION_REQUEST]: samuGetDriversLocationRequest,
  [Types.SAMU_GET_DRIVERS_LOCATION_SUCCESS]: samuGetDriversLocationSuccess,
  [Types.SAMU_GET_DRIVERS_LOCATION_FAILURE]: samuGetDriversLocationFailure,
});
