import _ from "lodash";
import { /* call, put, fork,  */ takeLatest } from "redux-saga/effects";
import { /* MinioActions, */ MinioTypes } from "reducers/minio";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* getPresignedUrlAsync({ path, callback }) {
  const query = `
    query {
      GetPresignedUrls(pathNames: ["${path.join('", "')}"])
    }
  `;

  try {
    const { GetPresignedUrls } = yield graphQLClient().request(query);
    if (_.isFunction(callback)) callback(GetPresignedUrls);
  } catch (error) {
    if (_.isFunction(callback)) callback(null);
  }
}

function* putPresignedUrlAsync({ path, callback }) {
  const query = `
    query {
      PutPresignedUrls(pathNames: ["${path.join('", "')}"])
    }
  `;

  try {
    const { PutPresignedUrls } = yield graphQLClient().request(query);
    if (_.isFunction(callback)) callback(PutPresignedUrls);
  } catch (error) {
    if (_.isFunction(callback)) callback("");
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(MinioTypes.PUT_PRESIGNED_URL, putPresignedUrlAsync),
  takeLatest(MinioTypes.GET_PRESIGNED_URL, getPresignedUrlAsync),
];
