import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  realTimeRequest: [],
  realTimeSuccess: ["dataset"],
  realTimeFailure: ["error"],

  transporterAvailabilityRequest: ["companies"], // list of id
  transporterAvailabilitySuccess: ["dataset"],
  transporterAvailabilityFailure: ["error"],

  rideFrequencyPerCompanyRequest: ["from", "to"], // 2 dates
  rideFrequencyPerCompanySuccess: ["dataset"],
  rideFrequencyPerCompanyFailure: ["error"],

  rideFrequencyMacroRequest: ["from", "to", "macro"], // 2 dates and macro (HOUR, DAY, WEEK, MONTH...)
  rideFrequencyMacroSuccess: ["dataset"],
  rideFrequencyMacroFailure: ["error"],
});

export const DashboardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  realTime: {
    action: { loading: false, error: "" },
    dataset: null,
    /**
      {
        ride { total incoming ongoing completed }
        driver { total free busy }
      }
    */
  },
  transporterAvailability: {
    action: { loading: false, error: "" },
    dataset: {
      total: 1,
      samu: 0,
      other: 0,
      offline: 0,
    },
    // { total samu offline offline }
  },
  rideFrequencyPerCompany: {
    action: { loading: false, error: "" },
    dataset: [], // [total id name]
  },
  rideFrequencyMacro: {
    action: { loading: false, error: "" },
    dataset: [], // [key value]
  },
});

/* ------------- Reducers ------------- */

// REALTIME
export const realTimeRequest = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["realTime", "action", "loading"],
    true
  );
  return Immutable.setIn(nextSate, ["realTime", "action", "error"], "");
};

export const realTimeSuccess = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["realTime", "action", "loading"],
    false
  );
  return Immutable.setIn(nextSate, ["realTime", "dataset"], action.dataset);
};

export const realTimeFailure = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["realTime", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["realTime", "action", "error"],
    action.error
  );
};

// TRANSPORTER AVAILABILITY
export const transporterAvailabilityRequest = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["transporterAvailability", "action", "loading"],
    true
  );
  return Immutable.setIn(
    nextSate,
    ["transporterAvailability", "action", "error"],
    ""
  );
};

export const transporterAvailabilitySuccess = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["transporterAvailability", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["transporterAvailability", "dataset"],
    action.dataset
  );
};

export const transporterAvailabilityFailure = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["transporterAvailability", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["transporterAvailability", "action", "error"],
    action.error
  );
};

// RIDE FREQUENCY PER COMPANY
export const rideFrequencyPerCompanyRequest = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["rideFrequencyPerCompany", "action", "loading"],
    true
  );
  return Immutable.setIn(
    nextSate,
    ["rideFrequencyPerCompany", "action", "error"],
    ""
  );
};

export const rideFrequencyPerCompanySuccess = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["rideFrequencyPerCompany", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["rideFrequencyPerCompany", "dataset"],
    action.dataset
  );
};

export const rideFrequencyPerCompanyFailure = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["rideFrequencyPerCompany", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["rideFrequencyPerCompany", "action", "error"],
    action.error
  );
};

export const rideFrequencyMacroRequest = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["rideFrequencyMacro", "action", "loading"],
    true
  );
  return Immutable.setIn(
    nextSate,
    ["rideFrequencyMacro", "action", "error"],
    ""
  );
};

export const rideFrequencyMacroSuccess = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["rideFrequencyMacro", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["rideFrequencyMacro", "dataset"],
    action.dataset
  );
};

export const rideFrequencyMacroFailure = (state, action) => {
  let nextSate = Immutable.setIn(
    state,
    ["rideFrequencyMacro", "action", "loading"],
    false
  );
  return Immutable.setIn(
    nextSate,
    ["rideFrequencyMacro", "action", "error"],
    action.error
  );
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.REAL_TIME_REQUEST]: realTimeRequest,
  [Types.REAL_TIME_SUCCESS]: realTimeSuccess,
  [Types.REAL_TIME_FAILURE]: realTimeFailure,

  [Types.TRANSPORTER_AVAILABILITY_REQUEST]: transporterAvailabilityRequest,
  [Types.TRANSPORTER_AVAILABILITY_SUCCESS]: transporterAvailabilitySuccess,
  [Types.TRANSPORTER_AVAILABILITY_FAILURE]: transporterAvailabilityFailure,

  [Types.RIDE_FREQUENCY_PER_COMPANY_REQUEST]: rideFrequencyPerCompanyRequest,
  [Types.RIDE_FREQUENCY_PER_COMPANY_SUCCESS]: rideFrequencyPerCompanySuccess,
  [Types.RIDE_FREQUENCY_PER_COMPANY_FAILURE]: rideFrequencyPerCompanyFailure,

  [Types.RIDE_FREQUENCY_MACRO_REQUEST]: rideFrequencyMacroRequest,
  [Types.RIDE_FREQUENCY_MACRO_SUCCESS]: rideFrequencyMacroSuccess,
  [Types.RIDE_FREQUENCY_MACRO_FAILURE]: rideFrequencyMacroFailure,
});
