import { Center } from "native-base";
import { Text } from "./Text";

const Tag = ({ bg = "ligth", color = "primary", text = "tag" }) => {
  return (
    <Center
      bg={bg}
      borderRadius="5px"
      px="10px"
      py="5px"
      // mx="auto"
    >
      <Text color={color} fontSize="14px" fontFamily="Lato">
        {text}
      </Text>
    </Center>
  );
};

export default Tag;
