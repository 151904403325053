import { put, takeLatest } from "redux-saga/effects";
import AccountActions, { AccountTypes } from "reducers/account";
import ongoingRidesActions from "reducers/transporter/transporter-ongoing-rides";
import rideHistoryActions from "reducers/transporter/transporter-rides-history";
import vehilcesActions from "reducers/transporter/transporter-vehicle";
// import rideDriverActions from "reducers/ride/driver-location";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* resetAllState() {
  yield put(ongoingRidesActions.onGoingRidesResetState());
  yield put(rideHistoryActions.rideHistoryResetState());
  yield put(vehilcesActions.vehiclesResetState());
  // yield put(rideDriverActions.transporterRideDriverResetState());
}

function* signinAsyncRequest({ email, password }) {
  const query = `
    query { 
      SamuUserLogin( 
        email:"${email}",
        password:"${password}"
      )
    }
  `;
  try {
    yield resetAllState();
    const data = yield graphQLClient().request(query);
    const token = data.SamuUserLogin;
    yield put(AccountActions.signinRequestSuccess(token));
  } catch (error) {
    //   const { errors = [{ message: "" }] } = response;
    // yield put(AccountActions.signinRequestFailure(errors[0].message));
    yield put(AccountActions.signinRequestFailure("error"));
  }
}

function* signoutAsyncRequest({ callback }) {
  yield resetAllState();
  if (_.isFunction()) callback();
}

function* forgotPasswordAsyncRequest({ email, callback }) {
  const query = `
    mutation {
      SamuRequestResetPassword(email:"${email}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(AccountActions.forgotPasswordSuccess(callback));
    callback();
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.forgotPasswordFailure(errors[0].message));
  }
}

function* resetPasswordAsyncRequest({ token, password, callback }) {
  const query = `
    mutation {
      SamuResetPassword(token:"${token}", password:"${password}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(AccountActions.resetPasswordSuccess(callback));
    callback();
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.resetPasswordFailure(errors[0].message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(AccountTypes.SIGNIN_REQUEST, signinAsyncRequest),
  takeLatest(AccountTypes.SIGNOUT_REQUEST, signoutAsyncRequest),
  takeLatest(AccountTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordAsyncRequest),
  takeLatest(AccountTypes.RESET_PASSWORD_REQUEST, resetPasswordAsyncRequest),
];

/* ------------- Connect Types To Sagas ------------- */
