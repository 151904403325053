import React from "react";
import { NavLink } from "react-router-dom";

function MenuLink({ to, label }) {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px 10px",
        borderRadius: "10px 10px 0 0",
        textDecoration: "none",
        color: isActive ? "white" : "rgb(95, 174, 232)",
        backgroundColor: isActive ? "rgb(95, 174, 232)" : "white",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: "700",
      })}
    >
      {label}
    </NavLink>
  );
}

export default MenuLink;
