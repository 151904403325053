import { Box, HStack } from "native-base";
import { Text } from "components/Text";

function LegendChart({ fill, label }) {
  return (
    <HStack space={1.5} alignItems="center">
      <Box rounded="full" w="14px" h="14px" bg={fill} />
      <Text fontSize="12px" fontWeight="400" color="#1C1C1C">
        {label}
      </Text>
    </HStack>
  );
}

export default LegendChart;
