import { Box, Center, HStack, Menu, Pressable } from "native-base";
import { Component } from "react";
import notificationIcon from "assets/img/notification.svg";
import { Text } from "components";

class NotificationMenu extends Component {
  constructor(props) {
    super(props);

    this.getNotificationsMessages = this.getNotificationsMessages.bind(this);
  }

  getNotificationsMessages() {
    const { items } = this.props;

    if (items.length === 0)
      return (
        <Menu.Item>
          <Text>Vide</Text>
        </Menu.Item>
      );

    return items.map((item, key) => (
      <Menu.Item key={key}>
        <HStack space="2" w="full" alignItems="center">
          <Box>
            {!item.readed && <Box size="2" rounded="full" bg="primary" />}
          </Box>
          <Text h="full" alignItems="center">
            {item.message}
          </Text>
        </HStack>
      </Menu.Item>
    ));
  }

  render() {
    return (
      <Menu
        onClose={this.props.onReadedNotifications}
        w="190"
        placement="left top"
        trigger={(triggerProps) => {
          return (
            <Pressable accessibilityLabel="show notification" {...triggerProps}>
              <Center rounded="full" size="10" bg="primary">
                <img src={notificationIcon} alt="notification" />
              </Center>
            </Pressable>
          );
        }}
      >
        {/* TEXT BRUT */}
        <Menu.Group title="Notifications">
          {this.getNotificationsMessages()}
        </Menu.Group>
      </Menu>
    );
  }
}

export default NotificationMenu;
