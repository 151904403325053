import Bar from "./Bar";
import Line from "./Line";
import TransporterAvailability from "./TransporterAvailability";
import RealTime from "./RealTime";
import MotifBar from "./MotifBar";
import MissionTimePieChart from "./MissionTimePieChart";

const Dashboard = {
  Bar,
  TransporterAvailability,
  RealTime,
  Line,
  MotifBar,
  MissionTimePieChart,
};

export default Dashboard;
