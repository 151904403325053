import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const SAMU_RIDE_STATUS_SUBSCRIPTION = gql`
  subscription {
    SamuRideStatusSubscription {
      _id
      status
      rideNumber
      dropoff {
        address
      }
      bilan
      transporter {
        company {
          name
        }
        vehicle {
          plate
          category
          make
          color
          model
          phone
        }
        driver
      }
    }
  }
`;

const OnGoingRideStatusNotification = ({
  onGoingRideStatusChanged,
  onGoingRidesRemoveRide,
  samuGetRidesWaitingTimeRequest,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      onGoingRideStatusChanged(ride);
      if (ride.status === "canceled" || ride.status === "completed") {
        onGoingRidesRemoveRide(ride);
      } else {
        showNotification({
          page: "/reservation",
          message: `Status du transport ${ride.rideNumber} a changé`,
          type: "info",
          readed: false,
        });
      }
      if (ride.status === "onway") {
        samuGetRidesWaitingTimeRequest([ride._id]);
      }
    }
  }

  return (
    <Subscription
      subscription={SAMU_RIDE_STATUS_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(subscriptionData.data.SamuRideStatusSubscription);
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default OnGoingRideStatusNotification;
