import { Component } from "react";
import { HStack, VStack, Pressable, Box } from "native-base";
import { Text } from "../Text";
import Tag from "../Tag";
import moment from "moment";
import { getDangerMessage } from "functions/bilan";
import { getBackendUrl } from "functions";
import DownloadShowButton from "components/DownloadShowButton";

function formatedDateRecap(d) {
  if (!d || !moment(d).isValid()) return "No";
  return moment(d).format("HH:mm");
}

class HistoryRidesCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMoreInfo: false,
    };

    this.onRideShowMoreInfoToggle = this.onRideShowMoreInfoToggle.bind(this);
    this.downloadBilanOrShow = this.downloadBilanOrShow.bind(this);
    this.downloadDischargeOrShow = this.downloadDischargeOrShow.bind(this);
    this.downloadOtherFiles = this.downloadOtherFiles.bind(this);
  }

  onRideShowMoreInfoToggle() {
    this.setState(
      Object.assign({}, this.state, {
        isShowMoreInfo: !this.state.isShowMoreInfo,
      })
    );
  }

  downloadOtherFiles(filesAvailable) {
    const { patient } = this.props.ride;
    this.props.downloadFiles(filesAvailable, patient);
  }

  downloadDischargeOrShow(show = false) {
    const { token, ride } = this.props;
    if (!ride.canceled || !ride.canceled?.discharge?.id) return;
    const urlDischarge = `${getBackendUrl()}/downloads/discharge/${
      ride.canceled?.discharge.id
    }/${show ? "show" : ""}?token=${token}`;
    window.open(urlDischarge, "_blank");
  }

  downloadBilanOrShow(show = false) {
    const { token, ride } = this.props;
    const urlBilan = `${getBackendUrl()}/downloads/bilan/${ride._id}/${
      show ? "show" : ""
    }?token=${token}`;
    window.open(urlBilan, "_blank");
  }

  render() {
    const { t, i18n } = this.props;
    const {
      rideNumber,
      patient,
      status,
      dropoff,
      pickup,
      transporter,
      datetime,
      intervention,
      observation,
      subject,
      summary,
      bilan = null,
      canceled = null,
    } = this.props.ride;
    const { isShowMoreInfo } = this.state;
    const statusColor = `status.${status}`;
    const statusText = t(`rideStatus.${status}`, { lng: i18n.language });
    const messages = bilan ? getDangerMessage(bilan) : null;
    const canHaveBilan = !["incoming", "pending", "onway"].includes(status);
    const haveDischarge =
      canceled && canceled?.discharge && canceled.discharge?.path;
    const filesAvailable = bilan?.documents
      ? Object.values(bilan.documents).filter((e) => e !== null)
      : [];

    function NotSpecified() {
      return (
        <Text fontSize="12px" italic>
          {t("TransporterReservationsPage.not_specified", {
            lng: i18n.language,
          })}
        </Text>
      );
    }

    return (
      <Pressable
        onPress={this.onRideShowMoreInfoToggle}
        mb={this.props.mb || 0}
        position="relative"
        borderWidth={1}
        boxSize="border-box"
        borderColor="gray"
        rounded="4px"
        _hover={{ borderColor: "rgba(2, 150, 174, 0.6)" }}
        _pressed={{ borderColor: "#0296AE" }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxWidth="986px"
        py="2"
        px="30px"
        bg="ligth"
      >
        <HStack w="full">
          <VStack space={4} w="295px" pr={2}>
            <HStack space="40px">
              <VStack space={5} h="110px">
                <Text fontSize="16px" fontWeight={900}>
                  {rideNumber}
                </Text>
                <Text fontSize="12px" fontWeight={500}>
                  {moment(datetime).format("DD MMM HH:mm")}
                </Text>
                {/* <HStack justifyContent="space-between" w="full">
                  <img src={clockIcon} style={{ width: 25 }} />
                  <Text fontSize="16px" fontWeight={900}>--:--</Text>
                </HStack> */}
              </VStack>

              <VStack space={2} flex={1}>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Prise en charge
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {pickup.address}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Dépose
                  </Text>

                  {dropoff.address ? (
                    <Text
                      nbOfLines={2}
                      fontSize="12px"
                      fontWeight="500"
                      numberOfLines={2}
                    >
                      {dropoff.address}
                    </Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              </VStack>
            </HStack>

            {isShowMoreInfo && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor="gray">
                <Text fontSize="12px" fontWeight="600" color="gray">
                  Informations complémentaires du lieu d'intervention
                </Text>

                <HStack space={4} w="full">
                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Batiment
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.batiment}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Digit Code
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.digicode}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Étage
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.etage}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </VStack>

          <HStack w="264px">
            <VStack
              space={1}
              pl={4}
              borderLeftWidth={1}
              borderColor="gray"
              flex={1}
            >
              <Text
                fontSize="16px"
                fontWeight={900}
                isTruncated
              >{`${patient?.firstname} ${patient?.lastname}`}</Text>
              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Age
                </Text>
                {patient?.age ? (
                  <Text fontSize="12px" fontWeight={900}>
                    {patient.age}
                  </Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>

              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Motif
                </Text>
                {subject ? (
                  <Text fontSize="12px" fontWeight={900}>
                    {subject}
                  </Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>
              {isShowMoreInfo && (
                <VStack space={1}>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° téléphone
                    </Text>
                    {patient?.phone ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient.phone}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° sécurité sociale
                    </Text>
                    {patient?.nss ||
                    bilan?.administrative?.socialSecurity?.nss ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient?.nss ||
                          bilan?.administrative?.socialSecurity?.nss}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                </VStack>
              )}
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" mb="2px" color="gray">
                Alertes
              </Text>
              {messages &&
                messages.map((e, index) => {
                  if (!isShowMoreInfo && index > 3) return null;
                  if (!isShowMoreInfo && index === 3)
                    return (
                      <Text
                        fontSize="12px"
                        fontWeight="900"
                        color="gray"
                        key={e}
                      >
                        ...
                      </Text>
                    );
                  return (
                    <Text fontSize="12px" fontWeight="900" color="gray" key={e}>
                      {e}
                    </Text>
                  );
                })}
            </VStack>
          </HStack>

          <VStack
            space={1}
            w="365px"
            pl={4}
            borderLeftWidth={1}
            borderColor="gray"
          >
            <HStack space="44px">
              <VStack space={1} w="132px">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Véhicule
                </Text>
                {status === "incoming" && <NotSpecified />}
                <Text fontSize="16px" fontWeight="900">
                  {transporter?.company?.name || ""}
                </Text>
                <Text fontSize="12px" fontWeight="700">
                  {transporter?.vehicle?.plate.toUpperCase() || ""}
                </Text>
                <Text fontSize="12px" fontWeight="500">
                  {transporter?.vehicle?.phone || ""}
                </Text>
              </VStack>

              <Box flex={1}>
                <VStack space={2} alignItems="flex-start">
                  <Tag text={statusText} color="ligth" bg={statusColor} />
                  {canHaveBilan && (
                    <DownloadShowButton
                      label="Fiche bilan"
                      onShow={() => this.downloadBilanOrShow(true)}
                      onDownload={() => this.downloadBilanOrShow(false)}
                    />
                  )}
                  {haveDischarge && (
                    <DownloadShowButton
                      label="Décharge"
                      onShow={() => this.downloadDischargeOrShow(true)}
                      onDownload={() => this.downloadDischargeOrShow(false)}
                    />
                  )}
                  {filesAvailable.length > 0 && (
                    <DownloadShowButton
                      label="Autres documents"
                      hideShow={true}
                      onDownload={this.downloadOtherFiles}
                    />
                  )}
                </VStack>
              </Box>
            </HStack>

            {isShowMoreInfo && (
              <VStack>
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Observation
                </Text>
                {observation ? (
                  <Text numberOfLines={3}>{observation}</Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>
            )}
            {isShowMoreInfo && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor="gray">
                <Text fontSize="12px" fontWeight="600" color="gray">
                  Récapitulatif de l'intervention
                </Text>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Heure de départ vers l'intervention
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    {formatedDateRecap(summary?.onway)}
                  </Text>
                </VStack>
                <HStack space={4}>
                  <VStack>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Heure de prise en charge
                    </Text>
                    <Text fontSize="12px" fontWeight="500">
                      {formatedDateRecap(summary?.onboard)}
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Heure de dépose
                    </Text>
                    <Text fontSize="12px" fontWeight="500">
                      {formatedDateRecap(summary?.arrived)}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </VStack>
        </HStack>
      </Pressable>
    );
  }
}

export default HistoryRidesCard;
