import React, { useEffect, useState } from "react";
import { Select, VStack } from "native-base";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";

import { Text } from "components";
import { MACRO_DASHBOARD_PERIODS } from "global";
import { useTranslation } from "react-i18next";

export default function Bar({ title, action, dataset, fetchDataset, filter }) {
  const { t } = useTranslation("Private");
  const [macro, setMacro] = useState(MACRO_DASHBOARD_PERIODS.DAY);

  useEffect(() => {
    fetchDataset(filter.from, filter.to, macro);
  }, [filter, macro, fetchDataset]);

  function formatTick(tick) {
    if (
      macro === MACRO_DASHBOARD_PERIODS.DAY ||
      macro === MACRO_DASHBOARD_PERIODS.MONTH
    )
      return t(`Dashboard.convertValueMacro.${macro}.${tick}`);
    return tick;
  }

  return (
    <VStack
      space={2}
      alignItems="center"
      rounded="12px"
      bg="dashboardCard"
      px="20px"
      py="9px"
      w="full"
    >
      <Text
        fontSize="16px"
        fontWeight="700"
        textAlign="center"
        noOfLines={2}
        w="181px"
      >
        {title}
      </Text>
      <VStack alignItems="flex-end" w="full">
        {/* <Pressable onPress={toggleDisplay}>
          <Text color={showRatio ? "primary" : "balck"}>Ratio %</Text>
        </Pressable> */}
        <Select
          selectedValue={macro}
          // minWidth={200}
          accessibilityLabel="Selectionner une macro"
          placeholder="Selectionner une macro"
          onValueChange={(itemValue) => setMacro(itemValue)}
          borderWidth={0}
        >
          {Object.values(MACRO_DASHBOARD_PERIODS).map((m, index) => (
            <Select.Item
              key={index}
              label={t(`Dashboard.rideFrequencyMacro.${m}`)}
              value={m}
            />
          ))}
        </Select>
      </VStack>
      {/* {action.loading || dataset.length === 0 ? (
        <Spinner accessibilityLabel="Loading posts" color="#F5BD4F" size="lg" />
      ) : (
        // <ResponsiveContainer height="500px" width="100%"> */}
      <LineChart
        data={dataset}
        width={700}
        height={276}
        margin={{ top: 5, right: 5, left: 5, bottom: 0 }}
      >
        <XAxis
          dataKey="name"
          style={{ fontFamily: "Lato" }}
          tickFormatter={formatTick}
        />
        <YAxis
          allowDecimals={false}
          label={{
            value: "Missions",
            angle: -90,
            // position"top" | "left" | "right" | "bottom" | "inside" | "outside" | "insideLeft" | "insideRight" | "insideTop" | "insideBottom" | "insideTopLeft" | "insideBottomLeft" | "insideTopRight" | "insideBottomRight" | "insideStart" | "insideEnd" | "end" | "center"optional
            position: "insideLeft",
            fontFamily: "Lato",
          }}
          style={{ fontFamily: "Lato" }}
        />
        <Tooltip
          labelFormatter={(tick) => {
            if (
              macro === MACRO_DASHBOARD_PERIODS.DAY ||
              macro === MACRO_DASHBOARD_PERIODS.MONTH
            )
              return t(`Dashboard.convertValueMacro.${macro}.${tick}`);
            if (macro === MACRO_DASHBOARD_PERIODS.HOUR) return tick + "h";
            return tick;
          }}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="quantity"
          stroke="#82ca9d"
          label={t(`Dashboard.rideFrequencyMacro.${macro}`)}
          name={t(`Dashboard.rideFrequencyMacro.${macro}`)}
          strokeWidth={3}
        />
      </LineChart>
      {/* // </ResponsiveContainer> */}
      {/* )} */}
    </VStack>
  );
}
