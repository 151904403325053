import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  vehiclesResetState: null,

  vehiclesRequest: ["page", "limit", "filter"],
  vehiclesRequestSuccess: ["vehicles"],
  vehiclesRequestFailure: ["error"],
});

export const VehicleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  vehicles: {
    page: 1,
    limit: 10,
    vehicles: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const vehiclesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const vehiclesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const vehiclesRequestSuccess = (state, action) => {
  const nextSate = Immutable.setIn(state, ["action", "loading"], false);

  if (action.vehicles.page === 1) {
    //first page
    return Immutable.setIn(nextSate, ["vehicles"], action.vehicles);
  }

  const prevList = state.vehicles.vehicles.asMutable({ deep: true }); //
  const { vehicles, ...rest } = action.vehicles; //

  return Immutable.setIn(nextSate, ["vehicles"], {
    ...rest,
    vehicles: [...prevList, ...vehicles],
  });
};

export const vehiclesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.VEHICLES_RESET_STATE]: vehiclesResetState,

  [Types.VEHICLES_REQUEST]: vehiclesRequest,
  [Types.VEHICLES_REQUEST_SUCCESS]: vehiclesRequestSuccess,
  [Types.VEHICLES_REQUEST_FAILURE]: vehiclesRequestFailure,
});
