import { Component } from "react";
import {
  HStack,
  Center,
  Box,
  Menu,
  Pressable,
  Toast,
  VStack,
} from "native-base";
import _ from "lodash";
import Card from "./Card";
import { Text } from "../Text";
import threeDotIcon from "assets/img/three_dots.svg";
import NotificationCustom from "components/NotificationCustom";
import Button from "../Button";

class PartnerCard extends Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.active = this.active.bind(this);
    this.findSector = this.findSector.bind(this);
    this.toggleSector = this.toggleSector.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);

    this.state = {
      showMore: false,
      showUpdatePartner: false,
      sectors: this.props.partner.sectors,
    };
  }

  delete() {
    const {
      deletePartnerRequest,
      partner: { partnerId, name },
    } = this.props;
    deletePartnerRequest(partnerId, () => null);
    Toast.show(
      NotificationCustom({
        message: `${name} a été retiré de vos partenaires.`,
      })
    );
  }

  active() {
    const { activePartnerRequest, partner } = this.props;
    const { partnerId } = partner;
    activePartnerRequest([{ partnerId, sectors: [] }], () => null);
    Toast.show(
      NotificationCustom({
        message: `${partner.name} a été réactiver dans vos partenaires.`,
      })
    );
  }

  toggleSector(sector) {
    // do nothing if update partner modal is not open
    if (!this.state.showUpdatePartner) return;

    let newSectors = [...this.state.sectors];
    const existingSector = newSectors.find(
      (s) => s.sectorId === sector.sectorId
    );
    if (existingSector) {
      newSectors = newSectors.filter((s) => s.sectorId !== sector.sectorId);
    } else {
      newSectors.push(sector);
    }
    this.setState({ sectors: newSectors });
  }

  findSector(sectorId) {
    const { sectors } = this.state;
    return sectors.find((s) => s.sectorId === sectorId);
  }

  onConfirmUpdate() {
    const callback = (error = null) => {
      Toast.show(
        NotificationCustom({
          message: error ? "Une erreur est survenue" : "Partenaire mis à jour",
        })
      );
      if (error) return;
      this.setState({ showUpdatePartner: false });
    };

    this.props.updatePartner(
      {
        partnerId: this.props.partner.partnerId,
        sectors: this.state.sectors,
      },
      callback
    );
  }

  render() {
    const { t, i18n, partner = {}, sectors = [] } = this.props;
    const { name, address, phones, deleted } = partner;
    const { showMore, showUpdatePartner } = this.state;
    const phonesNumber = Object.values(phones);
    const statusColor = deleted ? "gray" : "dark";

    return (
      <Pressable onPress={() => this.setState({ showMore: !showMore })}>
        <Card
          minHeight="75px"
          pb={0}
          pt={2}
          pl="29px"
          mb="8px"
          borderColor="gray"
          borderWidth="1px"
          maxWidth="986px"
        >
          <Box alignItems="end" w="full">
            <Menu
              placement="bottom"
              trigger={(triggerProps) => (
                <Pressable
                  {...triggerProps}
                  accessibilityLabel="More option menu"
                >
                  <img src={threeDotIcon} alt="more option" />
                </Pressable>
              )}
            >
              <Menu.Item
                className="logout"
                onPress={() =>
                  this.setState({ showUpdatePartner: true, showMore: true })
                }
              >
                {t("partnersPage.button_update_partner", {
                  lng: i18n.language,
                })}
              </Menu.Item>
              {deleted ? (
                <Menu.Item className="logout" onPress={this.active}>
                  {t("partnersPage.button_active_partner", {
                    lng: i18n.language,
                  })}
                </Menu.Item>
              ) : (
                <Menu.Item className="logout" onPress={this.delete}>
                  {t("partnersPage.button_delete_partner", {
                    lng: i18n.language,
                  })}
                </Menu.Item>
              )}
            </Menu>
          </Box>
          <HStack
            space="2"
            justifyContent="space-between"
            pt={3}
            // alignItems="center"
          >
            <Center flex={1}>
              <Text
                fontSize="16px"
                fontWeight="900"
                color={statusColor}
                textAlign="center"
              >
                {_.toUpper(name)}
              </Text>
            </Center>

            <Center flex={2}>
              <Text fontSize="16px" fontWeight="700" color={statusColor}>
                {address.address}
              </Text>
            </Center>

            <Center flex={1}>
              {phonesNumber.map((phone, key) => (
                <Text
                  fontSize="12px"
                  fontWeight="700"
                  color={statusColor}
                  key={key}
                >
                  {phone}
                </Text>
              ))}
            </Center>
          </HStack>
          {showMore && (
            <HStack py={2}>
              <VStack space={1} flex={1}>
                <Text fontSize="12px" fontWeight="700" color={statusColor}>
                  {t("partnersPage.sectors", {
                    lng: i18n.language,
                  })}
                </Text>
                <HStack space={2} alignItems="center" flex={1}>
                  {sectors.map((sector, key) => (
                    <Pressable
                      key={key}
                      onPress={() => this.toggleSector(sector)}
                    >
                      <Box
                        rounded={4}
                        borderWidth="1px"
                        borderColor="gray"
                        px="10px"
                        py="5px"
                        bg={
                          this.findSector(sector.sectorId) ? "primary" : "white"
                        }
                      >
                        <Text
                          fontSize="12px"
                          color={
                            this.findSector(sector.sectorId) ? "white" : "gray"
                          }
                        >
                          {sector.sectorName}
                        </Text>
                      </Box>
                    </Pressable>
                  ))}
                </HStack>
              </VStack>
              {showUpdatePartner && (
                <HStack space={2} alignItems="center" justifyContent="end">
                  <Button
                    onPress={() =>
                      this.setState({
                        showUpdatePartner: false,
                        sectors: partner.sectors,
                      })
                    }
                    third
                    colorScheme="primary"
                    size="sm"
                  >
                    {t("button.cancel", {
                      lng: i18n.language,
                    })}
                  </Button>
                  <Button
                    onPress={this.onConfirmUpdate}
                    variant="solid"
                    colorScheme="primary"
                    size="sm"
                  >
                    {t("button.valid", {
                      lng: i18n.language,
                    })}
                  </Button>
                </HStack>
              )}
            </HStack>
          )}
        </Card>
      </Pressable>
    );
  }
}

export default PartnerCard;
