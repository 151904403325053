import { put, takeLatest } from "redux-saga/effects";
import DashboardActions, { DashboardTypes } from "reducers/dashboard";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* realTimeAsyncRequest() {
  const query = `{
    SamuDataVisualisationRealTime {
      ride {
        total
        incoming
        ongoing
        completed
      }
      driver {
        total
        free
        busy
      }
    }
  }`;
  try {
    const { SamuDataVisualisationRealTime } = yield graphQLClient().request(
      query
    );
    yield put(DashboardActions.realTimeSuccess(SamuDataVisualisationRealTime));
  } catch (error) {
    // const { errors = [{ message: "" }] } = response;
    // yield put(AccountActions.signinRequestFailure(errors[0].message));
    yield put(DashboardActions.realTimeFailure(error));
  }
}

function* transporterAvailabilityAsyncRequest({ companies = [] }) {
  const query = `{
    SamuDataVisualisationTransporterAvailable(companies: [${companies
      .map((company) => `"${company}"`)
      .join(",")}]) {
      total
      samu
      other
      offline
    }
  }`;
  try {
    const { SamuDataVisualisationTransporterAvailable } =
      yield graphQLClient().request(query);
    yield put(
      DashboardActions.transporterAvailabilitySuccess(
        SamuDataVisualisationTransporterAvailable
      )
    );
  } catch (error) {
    // const { errors = [{ message: "" }] } = response;
    // yield put(AccountActions.signinRequestFailure(errors[0].message));
    yield put(DashboardActions.transporterAvailabilityFailure(error));
  }
}

function* rideFrequencyPerCompanyAsyncRequest({ from, to }) {
  const query = `{
    SamuDataVisualisationRideFrequencyPerCompany(from: "${from}", to: "${to}") {
      total
      ratio
      id
      vehicle
      name
    }
  }`;
  try {
    const { SamuDataVisualisationRideFrequencyPerCompany } =
      yield graphQLClient().request(query);
    yield put(
      DashboardActions.rideFrequencyPerCompanySuccess(
        SamuDataVisualisationRideFrequencyPerCompany
      )
    );
  } catch (error) {
    // const { errors = [{ message: "" }] } = response;
    // yield put(AccountActions.signinRequestFailure(errors[0].message));
    yield put(DashboardActions.rideFrequencyPerCompanyFailure(error));
  }
}

// SamuDataVisualisationRideFrequencyMacro
function* rideFrequencyMacroAsyncRequest({ from, to, macro }) {
  const query = `{
    SamuDataVisualisationRideFrequencyMacro(from: "${from}", to: "${to}", macro: ${macro})
  }`;
  try {
    const { SamuDataVisualisationRideFrequencyMacro } =
      yield graphQLClient().request(query);
    yield put(
      DashboardActions.rideFrequencyMacroSuccess(
        SamuDataVisualisationRideFrequencyMacro
      )
    );
  } catch (error) {
    // const { errors = [{ message: "" }] } = response;
    // yield put(AccountActions.signinRequestFailure(errors[0].message));
    yield put(DashboardActions.rideFrequencyMacroFailure(error));
  }
}
/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(DashboardTypes.REAL_TIME_REQUEST, realTimeAsyncRequest),
  takeLatest(
    DashboardTypes.TRANSPORTER_AVAILABILITY_REQUEST,
    transporterAvailabilityAsyncRequest
  ),
  takeLatest(
    DashboardTypes.RIDE_FREQUENCY_PER_COMPANY_REQUEST,
    rideFrequencyPerCompanyAsyncRequest
  ),
  takeLatest(
    DashboardTypes.RIDE_FREQUENCY_MACRO_REQUEST,
    rideFrequencyMacroAsyncRequest
  ),
];
