import { Component } from "react";
import { Alert, Box, Center, HStack, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import OngoingRidesActions from "reducers/transporter/transporter-ongoing-rides";
import DriverLocationActions from "reducers/ride/driver-location";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "global/local-dates";
import "assets/style.css";
import noDataIcon from "assets/img/no_data.svg";

import {
  Title,
  Text,
  OnGoingRidesCard,
  FloatingInput,
  NotificationMenu,
  DriverLocationMapModal,
} from "components";

class OnGoingBilans extends Component {
  constructor(props) {
    super(props);

    this.onSearchOngoingRidesFilter =
      this.onSearchOngoingRidesFilter.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.onShowMap = this.onShowMap.bind(this);

    this.state = {
      searchInputValue: "",
      filterDates: [],
      selectedRide: null,
      showMapOpen: false,
      selectedRideToShowDriverLocation: 0,
    };
  }

  componentDidMount() {
    const { onGoingRidesRequest } = this.props;
    onGoingRidesRequest(1, 10);

    this.driverLocationIntervalId = setInterval(() => {
      const { showMapOpen, selectedRideToShowDriverLocation } = this.state;

      if (!showMapOpen) return;

      this.getCurrentDriverLocationForSelectedRide(
        selectedRideToShowDriverLocation
      );
    }, 30 * 1000); // 30s

    this.waitingTimeIntervalId = setInterval(() => {
      this.estimateWaitingTime();
    }, 30 * 1000); // 30s
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterDates, searchInputValue } = this.state;
    if (
      prevState.filterDates !== filterDates ||
      prevState.searchInputValue !== searchInputValue
    ) {
      this.props.onGoingRidesRequest(1, 10, {
        text: searchInputValue,
        dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.driverLocationIntervalId);
    clearInterval(this.waitingTimeIntervalId);
  }

  estimateWaitingTime() {
    const { samuGetRidesWaitingTimeRequest, rides } = this.props;
    let ridesId = [];
    rides.rides.forEach((ride) => {
      if (ride.status === "onway" || ride.status === "onboard") {
        ridesId.push(ride._id);
      }
    });
    samuGetRidesWaitingTimeRequest(ridesId);
  }

  onSearchOngoingRidesFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
  }

  fetchData() {
    const { onGoingRidesRequest, rides } = this.props;
    const { page, limit, totalPages } = rides;
    if (page < totalPages) {
      onGoingRidesRequest(page + 1, limit, {
        text: this.state.searchInputValue,
        dates: this.state.filterDates,
      });
      this.estimateWaitingTime();
    }
  }

  onShowMap(ride) {
    this.setState({
      showMapOpen: true,
      selectedRideToShowDriverLocation: ride,
    });
    this.getCurrentDriverLocationForSelectedRide(ride);
  }

  getCurrentDriverLocationForSelectedRide(ride) {
    const { samuGetRideDriverLocationRequest } = this.props;
    //in future check if ride is transporter or taxi
    samuGetRideDriverLocationRequest(ride.transporter.driver);
  }

  render() {
    const { t, i18n, action, rides, driverCoords, waitingTime } = this.props;
    const { error, loading } = action;
    const { searchInputValue, selectedRideToShowDriverLocation } = this.state;
    let coords = {};

    if (
      selectedRideToShowDriverLocation !== 0 &&
      selectedRideToShowDriverLocation.transporter.driver ===
        driverCoords.driverId
    ) {
      coords = driverCoords;
    }

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space={5} h="48" mt="5" zIndex={1}>
            {error !== "" && (
              <Alert bsStyle="danger">
                <span>{error}</span>
              </Alert>
            )}

            <Title>
              {t("TransporterReservationsPage.reservation_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            {/* Search */}
            <HStack justifyContent="space-between">
              <FloatingInput
                inputType="search"
                label={t("searchBar", { lng: i18n.language })}
                onChange={this.onSearchOngoingRidesFilter}
                value={searchInputValue}
              />

              <Box justifyContent="center" position="relative">
                <Calendar
                  multiple={true}
                  value={this.state.filterDates}
                  weekStartDayIndex={1}
                  disableDayPicker={false}
                  showOtherDays={false}
                  calendarPosition="Right Top"
                  className="container-input-calendar"
                  render={<InputIcon />}
                  locale={datepickerLocal}
                  shadow={false}
                  onChange={(selectedDates) => {
                    this.setState({ filterDates: selectedDates });
                  }}
                />
              </Box>
            </HStack>
          </VStack>

          {/* No Data */}
          {rides.rides.length === 0 && (
            <Center flex={1}>
              <img src={noDataIcon} alt="no data" />
              <Text fontSize="18">
                {t("TransporterReservationsPage.message_when_no_data")}
              </Text>
            </Center>
          )}

          <div
            id="scrollableDiv"
            style={{
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={rides.rides.length} //This is important field to render the next data
              next={this.fetchData}
              hasMore={true}
              scrollableTarget="scrollableDiv"
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={loading}
                />
              }
            >
              {rides.rides.map((ride, key) => {
                return (
                  <OnGoingRidesCard
                    {...{ t, i18n }}
                    ride={ride}
                    key={key}
                    mb="10px"
                    onShowMap={this.onShowMap}
                    waitingTime={waitingTime.filter(
                      (el) => el.rideId === ride._id
                    )}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </VStack>

        <DriverLocationMapModal
          isOpen={this.state.showMapOpen}
          coordinates={[coords]}
          onClose={() => {
            this.setState(
              Object.assign({}, this.state, { showMapOpen: false })
            );
          }}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.onGoingRides.rides.asMutable({ deep: true }),
    action: state.onGoingRides.action.asMutable({ deep: true }),
    waitingTime: state.driverLocation.waitingTime.asMutable({ deep: true }),
    driverCoords: state.driverLocation.coords.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGoingRidesRequest: (...args) =>
      dispatch(OngoingRidesActions.onGoingRidesRequest(...args)),
    samuGetRideDriverLocationRequest: (...args) =>
      dispatch(DriverLocationActions.samuGetRideDriverLocationRequest(...args)),
    // samuGetRidesWaitingTimeRequest: (...args) =>
    //   dispatch(
    //     DriverLocationActions.samuGetRidesWaitingTimeRequest(...args)
    //   ),
  };
};

const reservationsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnGoingBilans);
export default withTranslation(["Private", "Common"], { wait: true })(
  reservationsRedux
);
