import React, { Component } from "react";
import { Stack, Box } from "native-base";
import { connect } from "react-redux";
import AccountActions from "reducers/account";
import { getQueryString } from "functions";
import RequestView from "./Request";
import SuccessView from "./Success";
import ResetView from "./Reset";
import resaamIcon from "assets/img/resaam-logo.svg";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuccessRequestOrReset: false,
      isRequest: false,
      tokenResetPassword: getQueryString("token"),
    };

    this.onPressForgotPasswordButton =
      this.onPressForgotPasswordButton.bind(this);
    this.onPressResetPasswordButton =
      this.onPressResetPasswordButton.bind(this);
  }

  onPressForgotPasswordButton(email) {
    this.props.forgotPasswordRequest(email, () => {
      this.setState({
        isSuccessRequestOrReset: true,
        isRequest: true,
      });
    });
  }

  onPressResetPasswordButton(password) {
    this.props.resetPasswordRequest(
      this.state.tokenResetPassword,
      password,
      () => {
        this.setState({
          isSuccessRequestOrReset: true,
        });
      }
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { isSuccessRequestOrReset, tokenResetPassword, isRequest } =
      this.state;

    return (
      <>
        <Box position="absolute" top={10} left={10}>
          <img
            className="logo resaam"
            width="180px"
            src={resaamIcon}
            alt="Resaam"
          />
        </Box>

        <Stack alignItems="center" justifyContent="center">
          {!isSuccessRequestOrReset && !tokenResetPassword && (
            <RequestView
              {...{ t, i18n }}
              onPressForgotPasswordButton={this.onPressForgotPasswordButton}
            />
          )}

          {!isSuccessRequestOrReset && tokenResetPassword && (
            <ResetView
              {...{ t, i18n }}
              onPressResetPasswordButton={this.onPressResetPasswordButton}
            />
          )}

          {isSuccessRequestOrReset && (
            <SuccessView {...{ t, i18n }} isRequest={isRequest} />
          )}
        </Stack>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordRequest: (...args) =>
      dispatch(AccountActions.forgotPasswordRequest(...args)),
    resetPasswordRequest: (...args) =>
      dispatch(AccountActions.resetPasswordRequest(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
