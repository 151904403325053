import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import SettingActions, { SettingTypes } from "reducers/setting";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* generateTokenExtensionAsyncRequest({ callback }) {
  const query = `{ SamuExtensionGenerateToken }`;
  try {
    const { SamuExtensionGenerateToken } = yield graphQLClient().request(query);
    yield put(SettingActions.generateTokenExtensionSuccess());
    if (_.isFunction(callback)) callback(SamuExtensionGenerateToken);
  } catch (error) {
    //   const { errors = [{ message: "" }] } = response;
    // yield put(AccountActions.signinRequestFailure(errors[0].message));
    yield put(SettingActions.generateTokenExtensionFailure("error"));
  }
}

function* profileAsyncRequest() {
  const query = `
    query { 
      SamuUserProfile
    }
  `;
  try {
    const { SamuUserProfile } = yield graphQLClient().request(query);
    yield put(SettingActions.profileSuccess(SamuUserProfile));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(SettingActions.profileFailure(errors[0].message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(
    SettingTypes.GENERATE_TOKEN_EXTENSION_REQUEST,
    generateTokenExtensionAsyncRequest
  ),
  takeLatest(SettingTypes.PROFILE_REQUEST, profileAsyncRequest),
];
