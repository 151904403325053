import React, { useEffect, useState } from "react";
import { HStack, Pressable, Spinner, VStack } from "native-base";
import { Text } from "components";
import {
  BarChart,
  Bar as BarCh,
  Cell,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
  // ResponsiveContainer,
  LabelList,
} from "recharts";

const colors = {
  good: "#002CEB",
  medium: "#898FFF",
  bad: "#F5BD4F",
  veryBad: "#FF7A00",
};

function formatTextInVerticalBarChart(text = "", MAX_LENGTH = 22) {
  const truncated =
    text.length > MAX_LENGTH ? text.substring(0, MAX_LENGTH) + "..." : text;
  // remove spaces and replace them with non-breaking spaces
  return "\xa0\xa0" + truncated.split(" ").join("\xa0");
}

export default function Bar({ title, action, dataset, fetchDataset, filter }) {
  const [showRatio, setShowRatio] = useState(false);

  useEffect(() => {
    fetchDataset(filter.from, filter.to);
  }, [filter, fetchDataset]);

  function toggleDisplay() {
    setShowRatio((current) => !current);
  }

  return (
    <VStack
      space={2}
      alignItems="center"
      rounded="12px"
      bg="dashboardCard"
      px="20px"
      py="9px"
    >
      <Text
        fontSize="16px"
        fontWeight="700"
        textAlign="left"
        // w="181px"
      >
        {title}
      </Text>
      <HStack space={2}>
        <BarChart
          data={dataset}
          height={dataset.length * 40}
          width={202}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          layout="vertical"
        >
          <BarCh
            dataKey={showRatio ? "ratio" : "total"}
            radius={[0, 100, 100, 0]}
            background={{ radius: 100, fill: "rgba(229, 229, 229, 0.5)" }}
            // minPointSize={17}
            barSize={12}
            max={11}
          >
            {dataset.map((entry, index) => {
              let color = colors.medium;
              if (entry.ratio > 0.24) color = colors.good;
              if (entry.ratio > 0.5) color = colors.good;
              if (entry.ratio > 0.75) color = colors.good;
              return <Cell key={index} fill={color} />;
            })}

            {/* <LabelList
              dataKey={showRatio ? "ratio" : "total"}
              position="right"
              fill="#C4C4C4"
              fontFamily="Lato"
            /> */}
          </BarCh>

          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey={(v) => formatTextInVerticalBarChart(v.name, 10)}
            width={80}
            axisLine={false}
            fontFamily="Lato"
            fontSize="12px"
            fontWeight={400}
            color="#000"
            // textAnchor="start"
          />
          {/* <Tooltip  /> */}
          {/* <Legend /> */}
        </BarChart>
        <VStack mt={3} space={"22px"}>
          {dataset.map((entry, index) => (
            <Text
              key={index}
              fontSize="12px"
              fontWeight={700}
              fontFamily="Lato"
            >
              {entry.total}
            </Text>
          ))}
        </VStack>
      </HStack>

      {/* <ResponsiveContainer width="100%" height="100%">
      </ResponsiveContainer> */}
    </VStack>
  );
}

// export default function Bar({ title, action, dataset, fetchDataset, filter }) {
//   const [showRatio, setShowRatio] = useState(false);

//   useEffect(() => {
//     fetchDataset(filter.from, filter.to);
//   }, [filter, fetchDataset]);

//   function toggleDisplay() {
//     setShowRatio((current) => !current);
//   }

//   return (
//     <VStack
//       space={2}
//       alignItems="center"
//       rounded="12px"
//       bg="dashboardCard"
//       px="20px"
//       py="9px"
//     >
//       <Text
//         fontSize="16px"
//         fontWeight="700"
//         textAlign="center"
//         noOfLines={2}
//         w="181px"
//       >
//         {title}
//       </Text>
//       <VStack alignItems="flex-end" w="full">
//         <Pressable onPress={toggleDisplay}>
//           <Text color={showRatio ? "primary" : "balck"}>Ratio %</Text>
//         </Pressable>
//       </VStack>
//       {action.loading || dataset.length === 0 ? (
//         <Spinner accessibilityLabel="Loading posts" color="#F5BD4F" size="lg" />
//       ) : (
//         <BarChart
//           data={dataset}
//           width={dataset.length * 45}
//           height={200}
//           margin={{ top: 5, right: 5, left: 5, bottom: 0 }}
//         >
//           <BarCh
//             dataKey={showRatio ? "ratio" : "total"}
//             radius={100}
//             background={{ radius: 100, fill: "rgba(229, 229, 229, 0.5)" }}
//             minPointSize={17}
//             barSize={15}
//           >
//             {dataset.map((entry, index) => {
//               let color = colors.good;
//               if (entry.ratio > 0.24) color = colors.medium;
//               if (entry.ratio > 0.5) color = colors.bad;
//               if (entry.ratio > 0.75) color = colors.veryBad;
//               return <Cell key={index} fill={color} />;
//             })}
//             <LabelList
//               dataKey={showRatio ? "ratio" : "total"}
//               position="top"
//               fill="#C4C4C4"
//               fontFamily="Lato"
//             />
//             <LabelList
//               dataKey="name"
//               position="insideBottomLeft"
//               angle="-90"
//               fill="#999C9F" // #E5E5E5
//               offset={-5}
//               formatter={formatTextInVerticalBarChart}
//               fontWeight="600"
//               fontFamily="Lato"
//             />
//           </BarCh>

//           {/* <XAxis dataKey="name" /> */}
//           {/* <YAxis /> */}
//           {/* <Tooltip  /> */}
//           {/* <Legend /> */}
//         </BarChart>
//       )}

//       {/* <ResponsiveContainer width="100%" height="100%">
//       </ResponsiveContainer> */}
//     </VStack>
//   );
// }
