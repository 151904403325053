import _ from "lodash";

function getDangerForOneSurvey(survey) {
  const isOk = (property) => {
    return survey.hasOwnProperty(property) && survey[property] !== null;
  };
  let messages = [];

  if (isOk("eyeOpener") && isOk("verbalResponse") && isOk("motorResponse")) {
    const glasgowTotal =
      survey.eyeOpener + survey.verbalResponse + survey.motorResponse;
    if (glasgowTotal < 14) messages.push("GCS" + glasgowTotal);
  }

  if (isOk("saturation") && survey?.saturation < 95)
    messages.push("SAT" + survey?.saturation);
  if (isOk("respiratoryFrequency")) {
    if (survey?.respiratoryFrequency < 12)
      messages.push("FR" + survey?.respiratoryFrequency);
    if (survey?.respiratoryFrequency > 20)
      messages.push("FR" + survey?.respiratoryFrequency);
  }
  if (isOk("circulatoryFrequency")) {
    if (survey?.circulatoryFrequency < 50)
      messages.push("FC" + survey?.circulatoryFrequency);
    if (survey?.circulatoryFrequency > 110)
      messages.push("FC" + survey?.circulatoryFrequency);
  }
  if (
    (isOk("bloodPressureLeft") && survey?.bloodPressureLeft < 100) ||
    (isOk("bloodPressureRight") && survey?.bloodPressureRight < 100)
  ) {
    const msg =
      survey?.bloodPressureLeft < 100
        ? survey?.bloodPressureLeft
        : survey?.bloodPressureRight;
    messages.push("PA" + msg);
  }
  if (
    (isOk("bloodPressureLeft") && survey?.bloodPressureLeft > 170) ||
    (isOk("bloodPressureRight") && survey?.bloodPressureRight > 170)
  ) {
    const msg =
      survey?.bloodPressureLeft > 170
        ? survey?.bloodPressureLeft
        : survey?.bloodPressureRight;
    messages.push("PA" + msg);
  }
  return messages;
}

export function getDangerMessage(bilan) {
  const isOk = (property) => {
    return bilan.hasOwnProperty(property) && bilan[property] !== null;
  };
  let messages = [];
  if (!_.isEmpty(bilan.survey)) {
    let lastElement = bilan.survey[bilan.survey.length - 1];
    messages = [...messages, ...getDangerForOneSurvey(lastElement)];
  } else {
    messages = [
      ...messages,
      ...getDangerForOneSurvey({
        ...bilan?.ventilatory,
        ...bilan?.circulatory,
        ...bilan?.neurological,
      }),
    ];
  }
  if (isOk("ventilatory")) {
    if (
      bilan?.ventilatory.hasOwnProperty("respiratoryArrest") &&
      bilan?.ventilatory.respiratoryArrest
    )
      messages.push("AR !");
  }
  if (
    isOk("circulatory") &&
    bilan?.circulatory.hasOwnProperty("circulatoryArrest") &&
    bilan?.circulatory.circulatoryArrest
  )
    messages.push("AC !");

  if (isOk("various")) {
    if (bilan?.various?.hasOwnProperty("evn") && bilan.various?.evn > 5)
      messages.push("EVN" + bilan.various?.evn);
    if (
      bilan?.various.hasOwnProperty("temperature") &&
      bilan.various.temperature != null
    ) {
      if (bilan.various?.temperature < 35.5)
        messages.push("T" + bilan.various?.temperature);
      if (bilan.various?.temperature > 38)
        messages.push("T" + bilan.various?.temperature);
    }
  }

  return messages;
}
