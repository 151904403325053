import React, { Component } from "react";
import { HStack, Stack } from "native-base";
import { Title, Text, FloatingInput, Button } from "components";

class ResetView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirm: "",
      error: "",
    };

    this.onPropertyChanged = this.onPropertyChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  onSubmit() {
    const { onPressResetPasswordButton, t, i18n } = this.props;
    const { password, passwordConfirm } = this.state;
    if (password === passwordConfirm) {
      onPressResetPasswordButton(password);
    } else {
      this.setState({
        error: t("ForgotPasswordPage.reset.error_message", {
          lng: i18n.language,
        }),
      });
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { password, passwordConfirm, error } = this.state;

    return (
      <Stack space={8} maxWidth="700px">
        <Title>
          {t("ForgotPasswordPage.reset.head_title", { lng: i18n.language })}
        </Title>

        <Text color="danger">{error}</Text>

        <FloatingInput
          inputType="password"
          label={t("ForgotPasswordPage.reset.password_label", {
            lng: i18n.language,
          })}
          onChange={(e) => this.onPropertyChanged({ password: e.target.value })}
          onKeyPress={this.handleKeyPress}
          value={password}
          error={error}
        />

        <FloatingInput
          inputType="password"
          label={t("ForgotPasswordPage.reset.password_confirm_label", {
            lng: i18n.language,
          })}
          onChange={(e) =>
            this.onPropertyChanged({ passwordConfirm: e.target.value })
          }
          onKeyPress={this.handleKeyPress}
          value={passwordConfirm}
          error={error}
        />

        <HStack justifyContent="end" w="full" mt="2">
          <Button
            onPress={this.onSubmit}
            third
            borderColor="primary"
            _text={{
              color: "primary",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
            }}
            px="11px"
            h="32px"
            alignItems="center"
            disabled={password === ""}
          >
            {t("ForgotPasswordPage.reset.button_label", { lng: i18n.language })}
          </Button>
        </HStack>
      </Stack>
    );
  }
}

export default ResetView;
