import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  rideHistoryResetState: null,
  rideHistoryRequest: ["page", "limit", "filter"],
  rideHistoryRequestSuccess: ["rides"],
  rideHistoryRequestFailure: ["error"],

  rideHistoryAppendNewRide: ["ride"],
});

export const RideHistoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const rideHistoryResetState = (state, action) => {
  return INITIAL_STATE;
};

export const rideHistoryRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const rideHistoryRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const rideHistoryRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.rides.page === 1)
    //first page
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  else {
    //apend next pages
    let prevList = state.rides.rides.asMutable({ deep: true });
    const { rides, ...rest } = action.rides;

    return Immutable.setIn(nextSate, ["rides"], {
      ...rest,
      rides: [...prevList, ...rides],
    });
  }
};

export const rideHistoryAppendNewRide = (state, action) => {
  let prevList = state.rides.rides.asMutable({ deep: true });
  return Immutable.setIn(state, ["rides", "rides"], [action.ride, ...prevList]);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RIDE_HISTORY_RESET_STATE]: rideHistoryResetState,
  [Types.RIDE_HISTORY_REQUEST]: rideHistoryRequest,
  [Types.RIDE_HISTORY_REQUEST_SUCCESS]: rideHistoryRequestSuccess,
  [Types.RIDE_HISTORY_REQUEST_FAILURE]: rideHistoryRequestFailure,

  [Types.RIDE_HISTORY_APPEND_NEW_RIDE]: rideHistoryAppendNewRide,
});
