import { put, takeLatest } from "redux-saga/effects";
import OngoingBilansActions, { OngoingBilanTypes } from "reducers/bilan";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* onGoingBilansAsyncRequest({ page = 1, limit = 100 }) {
  // const test = `{
  //   page
  //   limit
  //   totalPages
  //   totalDocs
  //   bilans {
  //     _id
  //     administrative { firstname }
  //     survey { createdAt }
  //   }
  // }`;

  const query = `
    query {
      ServiceOngoingBilans(page:${page}, limit:${limit})
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const bilans = data.ServiceOngoingBilans;
    yield put(OngoingBilansActions.onGoingBilansRequestSuccess(bilans));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      OngoingBilansActions.onGoingBilansRequestFailure
    );
  }
}

function* serviceGetBilanWaitingTimeAsyncRequest({ ridesId }) {
  let _ridesId = _.reduce(ridesId, (acc, e) => [...acc, `"${e}"`], []);

  const query = `
  query{
    ServiceGetBilanWaitingTime(ridesId:[${_ridesId.join(",")}])
      {
        rideId, 
        duration, 
        distance
      }
    }
  `;

  try {
    const { ServiceGetBilanWaitingTime } = yield graphQLClient().request(query);

    yield put(
      OngoingBilansActions.serviceGetBilanWaitingTimeSuccess(
        ServiceGetBilanWaitingTime
      )
    );
  } catch (error) {
    console.error(error);
    yield put(
      OngoingBilansActions.serviceGetBilanWaitingTimeFailure(
        "error waiting time"
      )
    );
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.serviceGetRidesWaitingTimeRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

function* historyBilansAsyncRequest({ page = 1, limit = 100 }) {
  const query = `
    query {
      ServiceHistoryBilans(page:${page}, limit:${limit})
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const bilans = data.ServiceHistoryBilans;
    yield put(OngoingBilansActions.historyBilansRequestSuccess(bilans));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      OngoingBilansActions.historyBilansRequestFailure
    );
  }
}

//

// serviceGetBilanWaitingTimeSuccess
// serviceGetBilanWaitingTimeFailure
/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(
    OngoingBilanTypes.ON_GOING_BILANS_REQUEST,
    onGoingBilansAsyncRequest
  ),
  takeLatest(
    OngoingBilanTypes.SERVICE_GET_BILAN_WAITING_TIME_REQUEST,
    serviceGetBilanWaitingTimeAsyncRequest
  ),
  takeLatest(
    OngoingBilanTypes.HISTORY_BILANS_REQUEST,
    historyBilansAsyncRequest
  ),
];
