import { put, takeLatest } from "redux-saga/effects";
import PlanningActions, { PlanningTypes } from "reducers/planning";
import _ from "lodash";
import errorHandler from "./error-handler";
// import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* planningsAsyncRequest({ page = 1, limit = 5, filter = "" }) {
  const query = `
    {
      SamuPlannings (page: ${page}, limit: ${limit}, filter: "${filter}") {
        page
        limit
        totalPages
        plannings {
          _id
          month
          haveDay
          haveNight
        }
      }
    }
  `;
  try {
    const { SamuPlannings } = yield graphQLClient().request(query);
    yield put(PlanningActions.planningsRequestSuccess(SamuPlannings));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, PlanningActions.planningsRequestFailure);
  }
}

function* createPlanningAsyncRequest({ planning, callback }) {
  const query = `
    mutation {
      SamuAddPlanning(planningPath: "${planning}")
    }
  `;

  try {
    yield graphQLClient().request(query);
    yield put(PlanningActions.createPlanningRequestSuccess());
    // yield put(PlanningActions.planningsRequest(1, 100));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, PlanningActions.createPlanningRequestFailure);
    if (_.isFunction(callback)) callback(message);
  }
}

function* deletePlanningAsyncRequest({ planningId, callback }) {
  const query = `
    mutation {
      SamuRemovePlanning(planningId:"${planningId}")
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(PlanningActions.deletePlanningRequestSuccess());
    yield put(PlanningActions.planningsRequest(1, 100));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    // yield errorHandler(
    //   message,
    //   PlanningActions.deletePlanningRequestFailure
    // );
    if (_.isFunction(callback)) callback(error);
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(PlanningTypes.PLANNINGS_REQUEST, planningsAsyncRequest),
  takeLatest(PlanningTypes.CREATE_PLANNING_REQUEST, createPlanningAsyncRequest),
  takeLatest(PlanningTypes.DELETE_PLANNING_REQUEST, deletePlanningAsyncRequest),
];
