import _ from "lodash";
import { saveAs } from "file-saver";

export const capitalize = (string) => {
  if (typeof string !== "string") return "";
  const stringLower = string.toLowerCase();
  return stringLower.charAt(0).toUpperCase() + stringLower.slice(1);
};

export function getQueryString(name) {
  var after = window.location.hash.split("?")[1];
  if (after) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = after.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    } else {
      return null;
    }
  }
}

export function getBackendUrl() {
  let BACK_END = "";
  if (process.env.REACT_APP_STAGE === "dev")
    BACK_END = process.env.REACT_APP_BACK_END_URL_DEV;
  else if (process.env.REACT_APP_STAGE === "prod")
    BACK_END = process.env.REACT_APP_BACK_END_URL_PROD;
  else if (process.env.REACT_APP_STAGE === "demo")
    BACK_END = process.env.REACT_APP_BACK_END_URL_DEMO;
  else BACK_END = process.env.REACT_APP_BACK_END_URL;
  return BACK_END;
}

export function downloadFilesAsBlob(urls, paths, patient) {
  for (const urls3 of urls) {
    const file = paths.find((i) => urls3.includes(i.name));
    const extension = file?.extension || "png";
    fetch(urls3, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, patient.lastname + "_" + file.name + "." + extension);
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  }
}

export function uploadFile({ url, file, callback }) {
  fetch(url, {
    method: "PUT",
    headers: { "Content-Type": file.type },
    body: file,
  })
    .then((res) => {
      if (_.isFunction(callback)) callback(res);
      // console.log(res)
    })
    .catch((e) => {
      if (_.isFunction(callback)) callback(false);
      // console.log(e)
    });
}
