import { put, takeLatest } from "redux-saga/effects";
import VehicleActions, {
  VehicleTypes,
} from "reducers/transporter/transporter-vehicle";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* vehiclesAsyncRequest({ page, limit, filter = "" }) {
  const query = `
    {
      SamuVehicles(page:${page},limit:${limit},filter:"${filter}") { 
        page
        limit
        totalPages
        vehicles {
          _id
          status
          vehicle { model color make plate category phone }
          company { name id }
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const vehicles = data.SamuVehicles;

    yield put(VehicleActions.vehiclesRequestSuccess(vehicles));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, VehicleActions.vehiclesRequestFailure);
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(VehicleTypes.VEHICLES_REQUEST, vehiclesAsyncRequest),
];
