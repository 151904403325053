import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  partnersResetState: null,

  partnersRequest: ["page", "limit", "filter"],
  partnersRequestSuccess: ["partners"],
  partnersRequestFailure: ["error"],

  createPartnerRequest: ["partner", "callback"],
  createPartnerRequestSuccess: ["partner"],
  createPartnerRequestFailure: ["error"],

  updatePartnerRequest: ["partner", "callback"],
  updatePartnerSuccess: ["partner"],
  updatePartnerFailure: ["error"],

  deletePartnerRequest: ["partnerId"],
  deletePartnerRequestSuccess: null,
  deletePartnerRequestFailure: ["error"],

  searchPartnerRequest: ["page", "limit", "filter"],
  searchPartnerSuccess: ["companies"],
  searchPartnerFailure: ["error"],

  sectorsRequest: null,
  sectorsSuccess: ["sectors"],
  sectorsFailure: ["error"],
});

export const PartnerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  partners: {
    page: 1,
    limit: 10,
    partners: [],
  },
  search: {
    page: 1,
    limit: 100,
    companies: [],
  },
  sectors: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const partnersResetState = (state, action) => {
  return INITIAL_STATE;
};

export const partnersRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const partnersRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["partners", "partners"], action.partners);
};

export const partnersRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------add partner -----------------------*/

export const createPartnerRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const createPartnerRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const createPartnerRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------ delete partner -----------------------*/

export const deletePartnerRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const deletePartnerRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const deletePartnerRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------ delete partner -----------------------*/

export const searchPartnerRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const searchPartnerSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["search"], action.companies);
};

export const searchPartnerFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------ update partner -----------------------*/

export const updatePartnerRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const updatePartnerSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const updatePartnerFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------ sectors request -----------------------*/

export const sectorsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const sectorsSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["sectors"], action.sectors);
};

export const sectorsFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PARTNERS_RESET_STATE]: partnersResetState,
  [Types.PARTNERS_REQUEST]: partnersRequest,
  [Types.PARTNERS_REQUEST_SUCCESS]: partnersRequestSuccess,
  [Types.PARTNERS_REQUEST_FAILURE]: partnersRequestFailure,

  [Types.CREATE_PARTNER_REQUEST]: createPartnerRequest,
  [Types.CREATE_PARTNER_REQUEST_SUCCESS]: createPartnerRequestSuccess,
  [Types.CREATE_PARTNER_REQUEST_FAILURE]: createPartnerRequestFailure,

  [Types.DELETE_PARTNER_REQUEST]: deletePartnerRequest,
  [Types.DELETE_PARTNER_REQUEST_SUCCESS]: deletePartnerRequestSuccess,
  [Types.DELETE_PARTNER_REQUEST_FAILURE]: deletePartnerRequestFailure,

  [Types.SEARCH_PARTNER_REQUEST]: searchPartnerRequest,
  [Types.SEARCH_PARTNER_SUCCESS]: searchPartnerSuccess,
  [Types.SEARCH_PARTNER_FAILURE]: searchPartnerFailure,

  [Types.UPDATE_PARTNER_REQUEST]: updatePartnerRequest,
  [Types.UPDATE_PARTNER_SUCCESS]: updatePartnerSuccess,
  [Types.UPDATE_PARTNER_FAILURE]: updatePartnerFailure,

  [Types.SECTORS_REQUEST]: sectorsRequest,
  [Types.SECTORS_SUCCESS]: sectorsSuccess,
  [Types.SECTORS_FAILURE]: sectorsFailure,
});
