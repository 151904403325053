import { Component } from "react";
import { Box, HStack, Modal, Pressable, VStack } from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { Button, Text, Title, Card } from "components";
// import InfiniteScroll from "react-infinite-scroll-component";
// import Loader from "react-loader-spinner";

class PartnerFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: [], // list of id
      siret: "", // ambulance fictive
    };

    this.onPropertyChanged = this.onPropertyChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.resetState = this.resetState.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
    this.togglePartner = this.togglePartner.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { searchPartnerRequest, searchPartner } = this.props;
    const { page, limit, totalPages } = searchPartner;
    if (page < totalPages) searchPartnerRequest(page + 1, limit);
    else searchPartnerRequest(1, limit);
  }

  togglePartner(id, sectorId) {
    let partners = [...this.state.partners];
    const sector = this.props.sectors.find((s) => s.sectorId === sectorId);

    if (!sector) return;
    // if partner is already in the list, remove it
    // else add it
    const existingPartner = partners.find((p) => p.partnerId === id);

    // remove
    if (existingPartner) {
      let existingSector = existingPartner.sectors.find(
        (s) => s.sectorId === sectorId
      );

      // remove sector
      if (existingSector) {
        existingPartner.sectors = existingPartner.sectors.filter(
          (s) => s.sectorId !== sectorId
        );
      } else {
        // add sector
        existingPartner.sectors.push(sector);
      }

      // check if have 0 sector
      if (existingPartner.sectors.length === 0) {
        // partners = partners.filter(p => p !== id);
        partners = partners.filter((p) => p.partnerId !== id);
      }
    }
    // add with 1 sector
    else {
      partners.push({
        partnerId: id,
        sectors: [sector],
      });
    }

    this.setState({ partners });
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmitForm();
    }
  };

  resetState() {
    this.setState({
      siret: "",
    });
  }

  cancelAction() {
    this.resetState();
    this.props.onClose();
  }

  onSubmitForm() {
    const { partners } = this.state;

    if (!_.isEmpty(partners)) {
      this.props.onConfirm(partners);
    }
  }

  findPartnerAndSector(partnerId, sectorId) {
    const partner = this.state.partners.find((p) => p.partnerId === partnerId);
    if (partner) {
      const sector = partner.sectors.find((s) => s.sectorId === sectorId);
      if (sector) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {
      t,
      i18n,
      error = false,
      searchPartner,
      sectors,
      // action,
    } = this.props;

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="517px" px="" py="41px">
          <Modal.Header>
            {/* TITLE */}
            <VStack space="2">
              <Title
                fontSize="36px"
                fontWeight="400"
                textAlign="center"
                fontFamily="Lato"
              >
                {t("addPartnerPopUp.title_headline", {
                  lng: i18n.language,
                })}
              </Title>

              <Text fontSize="14px" color="default" textAlign="center">
                {t("addPartnerPopUp.subtitle_headline", {
                  lng: i18n.language,
                })}
              </Text>
              {error && (
                <Text color="danger">
                  {t("addPartnerPopUp.fail_add", { lng: i18n.language })}
                </Text>
              )}
            </VStack>
          </Modal.Header>

          {/* <InfiniteScroll
              dataLength={searchPartner.companies.length} // This is important field to render the next data
              next={this.fetchData}
              hasMore={true}
              scrollableTarget="scrollableDiv"
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={action.loading}
                />
              }
            >
            </InfiniteScroll> */}
          <Modal.Body>
            <VStack space={1}>
              {searchPartner.companies.map((partner, index) => (
                <Card
                  key={index}
                  pl="29px"
                  rounded={4}
                  borderColor="gray"
                  borderWidth="1px"
                >
                  <VStack space={2}>
                    <HStack
                      space={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text flex={2}>{partner.name}</Text>
                      <Text isTruncated flex={3} fontSize="12px">
                        {partner.address.address}
                      </Text>
                    </HStack>
                    <HStack space={2} alignItems="center" justifyContent="end">
                      {sectors.map((sector, key) => (
                        <Pressable
                          key={key}
                          onPress={() =>
                            this.togglePartner(partner._id, sector.sectorId)
                          }
                        >
                          <Box
                            rounded={4}
                            borderWidth="1px"
                            borderColor="gray"
                            px="10px"
                            py="5px"
                            bg={
                              this.findPartnerAndSector(
                                partner._id,
                                sector.sectorId
                              )
                                ? "primary"
                                : "white"
                            }
                          >
                            <Text
                              fontSize="12px"
                              color={
                                this.findPartnerAndSector(
                                  partner._id,
                                  sector.sectorId
                                )
                                  ? "white"
                                  : "gray"
                              }
                            >
                              {sector.sectorName}
                            </Text>
                          </Box>
                        </Pressable>
                      ))}
                    </HStack>
                  </VStack>
                </Card>
                //   onPress={() => this.togglePartner(partner._id, 1)}
              ))}
            </VStack>
          </Modal.Body>
          <Modal.Footer bg="light">
            {/* BUTTONS */}
            <HStack justifyContent="end" space="11px" alignItems="center">
              <Button
                minWidth="140px"
                third
                borderWidth="1px"
                px="36px"
                py="8px"
                onPress={this.cancelAction}
              >
                {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
              </Button>

              <Button
                minWidth="140px"
                isDisabled={_.isEmpty(this.state.partners)}
                borderWidth="0px"
                px="36px"
                py="8px"
                onPress={this.onSubmitForm}
              >
                {t(`button.add`, { lng: i18n.language }).toUpperCase()}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  PartnerFormModal
);
