import { put, takeLatest } from "redux-saga/effects";
import NewRideActions, { NewRideTypes } from "reducers/ride/new-ride";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* samuCreateNewRideAsyncRequest({ ride, callback }) {
  const query = `
  mutation{
    SamuCreateNewRide(
    ${ride.join(",")}
    )
  }
  `;
  try {
    const { SamuCreateNewRide } = yield graphQLClient().request(query);

    if (SamuCreateNewRide) {
      yield put(NewRideActions.samuCreateNewRideRequestSuccess());
      if (_.isFunction(callback)) callback();
    } else {
      yield put(NewRideActions.samuCreateNewRideRequestFailure("Server error"));
    }
  } catch (error) {
    yield put(NewRideActions.samuCreateNewRideRequestFailure("Server error"));
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(
    NewRideTypes.SAMU_CREATE_NEW_RIDE_REQUEST,
    samuCreateNewRideAsyncRequest
  ),
];
