import React, { Component } from "react";
import { Stack, HStack, Link } from "native-base";
import { Title, Text, FloatingInput, Button } from "components";

class RequestView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email:
        process.env.NODE_ENV === "development" ? "admin@montfermeil.com" : "",
    };

    this.onPropertyChanged = this.onPropertyChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  onSubmit() {
    const { onPressForgotPasswordButton } = this.props;
    onPressForgotPasswordButton(this.state.email);
  }

  render() {
    const { t, i18n } = this.props;
    const { email } = this.state;

    return (
      <Stack space={8} maxWidth="700px">
        <Title>
          {t("ForgotPasswordPage.request.head_title", { lng: i18n.language })}
        </Title>

        <Text textAlign="center">
          {t("ForgotPasswordPage.request.description", { lng: i18n.language })}
        </Text>

        <FloatingInput
          inputType="email"
          label={t("ForgotPasswordPage.request.email_label", {
            lng: i18n.language,
          })}
          onChange={(e) => this.onPropertyChanged({ email: e.target.value })}
          onKeyPress={this.handleKeyPress}
          value={email}
        />

        <HStack justifyContent="space-between" w="full" mt="2">
          <Link href={"/"}>
            {t("ForgotPasswordPage.request.login_link", { lng: i18n.language })}
          </Link>

          <Button
            onPress={this.onSubmit}
            third
            borderColor="primary"
            _text={{
              color: "primary",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
            }}
            px="11px"
            h="32px"
            alignItems="center"
          >
            {t("ForgotPasswordPage.request.button_label", {
              lng: i18n.language,
            })}
          </Button>
        </HStack>
      </Stack>
    );
  }
}

export default RequestView;
