import React, { useEffect, useState } from "react";
import { Divider, HStack, ScrollView, Stack, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import ExportButton from "./ExportButton";
import { Text } from "components";

export default function HistoryMonth({ selectedMonth }) {
  const { t } = useTranslation("Private");
  const [rides, setRides] = useState([]);

  useEffect(() => {
    if (selectedMonth == null) {
      setRides([]);
    } else {
      const fakerNumberRides = _.random(0, 20);
      const fakerRides = _.times(fakerNumberRides, (index) => ({
        name: "Société " + index,
        // date in the selected month and year
        date: moment(selectedMonth).add(_.random(0, 30), "days").toDate(),
      })).sort((a, b) => moment(a.date).diff(b.date));

      setRides(fakerRides);
      // fetchRides
      // use history reducers to fetch rides with selectedMonth params
    }
  }, [selectedMonth]);

  const onExportDocument = (format) => {
    console.log("export history with format", format);
  };

  return (
    <VStack flex={1} py={2} space={2} rounded={4} bg="muted.100">
      <HStack alignItems="center" justifyContent="space-between" pr={4} pt={1}>
        <Stack w="18px" h="16px" />

        <Text fontSize="lg" fontWeight="bold">
          {t("Planning.subtitleHistory")}
          {/*  {selectedMonth ? moment(selectedMonth).format("MMMM YYYY") : "nouveau mois"} */}
        </Text>

        <ExportButton
          disabled={selectedMonth === null}
          onExport={onExportDocument}
        />
      </HStack>

      <ScrollView h="full" py={4}>
        <VStack space={2} divider={<Divider />}>
          {rides.length === 0 && (
            <Text textAlign="center">{t("Planning.historyNoRides")}</Text>
          )}
          {rides.map((ride, key) => (
            <HStack key={key} px={4} py={1} justifyContent="space-between">
              <Text>{ride?.name}</Text>
              <Text>
                {ride?.date ? moment(ride.date).format("DD/MM HH[h]mm") : "n/a"}
              </Text>
            </HStack>
          ))}
        </VStack>
      </ScrollView>
    </VStack>
  );
}
