import React, { Component } from "react";
import { HStack, Box, Link } from "native-base";
import { Title, Text } from "components";
import approveChatIcon from "assets/img/approve_chat.svg";

class SuccessView extends Component {
  render() {
    const { t, i18n, isRequest } = this.props;

    return (
      <HStack space={8} maxWidth="700px">
        <Box>
          <img src={approveChatIcon} alt="approve chat" />
        </Box>
        <Text display="flex" flexDirection="column">
          <Title noOfLines={2}>
            {t("ForgotPasswordPage.success.head_title", { lng: i18n.language })}
          </Title>

          <Text noOfLines={2}>
            {t(
              `ForgotPasswordPage.success.${
                isRequest ? "description_request" : "description_reset"
              }`,
              { lng: i18n.language }
            )}
          </Text>

          {isRequest && (
            <>
              <Text italic color="disabled">
                {t("ForgotPasswordPage.success.disclaimer", {
                  lng: i18n.language,
                })}
              </Text>
            </>
          )}

          <HStack justifyContent="end" w="full" mt="2">
            <Link href={"/"}>
              {t("ForgotPasswordPage.request.login_link", {
                lng: i18n.language,
              })}
            </Link>
          </HStack>
        </Text>
      </HStack>
    );
  }
}

export default SuccessView;
