import React, { useEffect, useState } from "react";
import { Center, HStack, Select, Spinner, VStack } from "native-base";
import { Text } from "components";
import LegendChart from "./LegendChart";

const textStyle = {
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Lato",
  color: "white",
};

function Progress({ first = 0, second = 0, firstRatio = 0 }) {
  return (
    <VStack rounded={100} h="full" w="58px" bg="#E5E5E5">
      <Center rounded={100} _text={textStyle} flex={1}>
        {second}
      </Center>
      {first > 0 && (
        <Center
          rounded={100}
          _text={textStyle}
          bg="#A1E3CB"
          h={firstRatio + "%"}
          maxH="90%"
        >
          {first}
        </Center>
      )}
    </VStack>
  );
}

export default function TransporterAvailability({
  action,
  dataset,
  fetchDataset,
  companies = [],
}) {
  const [selectedCompanies, setSelectedCompanies] = useState("all");
  const [ratioAvailabily, setRatioAvailabily] = useState(0);

  useEffect(() => {
    fetchDataset(selectedCompanies === "all" ? [] : [selectedCompanies]);
  }, [selectedCompanies, fetchDataset]);

  useEffect(() => {
    if (dataset?.samu && dataset.total > 0)
      setRatioAvailabily(Math.round((dataset.samu / dataset.total) * 100));
    else setRatioAvailabily(0);
  }, [dataset]);

  return (
    <VStack
      space={2}
      alignItems="center"
      rounded="12px"
      bg="dashboardCard"
      px="20px"
      py="9px"
      h="100%"
    >
      <Text
        fontSize="16px"
        fontWeight="700"
        textAlign="center"
        noOfLines={2}
        w="181px"
        lineHeight="normal"
      >
        Taux d'engagement
        <br />
        des véhicules
      </Text>
      <Select
        selectedValue={selectedCompanies}
        minWidth={200}
        accessibilityLabel="Selectionner une entreprise"
        placeholder="Selectionner une entreprise"
        onValueChange={(itemValue) => setSelectedCompanies(itemValue)}
        borderWidth={0}
      >
        <Select.Item label="Toutes les ambulances" value={"all"} />
        {companies.map((company, index) => (
          <Select.Item
            key={index}
            label={company.name}
            value={company.partnerId}
          />
        ))}
      </Select>

      {action.loading || !dataset ? (
        <Center flex={1}>
          <Spinner
            accessibilityLabel="Loading posts"
            color="#F5BD4F"
            size="lg"
          />
        </Center>
      ) : (
        <HStack space={2} justifyContent="center" flex={1}>
          <Progress
            first={dataset.samu}
            second={dataset.total}
            firstRatio={ratioAvailabily}
            secondRatio={dataset.total - ratioAvailabily}
          />

          <VStack justifyContent="center" space={0}>
            <Text
              fontSize="36px"
              fontWeight="900"
              color="#A1E3CB"
              p={0}
              fontFamily="Lato"
            >
              {ratioAvailabily}%
            </Text>
            <Text
              fontSize="10px"
              fontWeight="700"
              color="gray"
              fontFamily="Lato"
            >
              de véhicules engagés
            </Text>
          </VStack>
        </HStack>
      )}

      <HStack justifyContent="space-evenly" w="full" mt="33px" mb="19px">
        <LegendChart fill="#E5E5E5" label="Total Disponible" />
        <LegendChart fill="#A1E3CB" label="En mission" />
      </HStack>
    </VStack>
  );
}
