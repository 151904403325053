import React from "react";
import ReactDOM from "react-dom";
import Boot from "boot";
import { NativeBaseProvider, extendTheme } from "native-base";
// import reportWebVitals from "reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";
// import "assets/react-datepicker-cssmodules.min.css";

const theme = extendTheme({
  config: {
    initialColorMode: "ligth",
    // button: {
    //   primary
    // }
    fonts: {
      heading: "Baloo",
      body: "Lato",
      mono: "Roboto",
    },
  },
  colors: {
    default: "#000000", // black
    primary: "#5FAEE8", // blue black
    primaryDark: "#1d4461", // blue black
    secondary: "#035FA2", // blue
    success: "#02A76B", // green
    gray: "#999C9F",
    grayLight: "#EBF5F8",
    ligthGray: "#E5E5E5",
    ligth: "#FFFFFF", // white
    ligthDark: "#F0F0F0", // white darker
    warning: "#FF7A00", // orange
    danger: "#FF1700", // red
    disabled: "#C4C4C4", //gray
    info: "#F5BD4F",
    dashboardCard: "rgba(95, 174, 232, 0.06)",

    status: {
      unassigned: "#FF7A00", // orange
      pending: "rgba(0, 147, 255, 0.2)", // bleu
      incoming: "rgba(19, 43, 68, 0.2)", //ligthGray
      anoffer: "#F5BD4F", // yellow
      onway: "rgba(3, 95, 163, 0.2)", // light blue
      waiting: "rgba(2, 150, 174, 0.2)", // turquoise
      onboard: "rgba(2, 167, 108, 0.2)", // vert
      arrived: "rgba(19,43,68, 0.2)", // dark blue
      completed: "#132B44", // dark blue
      canceled: "#999C9F", // gray
      // canceled: "#EBF5F8", // blue baby
    },
    textStatus: {
      unassigned: "#132B44",
      pending: "rgba(0, 147, 255, 1)",
      incoming: "rgba(19, 43, 68, 1)",
      anoffer: "#132B44",
      onway: "rgba(3, 95, 163, 1)",
      waiting: "rgba(2, 150, 174, 1)",
      onboard: "rgba(2, 167, 107, 1)",
      arrived: "#132B44",
      completed: "#132B44",
      canceled: "#132B44",
    },
  },
  shadows: {
    // 0px 4px 10px 5px rgba(0, 0, 0, 0.1)
    login: {
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.1,
      shadowRadius: 5,
      elevation: 10,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <Boot />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
