import { Component } from "react";
import { Box } from "native-base";

class Card extends Component {
  render() {
    const { children } = this.props;

    return (
      <Box
        borderWidth="2"
        borderRadius="4px"
        boxSize="border-box"
        borderColor="gray"
        py="2"
        px="10"
        bg="ligth"
        {...this.props}
      >
        {children}
      </Box>
    );
  }
}

export default Card;
