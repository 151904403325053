import { Component } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { Box, HStack, Stack, Menu, Pressable } from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import routes from "routes";
import rideChangeStatusAction from "reducers/transporter/transporter-ongoing-rides";
import DriverLocationActions from "reducers/ride/driver-location";
import PartnerAction from "reducers/transporter/transporter-partner";

import gear from "assets/img/gear.svg";
import { SideBar, Text } from "components";

import {
  OnGoingRideStatusNotification,
  OnGoingRideBilanUpdatedNotification,
} from "layouts/private/ride";

import bip from "assets/raw/juntos.mp3";
import OnGoingNewRideNotification from "./ride/OnGoingNewRideNotification";
import LogoutConfirmationDialog from "components/LogoutConfirmation";

class Private extends Component {
  constructor(props) {
    super(props);

    this.playSoundNotification = this.playSoundNotification.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.onReadedNotifications = this.onReadedNotifications.bind(this);
    this.readedNotificationInPage = this.readedNotificationInPage.bind(this);
    this.logout = this.logout.bind(this);
    this.onConfirmLogout = this.onConfirmLogout.bind(this);
    this.onCancelLogout = this.onCancelLogout.bind(this);

    this.state = {
      soundIsPlayed: false,
      notifications: [],
      soundReminder: null,
      showLogoutConfirmationDialog: false,
    };
  }

  componentDidMount() {
    // load profile firstname, lastname, society name...
    this.props.profileRequest();

    // load partners
    this.props.partnersRequest();
  }

  componentWillUnmount() {
    clearInterval(this.state.soundReminder);
  }

  logout() {
    this.setState(
      Object.assign({}, this.state, { showLogoutConfirmationDialog: true })
    );
  }

  onConfirmLogout() {
    const { signoutRequest } = this.props;

    signoutRequest(() => {
      // to hide the logout confirmation dialog
      this.onCancelLogout();
    });
  }

  onCancelLogout() {
    this.setState(
      Object.assign({}, this.state, { showLogoutConfirmationDialog: false })
    );
  }

  playSoundNotification(type = "default") {
    this.setState({ soundIsPlayed: true });
    let sound = bip;
    // type = ["info", "success", "danger"];
    switch (type) {
      case "default":
        sound = bip;
        break;
      default:
        break;
    }

    new Audio(sound).play();

    setTimeout(() => {
      this.setState({ soundIsPlayed: false });
    }, 2_000);
  }

  showNotification(notif) {
    if (!this.state.soundIsPlayed) {
      this.playSoundNotification(notif.type);
    }

    // Toast.show({
    //   placement: "top-right",
    //   render: () => {
    //     return (
    //       <Box mr="8" bg="primary" px="12px" py="3px" borderRadius="10px">
    //         <Center minHeight="8">
    //           <Text color="ligth">{notif.message}</Text>
    //         </Center>
    //       </Box>
    //     );
    //   },
    // });

    // this.setState({ notifications: [notif, ...this.state.notifications] });
  }

  onReadedNotifications() {
    const notifsReaded = this.state.notifications.map((notif) => ({
      ...notif,
      readed: true,
    }));
    this.setState({ notifications: notifsReaded });
  }

  readedNotificationInPage(page) {
    const { notifications } = this.state;
    const notificationsUpdated = notifications.map((notif) => {
      if (notif.page === page) return { ...notif, readed: true };
      return notif;
    });
    this.setState({ notifications: notificationsUpdated });
  }

  getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === "/private") {
        const View = prop.component;
        return (
          <Route
            path={prop.path}
            element={
              <View
                {...this.props}
                notifications={this.state.notifications}
                onReadedNotifications={this.onReadedNotifications}
              />
            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  render() {
    const { profile, t, i18n } = this.props;

    return (
      <>
        <Stack h="full">
          {/* <UserStatusSubscription   {...this.props} /> */}
          <HStack w="full" h="full" bg="ligth">
            <OnGoingRideStatusNotification
              onGoingRideStatusChanged={this.props.onGoingRideStatusChanged}
              onGoingRidesRemoveRide={this.props.onGoingRidesRemoveRide}
              samuGetRidesWaitingTimeRequest={
                this.props.samuGetRidesWaitingTimeRequest
              }
              showNotification={this.showNotification}
            />

            <OnGoingRideBilanUpdatedNotification
              onGoingRideStatusChanged={this.props.onGoingRideStatusChanged}
            />

            <OnGoingNewRideNotification
              onGoingRidesNewRideRequest={this.props.onGoingRidesNewRideRequest}
              showNotification={this.showNotification}
            />

            <SideBar
              minWidth="125px"
              notifications={this.state.notifications}
              readedNotificationInPage={this.readedNotificationInPage}
              {...this.props}
            />

            <div style={{ display: "flex", flexGrow: 1, minWidth: "" }}>
              <Routes>{this.getRoutes(routes)}</Routes>
            </div>

            <HStack
              position="fixed"
              top={0}
              right={0}
              px="20px"
              py="5px"
              borderBottomLeftRadius={12}
              display="flex"
              space={2}
              alignItems="center"
              gap="20px"
              color="black"
              bg="#E8E8E8"
              shadow="rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
            >
              <Box>
                <Text fontSize="14px">
                  {_.capitalize(profile?.lastname)}{" "}
                  {_.capitalize(profile?.firstname)} -{" "}
                  {_.capitalize(profile?.company?.name)}
                </Text>
              </Box>
              <Menu
                // placement="bottom-end"
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      {...triggerProps}
                      w="8"
                      // pt="3"
                      color="primary"
                      accessibilityLabel="More option menu"
                    >
                      <img src={gear} alt="icon gear" />
                    </Pressable>
                  );
                }}
              >
                <Menu.Item
                  className="settings"
                  onPress={() => window.open("/#/settings", "_self")}
                >
                  {t("navLinks.settings_menu_item_label", {
                    lng: i18n.language,
                  })}
                </Menu.Item>

                <Menu.Item className="logout" onPress={this.logout}>
                  {t("navLinks.log_out_menu_item_label", {
                    lng: i18n.language,
                  })}
                </Menu.Item>
              </Menu>
            </HStack>
          </HStack>
        </Stack>

        <LogoutConfirmationDialog
          className="side-bar-dialog"
          {...{ t, i18n }}
          isOpen={this.state.showLogoutConfirmationDialog}
          onConfirm={this.onConfirmLogout}
          onClose={this.onCancelLogout}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onGoingRides: state.onGoingRides.rides.asMutable({ deep: true }),
    partners: state.transporterPartners.partners.asMutable({ deep: true }),
    profile: state.setting.profile.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGoingRideStatusChanged: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRideStatusChanged(...args)),
    onGoingRidesRemoveRide: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRidesRemoveRide(...args)),
    onGoingRidesNewRideRequest: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRidesNewRideRequest(...args)),

    samuGetRidesWaitingTimeRequest: (...args) =>
      dispatch(DriverLocationActions.samuGetRidesWaitingTimeRequest(...args)),
    partnersRequest: (...args) =>
      dispatch(PartnerAction.partnersRequest(...args)),
    profileRequest: () => dispatch({ type: "PROFILE_REQUEST" }),
  };
};

const reduxPrivate = connect(mapStateToProps, mapDispatchToProps)(Private);

export default withTranslation(["Private", "Common"], { wait: true })(
  reduxPrivate
);
