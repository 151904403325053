import { Component } from "react";
import { HStack, VStack, Box } from "native-base";
import Card from "./Card";
import { Text } from "../Text";
import { capitalize } from "functions";
// import VehicleOptions from "../VehicleOptions";
// import { VEHICLE_ICONS } from "global";
// import threeDotIcon from "assets/img/three_dots.svg";

class VehicleCard extends Component {
  render() {
    const { vehicle = {} } = this.props;
    const { model, make, plate, category, phone } = vehicle.vehicle;
    const { status, company } = vehicle;

    return (
      <Card
        minWidth="986px"
        h="75px"
        py={0}
        pl="29px"
        mb="8px"
        borderColor="gray"
        borderWidth="1px"
        maxWidth="986px"
      >
        {/* <Box alignItems="end" w="full">
          <Menu
            placement="bottom"
            trigger={(triggerProps) => (
              <Pressable
                {...triggerProps}
                accessibilityLabel="More option menu"
              >
                <img src={threeDotIcon} />
              </Pressable>
            )}
          >
            <Menu.Item className="logout" onPress={this.delete}>
              {t("vehiclesPage.button_delete_vehicle", { lng: i18n.language })}
            </Menu.Item>
          </Menu>
        </Box> */}
        <HStack h="full" space={4}>
          <Box
            justifyContent="center"
            borderColor="gray"
            borderRightWidth={1}
            w="200px"
          >
            <Text fontSize="16px" fontWeight="900">
              {company.name}
            </Text>
          </Box>

          <HStack pt="12px" flex={1} pl="20px">
            <VStack flex={1}>
              <Text fontSize="16px" fontWeight="900">
                {capitalize(model)}
              </Text>
              <Text fontSize="16px" fontWeight="900">
                {plate.toUpperCase()}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                N° téléphone
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {phone}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                Marque du véhicule
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {capitalize(make)}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                Type du véhicule
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {category}
              </Text>
            </VStack>

            <HStack space="2" justifyContent="center" flex={1}>
              <Box
                rounded="full"
                size="15px"
                bg={status === "free" ? "success" : "info"}
              />
              <Text
                fontSize="14px"
                fontWeight="700"
                fontStyle="italic"
                color={status === "free" ? "success" : "info"}
              >
                {status === "free" ? "disponible" : "indisponible"}
              </Text>
            </HStack>
          </HStack>
        </HStack>
      </Card>
    );
  }
}

export default VehicleCard;
