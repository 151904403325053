import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  onGoingRidesResetState: null,
  onGoingRidesRequest: ["page", "limit", "filter"],
  onGoingRidesRequestSuccess: ["rides"],
  onGoingRidesRequestFailure: ["error"],

  onGoingRideStatusChanged: ["ride"],
  // onGoingRidePatientStatusChanged: ["ride"],
  onGoingRidesNewRideRequest: ["ride"],
  onGoingRidesRemoveRide: ["ride"],

  onCancelRideRequest: ["rideId", "reason", "callback"],
  onCancelRideSuccess: ["ride"],
  onCancelRideFailure: ["error"],

  updateDropoffRequest: ["rideId", "dropoff", "callback"],
  updateDropoffSuccess: ["ride"],
  updateDropoffFailure: ["error"],
});

export const OngoingRideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  ridesNeedAssign: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// Subscription
export const onGoingRidesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const onGoingRideStatusChanged = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      return { ...r, ...ride };
    }
    return r;
  });
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const onGoingRidesRemoveRide = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = rides.filter((r) => r._id !== ride._id);
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

// export const onGoingRidePatientStatusChanged = (state, action) => {
//   const { ride } = action;
//   let rides = state.rides.rides.asMutable({ deep: true });
//   let _rides = _.map(rides, (r) => {
//     if (ride._id === r._id) {
//       r.patient.status = ride.patient.status;
//     }
//     return r;
//   });
//   return Immutable.setIn(state, ["rides", "rides"], _rides);
// };

/** Query */

export const onGoingRidesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const onGoingRidesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onGoingRidesRequestSuccess = (state, action) => {
  const nextSate = Immutable.setIn(state, ["action", "loading"], false);

  if (action.rides.page === 1) {
    //first page
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  }

  const prevList = state.rides.rides.asMutable({ deep: true }); //
  const { rides, ...rest } = action.rides; //

  return Immutable.setIn(nextSate, ["rides"], {
    ...rest,
    rides: [...prevList, ...rides],
  });
};

export const onGoingRidesNewRideRequest = (state, action) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  rideRequests = [action.ride, ...rideRequests.rides];
  return Immutable.setIn(state, ["rides", "rides"], rideRequests);
};

// CANCLE RIDE
export const onCancelRideRequest = onGoingRidesRequest;

export const onCancelRideSuccess = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.filter(rides, (r) => r._id !== ride._id);
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const onCancelRideFailure = onGoingRidesRequestFailure;

// UPDATE DROPOFF
export const updateDropoffRequest = onGoingRidesRequest;

export const updateDropoffSuccess = onGoingRideStatusChanged;

export const updateDropoffFailure = onGoingRidesRequestFailure;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_GOING_RIDES_RESET_STATE]: onGoingRidesResetState,
  [Types.ON_GOING_RIDES_REQUEST]: onGoingRidesRequest,
  [Types.ON_GOING_RIDES_REQUEST_SUCCESS]: onGoingRidesRequestSuccess,
  [Types.ON_GOING_RIDES_REQUEST_FAILURE]: onGoingRidesRequestFailure,

  [Types.ON_GOING_RIDE_STATUS_CHANGED]: onGoingRideStatusChanged,
  // [Types.ON_GOING_RIDE_PATIENT_STATUS_CHANGED]: onGoingRidePatientStatusChanged,
  [Types.ON_GOING_RIDES_NEW_RIDE_REQUEST]: onGoingRidesNewRideRequest,
  [Types.ON_GOING_RIDES_REMOVE_RIDE]: onGoingRidesRemoveRide,

  [Types.ON_CANCEL_RIDE_REQUEST]: onCancelRideRequest,
  [Types.ON_CANCEL_RIDE_SUCCESS]: onCancelRideSuccess,
  [Types.ON_CANCEL_RIDE_FAILURE]: onCancelRideFailure,

  [Types.UPDATE_DROPOFF_REQUEST]: updateDropoffRequest,
  [Types.UPDATE_DROPOFF_SUCCESS]: updateDropoffSuccess,
  [Types.UPDATE_DROPOFF_FAILURE]: updateDropoffFailure,
});
