import React from "react";
import { Pressable, ScrollView, Stack, VStack } from "native-base";
// import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Text } from "components";

export default function SelectMonth({
  months,
  selectedMonth: selected,
  onSelectMonth,
  // onPressAddMonth,
}) {
  const { month: selectedMonth } = selected;
  // const { t } = useTranslation("Private");
  // const [lastMonth, setLastMonth] = useState(null);

  // function handlePressAddMonth() {
  //   if (selectedMonth === null) {
  //     onPressAddMonth();
  //   } else {
  //     // setLastMonth(selectedMonth);
  //     onSelectMonth(null);
  //   }
  // }

  // function cancelAddMonth() {
  //   onSelectMonth(lastMonth);
  //   setLastMonth(null);
  // }

  return (
    <Stack h="full">
      <ScrollView py={4} pr={1}>
        <VStack space={2}>
          {/* {selectedMonth === null ? (
             <HStack w={32} h={8} bg="muted.100" roundedTop="md" justifyContent="space-evenly" alignItems="center">
              <Pressable onPress={cancelAddMonth}>
                <SmallCloseIcon />
              </Pressable>
              <Pressable onPress={handlePressAddMonth}>
                <FontAwesomeIcon icon={faCheck} color="#32de84" />
              </Pressable>
            </HStack>
          ) : (
            <Pressable
              alignItems="center" justifyContent="center"
              w={32} h={8}
              roundedTop="md"
              bg={"muted.100"}
              onPress={handlePressAddMonth}
            >
              <FontAwesomeIcon icon={faPlus} color="black" />
            </Pressable>
          )} */}
          {/* <Pressable
            alignItems="center"
            justifyContent="center"
            w={32}
            h={8}
            roundedTop="md"
            bg={selectedMonth === null ? "primary" : "muted.100"}
            onPress={handlePressAddMonth}
          >
            {selectedMonth === null ? (
              <Text fontSize="lg" fontWeight="bold" color="white">
                {t("Planning.buttonAddMonthConfirm")}
              </Text>
            ) : (
              <FontAwesomeIcon icon={faPlus} color="black" />
            )}
          </Pressable> */}

          {months.map(({ month, ...rest }) => (
            <Pressable
              key={month}
              w={32}
              h={16}
              alignItems="center"
              justifyContent="center"
              bg={selectedMonth === month ? "primary" : "muted.100"}
              onPress={() => onSelectMonth({ month, ...rest })}
            >
              <Text
                fontSize="lg"
                fontWeight="bold"
                color={selectedMonth === month ? "white" : "black"}
              >
                {_.capitalize(moment(month).format("MMMM"))}
              </Text>
              <Text
                fontSize="sm"
                color={selectedMonth === month ? "white" : "black"}
              >
                {moment(month).format("YYYY")}
              </Text>
            </Pressable>
          ))}
        </VStack>
      </ScrollView>
    </Stack>
  );
}
