import { Component } from "react";
import { withTranslation } from "react-i18next";
import { HStack, ScrollView, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import moment from "moment";
import Calendar, { DateObject } from "react-multi-date-picker";
import CalendarIcon from "react-multi-date-picker/components/icon";
import datepickerLocal from "global/local-dates";
import DashboardAction from "reducers/dashboard";
import { Title, NotificationMenu, Dashboard, Text } from "components";

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    const initialFrom = new DateObject(
      moment().startOf("isoWeek").toISOString()
    );

    this.state = {
      filter: {
        from: initialFrom,
        to: new DateObject(),
      },
      filterDates: [initialFrom, new DateObject()],
    };
  }

  componentDidMount() {
    this.props.realTimeRequest();
  }

  render() {
    const {
      t,
      i18n,
      realTime,
      transporterAvailability,
      rideFrequencyPerCompany,
      rideFrequencyMacro,
      partners,
    } = this.props;
    const { filter } = this.state;

    return (
      <View w="full">
        <VStack space="1" ml="24" mr="12" h="full">
          <VStack space="0" mt="5" zIndex={1}>
            {/* {error !== "" && <Alert bsStyle="danger">
            <span>{error}</span>
          </Alert>} */}

            <HStack justifyContent="space-between">
              <HStack space={5}>
                <Title>
                  {t("Dashboard.title_headline", { lng: i18n.language })}
                </Title>

                <Stack justifyContent="center" mt={1}>
                  <HStack
                    space={4}
                    position="relative"
                    zIndex={99}
                    alignItems="center"
                  >
                    <Calendar
                      range={true}
                      rangeHover={true}
                      value={this.state.filterDates}
                      weekStartDayIndex={1}
                      // calendarPosition="Right Top"
                      className="container-input-calendar"
                      render={<CalendarIcon color="rgb(95, 174, 232)" />}
                      locale={datepickerLocal}
                      shadow={false}
                      onChange={(selectedDates) => {
                        if (selectedDates.length === 2) {
                          this.setState({
                            filter: {
                              from: selectedDates[0],
                              to: selectedDates[1],
                            },
                          });
                        }
                        this.setState({ filterDates: selectedDates });
                      }}
                    />
                    <Text fontSize="18px" fontWeight="700" color="primary">
                      {`${filter.from.format(
                        "DD/MM/YYYY"
                      )} au ${filter.to.format("DD/MM/YYYY")}`}
                    </Text>
                  </HStack>
                </Stack>
              </HStack>

              {/* Notification menu */}
              <Stack alignItems="end" pt={35}>
                <NotificationMenu
                  items={this.props.notifications}
                  onReadedNotifications={this.props.onReadedNotifications}
                />
              </Stack>
            </HStack>
          </VStack>

          <ScrollView>
            <Dashboard.RealTime
              action={realTime.action}
              dataset={realTime.dataset}
            />

            <HStack space="28px" my="14px">
              <Stack>
                <Dashboard.Line
                  title="Fréquence des missions"
                  fetchDataset={this.props.rideFrequencyMacroRequest}
                  filter={filter}
                  action={rideFrequencyMacro.action}
                  dataset={rideFrequencyMacro.dataset}
                />
              </Stack>

              <Dashboard.Bar
                // title="Fréquence des missions par ambulance"
                title="Nb d'interventions"
                fetchDataset={this.props.rideFrequencyPerCompanyRequest}
                filter={filter}
                action={rideFrequencyPerCompany.action}
                // get max 7 companies NEED TO FIX THIS IN API
                // dataset={rideFrequencyPerCompany.dataset.slice(0, 7)}
                dataset={[
                  { name: "EVIA", total: 11, ratio: 0.2, vehicle: 5, id: 1 },
                  {
                    name: "EAU VIVE",
                    total: 32,
                    ratio: 0.4,
                    vehicle: 10,
                    id: 2,
                  },
                  { name: "PRINCE", total: 18, ratio: 0.2, vehicle: 7, id: 3 },
                  { name: "MORGANE", total: 6, ratio: 0.1, vehicle: 3, id: 4 },
                  { name: "DBS", total: 9, ratio: 0.1, vehicle: 4, id: 5 },
                  { name: "JACK", total: 27, ratio: 0.25, vehicle: 12, id: 6 },
                  { name: "ELYSEE", total: 24, ratio: 0.25, vehicle: 9, id: 7 },
                  // { name: "BOBIGNY", total: 6, ratio: 0.1, vehicle: 4, id: 8 },
                ]}
              />

              <Dashboard.TransporterAvailability
                companies={partners.partners}
                action={transporterAvailability.action}
                dataset={transporterAvailability.dataset}
                // dataset={{total: 140, samu: 85, other: 55, offline: 0}}
                fetchDataset={this.props.transporterAvailabilityRequest}
              />
            </HStack>

            <HStack space="28px" w="full" flex={1} my="14px">
              <Dashboard.MotifBar />
              <Dashboard.MissionTimePieChart />
            </HStack>
          </ScrollView>
        </VStack>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    realTime: state.dashboard.realTime.asMutable({ deep: true }),
    transporterAvailability: state.dashboard.transporterAvailability.asMutable({
      deep: true,
    }),
    rideFrequencyPerCompany: state.dashboard.rideFrequencyPerCompany.asMutable({
      deep: true,
    }),
    rideFrequencyMacro: state.dashboard.rideFrequencyMacro.asMutable({
      deep: true,
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    realTimeRequest: (...args) =>
      dispatch(DashboardAction.realTimeRequest(...args)),
    transporterAvailabilityRequest: (...args) =>
      dispatch(DashboardAction.transporterAvailabilityRequest(...args)),
    rideFrequencyPerCompanyRequest: (...args) =>
      dispatch(DashboardAction.rideFrequencyPerCompanyRequest(...args)),
    rideFrequencyMacroRequest: (...args) =>
      dispatch(DashboardAction.rideFrequencyMacroRequest(...args)),
  };
};

const dashboardRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
export default withTranslation(["Private", "Common"], { wait: true })(
  dashboardRedux
);
