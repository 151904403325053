import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const SAMU_BILAN_SAMU_UPDATED_SUBSCRIPTION = gql`
  subscription {
    SamuBilanSamuUpdatedSubscription {
      _id
      status
      rideNumber
      dropoff {
        address
      }
      bilan
      transporter {
        company {
          name
        }
        vehicle {
          plate
          category
          make
          color
          model
          phone
        }
        driver
      }
    }
  }
`;

const OnGoingRideBilanUpdatedNotification = ({ onGoingRideStatusChanged }) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      onGoingRideStatusChanged(ride);
    }
  }

  return (
    <Subscription
      subscription={SAMU_BILAN_SAMU_UPDATED_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.SamuBilanSamuUpdatedSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default OnGoingRideBilanUpdatedNotification;
