import { put, takeLatest } from "redux-saga/effects";
import SamuRideHistoryActions, {
  RideHistoryTypes,
} from "reducers/transporter/transporter-rides-history";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

export const queryRideHistory = `
  _id
  patient {
    firstname
    lastname
    phone
    email
    nss
    status
    gender
    age
  }
  pickup{address  longitude latitude}
  dropoff{address  longitude latitude}
  status
  rideNumber
  vehicleOptions
  transporter{
    company{name}
    vehicle {
      plate
      model
      make
      category
      color
      phone
    }
  }
  datetime
  createdAt
  bilan
  rideType
  samu { name }
  subject
  observation
  intervention { numeroRue rue codePostal commune complement batiment digicode etage poi }
  summary { onway onboard completed waiting arrived }
  canceled { by reason discharge { id path } }
`;

function* rideHistoryAsyncRequest({ page, limit, filter = false }) {
  let filterQuery = null;
  if (filter) {
    filterQuery = `
      {
        dates: [${filter.dates}],
        text: "${filter.text}"
      }
    `;
  }
  const query = `
    query {
      SamuRidesHistory(page:${page},limit:${limit},filter:${filterQuery}) {
        page
        limit
        totalPages
        rides {
          ${queryRideHistory}
        }
      }
    }
  `;

  try {
    const { SamuRidesHistory } = yield graphQLClient().request(query);
    yield put(
      SamuRideHistoryActions.rideHistoryRequestSuccess(SamuRidesHistory)
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      SamuRideHistoryActions.rideHistoryRequestFailure
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(RideHistoryTypes.RIDE_HISTORY_REQUEST, rideHistoryAsyncRequest),
];
