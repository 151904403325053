import { Component } from "react";
import { withTranslation } from "react-i18next";
import { HStack, ScrollView, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import PartnerAction from "reducers/transporter/transporter-partner";
import {
  Title,
  NotificationMenu,
  Button,
  PartnerFormModal,
  PartnerCard,
  MenuLink,
} from "components";
import addPartnerIcon from "assets/img/add_vehicle.svg";

class Partners extends Component {
  constructor(props) {
    super(props);

    this.onSearchPartnerFilter = this.onSearchPartnerFilter.bind(this);
    this.onPressShowFormButton = this.onPressShowFormButton.bind(this);
    this.onCancelFormPopUp = this.onCancelFormPopUp.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      searchInputValue: "",
      showFormPopUp: false,
    };
  }

  componentDidMount() {
    this.props.sectorsRequest();
  }

  onSearchPartnerFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
    // this.props.partnersRequest();
  }

  onPressShowFormButton() {
    this.props.partnersRequest();
    this.setState(
      Object.assign({}, this.state, {
        showFormPopUp: true,
      })
    );
  }

  onSubmitForm(partner) {
    this.props.createPartnerRequest(partner);
    this.setState({ showFormPopUp: false });
  }

  onCancelFormPopUp() {
    this.setState(
      Object.assign({}, this.state, {
        showFormPopUp: false,
      })
    );
  }

  render() {
    const { t, i18n, actionError, partners, sectors } = this.props;
    // const { error, loading } = actionError;
    const { showFormPopUp /* , searchInputValue */ } = this.state;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space="5" mt="5">
            {/* {error !== "" && <Alert bsStyle="danger">
            <span>{error}</span>
          </Alert>} */}

            <Title>
              {t("partnersPage.title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification menu */}
            <Stack alignItems="end" w="986px">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            <HStack justifyContent="space-between" w="986px">
              {/* Menu */}
              <HStack space="5">
                <MenuLink to="/planning" label="Planification" />
                <MenuLink to="/partner" label="Liste des structures" />
              </HStack>

              {/* Search */}
              {/* <FloatingInput
                inputType="search"
                label={t("searchBar", { lng: i18n.language })}
                onChange={this.onSearchPartnerFilter}
                // onKeyPress={this.handleKeyPress}
                value={searchInputValue}
              /> */}

              {/* Localiser */}

              <HStack space={4}>
                {/* Add partner */}
                <Button
                  rightIcon={
                    <img src={addPartnerIcon} alt="ajouter un partenaire" />
                  }
                  onPress={this.onPressShowFormButton}
                >
                  {t("partnersPage.button_add_partner", {
                    lng: i18n.language,
                  })}
                </Button>
              </HStack>
            </HStack>
          </VStack>

          <ScrollView>
            <VStack>
              {partners.partners.map((partner, key) => (
                <PartnerCard
                  {...{ t, i18n }}
                  key={key}
                  mb="10px"
                  partner={partner}
                  sectors={sectors}
                  activePartnerRequest={this.props.createPartnerRequest}
                  deletePartnerRequest={this.props.deletePartnerRequest}
                  updatePartner={this.props.updatePartnerRequest}
                />
              ))}
            </VStack>
          </ScrollView>
        </VStack>

        {/* MODAL ADD PARTNER */}
        <PartnerFormModal
          {...{ t, i18n }}
          error={actionError.error}
          isOpen={showFormPopUp}
          onClose={this.onCancelFormPopUp}
          onConfirm={this.onSubmitForm}
          searchPartnerRequest={this.props.searchPartnerRequest}
          searchPartner={this.props.searchPartner}
          action={this.props.searchPartnerAction}
          sectors={sectors}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    actionError: state.transporterPartners.action.asMutable({ deep: true }),
    searchPartner: state.transporterPartners.search.asMutable({ deep: true }),
    searchPartnerAction: state.transporterPartners.action.asMutable({
      deep: true,
    }),
    sectors: state.transporterPartners.sectors.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPartnerRequest: (...args) =>
      dispatch(PartnerAction.createPartnerRequest(...args)),
    deletePartnerRequest: (...args) =>
      dispatch(PartnerAction.deletePartnerRequest(...args)),
    searchPartnerRequest: (...args) =>
      dispatch(PartnerAction.searchPartnerRequest(...args)),
    sectorsRequest: (...args) =>
      dispatch(PartnerAction.sectorsRequest(...args)),
    updatePartnerRequest: (...args) =>
      dispatch(PartnerAction.updatePartnerRequest(...args)),
  };
};

const partnersRedux = connect(mapStateToProps, mapDispatchToProps)(Partners);
export default withTranslation(["Private", "Common"], { wait: true })(
  partnersRedux
);
