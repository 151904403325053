import { Component } from "react";
import { withTranslation } from "react-i18next";
import { HStack, ScrollView, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import VehicleActions from "../../reducers/transporter/transporter-vehicle";
import DriverLocationActions from "reducers/ride/driver-location";
import {
  Title,
  FloatingInput,
  NotificationMenu,
  VehicleCard,
  DriverLocationMapModal,
  Button,
} from "components";

class TransporterVehicles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInputValue: "",
      isShowMap: false,
      driversId: [],
    };

    this.onSearchVehicleFilter = this.onSearchVehicleFilter.bind(this);
    this.onShowMap = this.onShowMap.bind(this);
    this.onCloseMap = this.onCloseMap.bind(this);
  }

  componentDidMount() {
    this.props.vehiclesRequest(1, 100);
    // const {
    //   vehicle: { vehicles = [] },
    // } = this.props;
    // let driversId = [];
    // console.log("vehicles", vehicles)
    // vehicles.forEach((v) => {
    //   driversId.push(v._id);
    // });
    // this.setState({ driversId });

    this.driverLocationIntervalId = setInterval(() => {
      const { isShowMap } = this.state;

      if (!isShowMap) return;

      this.getCurrentDriversLocation();
    }, 30 * 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle.vehicles !== this.props.vehicle.vehicles) {
      const {
        vehicle: { vehicles = [] },
      } = this.props;
      this.setState({ driversId: vehicles.map((v) => v._id) });
    }
  }

  componentWillUnmount() {
    clearInterval(this.driverLocationIntervalId);
  }

  onSearchVehicleFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
    this.props.vehiclesRequest(1, 100, value);
  }

  onShowMap() {
    this.setState({ isShowMap: true });
    this.getCurrentDriversLocation();
  }

  onCloseMap() {
    this.setState({ isShowMap: false });
  }

  getCurrentDriversLocation() {
    const { samuGetDriversLocationRequest } = this.props;
    samuGetDriversLocationRequest(this.state.driversId);
  }

  render() {
    const {
      t,
      i18n,
      // actionError,
      driversCoords,
      vehicle: { vehicles = [] },
    } = this.props;
    // const { error, loading } = actionError;
    const { searchInputValue } = this.state;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space="5" h="48" mt="5">
            {/* {error !== "" && <Alert bsStyle="danger">
            <span>{error}</span>
          </Alert>} */}

            <Title>
              {t("vehiclesPage.vehicles_page_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification menu */}
            <Stack alignItems="end" w="986px">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            <HStack justifyContent="space-between" w="986px">
              {/* Search */}
              <FloatingInput
                inputType="search"
                label={t("searchBar", { lng: i18n.language })}
                onChange={this.onSearchVehicleFilter}
                // onKeyPress={this.handleKeyPress}
                value={searchInputValue}
              />

              {/* Localiser */}
              <Button onPress={this.onShowMap}>
                {t("vehiclesPage.button_locate", {
                  lng: i18n.language,
                }).toUpperCase()}
              </Button>
            </HStack>
          </VStack>

          <ScrollView>
            <VStack>
              {vehicles.map((vehicle, key) => (
                <VehicleCard vehicle={vehicle} key={key} {...{ t, i18n }} />
              ))}
            </VStack>
          </ScrollView>
        </VStack>

        <DriverLocationMapModal
          isOpen={this.state.isShowMap}
          coordinates={driversCoords}
          onClose={this.onCloseMap}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    actionError: state.transporterVehicles.action.asMutable({ deep: true }),
    vehicle: state.transporterVehicles.vehicles.asMutable({
      deep: true,
    }),
    driversCoords: state.driverLocation.driversCoords.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    samuGetDriversLocationRequest: (...args) =>
      dispatch(DriverLocationActions.samuGetDriversLocationRequest(...args)),
    vehiclesRequest: (...args) =>
      dispatch(VehicleActions.vehiclesRequest(...args)),
  };
};

const vehiclesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransporterVehicles);
export default withTranslation(["Private", "Common"], { wait: true })(
  vehiclesRedux
);
