import { Text } from "components/Text";
import { VStack } from "native-base";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function MotifBar(props) {
  const data = [
    { name: "ITF", value: 11, fill: "#9BC8E3" },
    { name: "TCP", value: 32, fill: "#4D9CB9" },
    { name: "GDP", value: 18, fill: "#112F45" },
    { name: "RGP", value: 6, fill: "#F4BA41" },
    { name: "ADP", value: 9, fill: "#EC8B33" },
    { name: "Autre", value: 27, fill: "#C8C8C8" },
  ];
  return (
    <VStack bg="dashboardCard" py="20px" rounded="12px" space="16px">
      <Text fontSize="16px" fontWeight="700" ml="55px">
        Motifs
      </Text>

      <BarChart
        width={450}
        height={172}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          vertical={false}
          stroke="var(--black-5, rgba(28, 28, 28, 0.05))"
        />
        <XAxis dataKey="name" />
        <YAxis />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Bar
          barSize={30}
          radius={[8, 8, 0, 0]}
          dataKey="value"
          fill="#8884d8"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />{" "}
      </BarChart>
    </VStack>
  );
}

export default MotifBar;
