import { Text } from "components/Text";
import { HStack, VStack } from "native-base";
import { Pie, PieChart } from "recharts";
import LegendChart from "./LegendChart";

function MissionTimePieChart(props) {
  const data = [
    { name: "ITF", value: 2, fill: "#9BC8E3" },
    { name: "TCP", value: 6, fill: "#4D9CB9" },
    { name: "GDP", value: 9, fill: "#112F45" },
    { name: "RGP", value: 14, fill: "#F4BA41" },
    { name: "ADP", value: 14, fill: "#EC8B33" },
  ];

  return (
    <VStack bg="dashboardCard" py="20px" rounded="12px" space="16px" px="24px">
      <VStack space={0}>
        <Text fontSize="16px" fontWeight="700" color="#273B4A">
          Durée moyenne d'intervention
        </Text>
        <Text fontSize="13px" fontWeight="400" color="#273B4A">
          En minutes
        </Text>
      </VStack>

      <HStack space={"50px"} rounded="12px">
        <PieChart width={140} height={140}>
          <Pie
            // startAngle={-270}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={45}
            outerRadius={70}
            dataKey="value"
          />
        </PieChart>

        <HStack space={2}>
          <VStack justifyContent="center" space={1}>
            <LegendChart fill="#A8B5FF" label="Temps d'engagement" />
            <LegendChart fill="#FFD071" label="Trajet vers le patient" />
            <LegendChart fill="#FF7A00" label="Temps avec le patient" />
            <LegendChart fill="#0E2BE2" label="Trajet vers les urgences" />
            <LegendChart fill="#8A8FF8" label="Temps aux urgences" />
            <Text mt={1} fontWeight="700">
              Temps total
            </Text>
          </VStack>
          <VStack justifyContent="center" space={1}>
            <Text fontSize="12px">2</Text>
            <Text fontSize="12px">6</Text>
            <Text fontSize="12px">9</Text>
            <Text fontSize="12px">14</Text>
            <Text fontSize="12px">14</Text>
            <Text mt={1} fontSize="12px" fontWeight="700">
              31
            </Text>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  );
}

export default MissionTimePieChart;
