import React from "react";
import { Center, Menu, Pressable, useDisclose } from "native-base";
import { useTranslation } from "react-i18next";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "components/Text";
import { EXPORT_DOCUMENT_FORMAT } from "global";

// export default function ExportButton({ icon = faFileExport, color = "rgb(95, 174, 232)", isDisabled = false, ...rest }) {
//   return (
//     <Pressable {...rest}>
//       <Center position="relative">
//         <FontAwesomeIcon icon={icon} color={rest?.disabled ? "gray" : color} />
//       </Center>
//     </Pressable>
//   );
// }

export default function ExportButton({
  icon = faFileExport,
  color = "rgb(95, 174, 232)",
  disabled = false,
  onExport,
}) {
  const { isOpen, onOpen, onClose } = useDisclose();
  const { t } = useTranslation("Private");

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={!disabled && onOpen}
      placement="bottom right"
      trigger={(triggerProps) => (
        <Pressable {...triggerProps}>
          <Center position="relative">
            <FontAwesomeIcon icon={icon} color={disabled ? "gray" : color} />
          </Center>
        </Pressable>
      )}
    >
      <Menu.OptionGroup
        defaultValue="1"
        title={t("Planning.menuOptionExport")}
        type="radio"
      >
        {Object.values(EXPORT_DOCUMENT_FORMAT).map((format) => (
          <Menu.Item key={format} onPress={() => onExport(format)}>
            <Text>{format}</Text>
          </Menu.Item>
        ))}
      </Menu.OptionGroup>
    </Menu>
  );
}
