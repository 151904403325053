import { put, takeLatest } from "redux-saga/effects";
import PartnerActions, {
  PartnerTypes,
} from "reducers/transporter/transporter-partner";
import _ from "lodash";
// import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* partnersAsyncRequest({ page, limit, filter = "" }) {
  // const query = `
  //   {
  //     SamuPartners(page:${page},limit:${limit},filter:"${filter}") {
  //       page
  //       limit
  //       totalPages
  //       partners {
  //         partnerId
  //         siret
  //         name
  //         address { address }
  //         email
  //         // phones
  //       }
  //     }
  //   }
  // `;
  const query = `
    {
      SamuPartners {
        partnerId
        siret
        name
        address { address }
        email
        phones
        deleted
        sectors {
          sectorId
          sectorName
        }
      }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const partners = data.SamuPartners;
    yield put(PartnerActions.partnersRequestSuccess(partners));
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    // yield errorHandler(
    //   message,
    //   PartnerActions.partnersRequestFailure
    // );
  }
}

function* createPartnerAsyncRequest({ partner, callback }) {
  const partnerQuery = partner.map(({ partnerId, sectors }) => {
    const sectorQuery = sectors
      .map(({ sectorId, sectorName }) => {
        return `{ sectorId: "${sectorId}", sectorName: "${sectorName}" }`;
      })
      .join(",");
    return `{ partnerId: "${partnerId}", sectors: [${sectorQuery}] }`;
  });
  const partnerQueryStr = partnerQuery.join(",");
  const query = `
    mutation {
      SamuAddPartner(partners: [${partnerQueryStr}])
      {
        partnerId
        siret
        name
        address { address }
        email
        phones
        deleted
        sectors {
          sectorId
          sectorName
        }
      }
    }
  `;

  try {
    yield graphQLClient().request(query);
    yield put(PartnerActions.createPartnerRequestSuccess());
    yield put(PartnerActions.partnersRequest(1, 100));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    // yield errorHandler(
    //   message,
    //   PartnerActions.createPartnerRequestFailure
    // );

    if (_.isFunction(callback)) callback(error);
  }
}

function* updatePartnerAsyncRequest({ partner, callback }) {
  const sectorQuery = partner.sectors
    .map(({ sectorId, sectorName }) => {
      return `{ sectorId: "${sectorId}", sectorName: "${sectorName}" }`;
    })
    .join(",");
  const partnerQuery = `{ partnerId: "${partner.partnerId}", sectors: [${sectorQuery}] }`;

  const query = `
    mutation {
      SamuUpdatePartner(partner: ${partnerQuery})
      {
        partnerId
        siret
        name
        address { address }
        email
        phones
        deleted
        sectors { sectorId sectorName }
      }
    }
  `;

  try {
    yield graphQLClient().request(query);
    yield put(PartnerActions.updatePartnerSuccess());
    yield put(PartnerActions.partnersRequest(1, 100));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    // yield errorHandler(
    //   message,
    //   PartnerActions.createPartnerRequestFailure
    // );
    yield put(PartnerActions.updatePartnerFailure(message));

    if (_.isFunction(callback)) callback(error);
  }
}

function* deletePartnerAsyncRequest({ partnerId, callback }) {
  const query = `
    mutation {
      SamuRemovePartner(partnerId:"${partnerId}")
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(PartnerActions.deletePartnerRequestSuccess());
    yield put(PartnerActions.partnersRequest(1, 100));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    // yield errorHandler(
    //   message,
    //   PartnerActions.deletePartnerRequestFailure
    // );
    if (_.isFunction(callback)) callback(error);
  }
}

function* searchPartnerAsyncRequest({ page = 1, limit = 100, filter = "" }) {
  const query = `
    {
      SamuSearchPartner(page: ${page}, limit: ${limit}) {
        page
        limit
        totalPages
        companies {
          _id
          name
          siret
          address { address }
        }
      }
    }
  `;

  try {
    const { SamuSearchPartner } = yield graphQLClient().request(query);
    yield put(PartnerActions.searchPartnerSuccess(SamuSearchPartner));
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    yield put(PartnerActions.searchPartnerSuccess(error));
    // yield errorHandler(
    //   message,
    //   PartnerActions.partnersRequestFailure
    // );
  }
}

function* sectorsAsyncRequest() {
  const query = `
    {
      SamuSectors {
        sectorId
        sectorName
      }
    }
  `;

  try {
    const { SamuSectors } = yield graphQLClient().request(query);
    yield put(PartnerActions.sectorsSuccess(SamuSectors));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    // yield errorHandler(
    //   message,
    //   PartnerActions.partnersRequestFailure
    // );

    yield put(PartnerActions.sectorsFailure(message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(PartnerTypes.PARTNERS_REQUEST, partnersAsyncRequest),
  takeLatest(PartnerTypes.CREATE_PARTNER_REQUEST, createPartnerAsyncRequest),
  takeLatest(PartnerTypes.UPDATE_PARTNER_REQUEST, updatePartnerAsyncRequest),
  takeLatest(PartnerTypes.DELETE_PARTNER_REQUEST, deletePartnerAsyncRequest),
  takeLatest(PartnerTypes.SEARCH_PARTNER_REQUEST, searchPartnerAsyncRequest),
  takeLatest(PartnerTypes.SECTORS_REQUEST, sectorsAsyncRequest),
];
