import { useState } from "react";
import { Box } from "native-base";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const InputAddress = ({ onSelectAddress }) => {
  const [value, setValue] = useState("");

  const onInputChange = (prop) => {
    const { label, value } = prop;
    const { place_id /* description */ } = value;
    setValue(prop);
    geocodeByPlaceId(place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        onSelectAddress({
          address: label,
          longitude: lng,
          latitude: lat,
        });
      });
  };

  return (
    <Box w="300">
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GMAPS_KEY}
        selectProps={{
          value,
          onChange: onInputChange,
          placeholder: "Adresse de destination",
          noOptionsMessage: () => null,
          // blurInputOnSelect: true,
          // components: {
          //   DropdownIndicator: () => null,
          //   IndicatorSeparator: () => null,
          // },
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["fr"],
          },
        }}
      />
    </Box>
  );
};

export default InputAddress;
